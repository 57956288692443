import {
  AfterViewInit,
  Component,
  ContentChildren,
  EventEmitter,
  Input, OnDestroy,
  OnInit,
  Output,
  QueryList
} from '@angular/core';
import {MjxFilterFieldDirective} from "./directives/mjx-filter-field.directive";
import {UntypedFormGroup} from "@angular/forms";
import {FilterStorageService} from "../../services/filters-storage.service";
import {FilterField} from "../../models/filter-field.model";
import {Subscription} from "rxjs";
import {MjxBaseFilter} from "./models/mjx-base-filter.model";

type FieldsMap = { [key: string]: FilterField };

@Component({
  selector: 'mjx-filter',
  templateUrl: './mjx-filter.component.html',
  styleUrls: ['./mjx-filter.component.scss']
})
export class MjxFilterComponent extends MjxBaseFilter implements OnInit, AfterViewInit, OnDestroy {
  @ContentChildren(MjxFilterFieldDirective) fieldsRef: QueryList<MjxFilterFieldDirective>;
  @Input() formGroup: UntypedFormGroup;
  @Input() customSearchButtonLabel: string;
  @Input() customSearchButtonIcon: string;

  @Output() filter = new EventEmitter<void>();
  @Output() clear = new EventEmitter<void>();

  selectedFilters: MjxFilterFieldDirective[] = [];
  formSubs: Subscription;
  activated = false;
  showFilterActions = true;

  constructor(
    private filterStorage: FilterStorageService
  ) {super()}

  get searchButtonName(): string {
    return this.customSearchButtonLabel || 'SHARED.FILTER.SEARCH_BTN';
  }

  get searchButtonIcon(): string {
    return this.customSearchButtonIcon || 'search';
  }

  get allFiltersHidden(): boolean {
    return this.selectedFilters.length === 0;
  }

  ngOnInit(): void {
  }

  ngOnDestroy() {
    if (this.formSubs && !this.formSubs.closed) {
      this.formSubs.unsubscribe();
    }
  }

  ngAfterViewInit() {
    this.loadFilterState();
    this.updateFilter();
    this.verifyFilterActions();
  }

  savefilterState() {
    this.filterStorage.saveFilterFields(this.filterName, this.fieldsToDisplay);
    this.updateFilter();
  }

  checkLabel(e: Event) {
    e.preventDefault();
    e.stopPropagation();
  }

  private verifyFilterActions() {
    this.showFilterActions = this.fieldsToDisplay.some(f => !f.hiddenFromActions);
  }


  private loadFilterState() {
    const storageFilter = this.filterStorage.loadFilterFields(this.filterName);
    if (storageFilter) {
      this.fieldsToDisplay = storageFilter
    }
  }

  private updateFilter() {
    let hiddenCounts = 0;

    const fields: FieldsMap = {}
    this.fieldsToDisplay.forEach(f => fields[f.name] = f);
    this.selectedFilters = this.fieldsRef.filter(field => {
        const currentField = fields[field.fieldName];
        const show = currentField?.show;

        if (!show) {
          hiddenCounts += 1;

          if (field?.parentForm) {
            field.parentForm.setValue(null);
          }

          if (currentField?.formControlNames) {
            currentField.formControlNames.forEach(form => {
              this.formGroup.get(form).setValue(null);
            })
          }
        }

        this.activated = (hiddenCounts > 0);

       return show;
    });
  }
}
