import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {MjxTableColumnDefinition} from "../../../../shared/modules/mjx-table/models/mjx-table.model";
import {MjxTableDataSource} from "../../../../shared/modules/mjx-table/mjx-table-datasource";
import {RequestDataFilterModel} from "../../../../shared/models/request-filter.model";
import {TranslateService} from "@ngx-translate/core";
import {SnackbarService} from "../../../../shared/services/snackbar.service";
import {Router} from "@angular/router";
import {MjxTableEventsModel} from "../../../../shared/modules/mjx-table/models/mjx-table-events.model";
import {Observable, Subscription} from "rxjs";
import {PageEvent} from "@angular/material/paginator";
import {UserModel} from "../../models/user.model";
import {UsersService} from "../../services/users.service";
import {Select, Store} from "@ngxs/store";
import {UsersState} from "../../states/users.state";
import {DeleteUser, GetUsers} from "../../states/users.actions";
import {environment} from "../../../../../environments/environment";
import {AppContexEnum} from "../../../../shared/enums/app-contex.enum";
import {UsersTableUtilsService} from "../../utils/users-table.utils";
import {SessionState} from "../../../../core/state/session/session.state";
import {UserFilterModel} from '../../models/user-filter.model';
import {ConfirmDialogModel} from 'src/app/shared/models/confirm-dialog.model';
import {ConfirmDialogComponent} from 'src/app/shared/components/confirm-dialog/confirm-dialog.component';
import {MatDialog} from '@angular/material/dialog';
import {MjxTableComponent} from "../../../../shared/modules/mjx-table/mjx-table.component";
import {isPartner} from 'src/app/shared/utils/get-context';
import {FilterField} from "../../../../shared/models/filter-field.model";
import {UsersFilterFields} from "../../utils/users-filter-fields.utils";

@Component({
  selector: 'mjx-list-users',
  templateUrl: './list-users.component.html',
  styleUrls: ['./list-users.component.scss']
})
export class ListUsersComponent implements OnInit, OnDestroy {
  usersTableColumns: string[];
  usersTableColumnsDef: MjxTableColumnDefinition[]
  usersSubscription: Subscription;
  currentFilter: RequestDataFilterModel<any> = this.defaultRequestFilter;
  isPartner = isPartner;

  @Select(UsersState.getLoadingState)
  isLoading$: Observable<boolean>;
  @Select(UsersState.getEmptyState)
  isEmpty$: Observable<boolean>;
  @Select(UsersState.hasErrorList)
  hasError$: Observable<boolean>
  @Select(UsersState.getDatasourceState)
  userDataSource$: Observable<MjxTableDataSource<UserModel>>;
  @ViewChild('customTable') mjxTable: MjxTableComponent;


  constructor(
    private translate: TranslateService,
    private snackbar: SnackbarService,
    private usersService: UsersService,
    private router: Router,
    private store: Store,
    private dialog: MatDialog,
    private usersTableUtils: UsersTableUtilsService
  ) {
    this.usersTableColumns = (isPartner)
      ? usersTableUtils.getPartnerColumns()
      : usersTableUtils.getColumns()
    this.usersTableColumnsDef = (isPartner)
      ? usersTableUtils.getPartnerColumnsDef()
      : usersTableUtils.getColumnsDef()
  }

  ngOnInit(): void {
    this.getUsers(this.defaultRequestFilter);
  }

  ngOnDestroy() {
    if (this.usersSubscription) {
      this.usersSubscription.unsubscribe();
    }
  }

  get isPartnerContext(): boolean {
    return environment.context === AppContexEnum.Partners;
  }

  get defaultRequestFilter(): RequestDataFilterModel<any> {
    const whereFilter = this.createFilterWhere();

    const filter: RequestDataFilterModel<any> = {
      dataset: {
        offset: 0,
        limit: 10
      },
      where: whereFilter
    }

    return filter;
  }

  listenTableEvents(event: MjxTableEventsModel) {
    if (event.name === 'users-edit') {
      const isCurrentUser = this.isCurrentUser(event.data?.id);

      this.router.navigateByUrl('users/edit', {
        state: { ...event.data, profile: isCurrentUser },
      });
    } else if (event.name === 'table-pagination') {
      this.changeDataPage(event.data)
    } else if (event.name === 'users-remove') {
      this.openDeleteUserDialog(event.data);
    }
  }

  private openDeleteUserDialog(user: UserModel) {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      panelClass: 'custom-modal',
      width: '500px',
      data: ({
        title: this.translate.instant('USERS.DELETE.MODAL_TITLE'),
        text: `${this.translate.instant('USERS.DELETE.MODAL_TEXT')} ${user.username}?`
      } as ConfirmDialogModel)
    })

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.store.dispatch(new DeleteUser(user.id, this.currentFilter));
      }
    });
  }

  getUsers(filter?: RequestDataFilterModel<any>) {
    if (!filter) {
      filter = this.currentFilter;
    } else {
      this.updateCurrentFilter(filter)
    }

    this.store.dispatch(new GetUsers(filter));
  }

  filterUsers(filterForm: UserFilterModel) {
    let filter: RequestDataFilterModel<any>;

    const where = {
      ...this.cleanFilterForm(filterForm)
    }

    filter = {
      dataset: {
        offset: this.defaultRequestFilter.dataset.offset,
        limit: this.currentFilter.dataset.limit
      },
      where
    }

    this.mjxTable.resetPaginator(true);
    this.getUsers(filter);
  }

  private cleanFilterForm(filterForm: UserFilterModel): UserFilterModel {
    const filterProperties = Object.keys(filterForm);
    filterProperties.forEach(key => {
      const value = filterForm[key];

      if (!value) {
        delete filterForm[key];
      }
    });

    return filterForm;
  }

  private isCurrentUser(userId: string): boolean {
    const myUser = this.store.selectSnapshot(SessionState.getCurrentUser);
    return  myUser.id === userId;
  }

  private createFilterWhere(filterForm?: any): any {
    let whereObj: any = { };

    if (!filterForm) return whereObj;

    if (filterForm.selectedValue) {
      whereObj = {
        ...whereObj,
        [filterForm.selectedFilter]: (filterForm.selectedValue as string).trim()
      };
    }

    return whereObj;
  }

  private changeDataPage(event: PageEvent) {
    const filter = (event as PageEvent);
    this.updateCurrentFilter({
      dataset: {
        offset: (filter.pageSize * filter.pageIndex),
        limit: (filter.pageSize)
      }
    });

    this.getUsers(this.currentFilter);
  }

  private updateCurrentFilter(filter: Partial<RequestDataFilterModel<any>>) {
    this.currentFilter = {
      ...this.currentFilter,
      ...filter
    }
  }

  get filterName(): string {
    return isPartner ? 'partner-list-users' : 'list-users';
  }

  get filterFields(): FilterField[] {
    return isPartner ? UsersFilterFields.partnerFields : UsersFilterFields.fields;
  }
}
