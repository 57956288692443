import { Injectable } from '@angular/core';
import {FilterField} from "../models/filter-field.model";
import {VersionedStorage} from "../modules/mjx-table/models/versioned-storage";

@Injectable({
  providedIn: 'root'
})
export class FilterStorageService extends VersionedStorage{
  // Update filter storage if current version is different from previous version
  override version = 'a#a';
  override storageKey = 'mjx-filter-fields';

  constructor() {
    super();
    this.verifyStorageVersion();
  }

  saveFilterFields(filterName: string, fields: FilterField[]) {
    const fieldsData = JSON.parse(localStorage.getItem(this.storageKey)) || {};
    if (fieldsData) {
      fieldsData['version'] = this.version;
      fieldsData[filterName.toLowerCase()] = fields;
      localStorage.setItem(this.storageKey, JSON.stringify(fieldsData));
    }
  }

  deleteFilterFields(filterName: string) {
    const fieldsData = JSON.parse(localStorage.getItem(this.storageKey)) || {};
    if (fieldsData && fieldsData[filterName.toLowerCase()]) {
      delete fieldsData[filterName.toLowerCase()];
      localStorage.setItem(this.storageKey, JSON.stringify(fieldsData));
    }
  }

  loadFilterFields(filterName: string) {
    const data = localStorage.getItem(this.storageKey);
    if (data) {
      const fieldsData = JSON.parse(data);
      if (fieldsData[filterName.toLowerCase()]) {
        return fieldsData[filterName.toLowerCase()];
      }
    }
    return null;
  }
}
