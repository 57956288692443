import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {UsersComponent} from "./users.component";
import {DetailedUserComponent} from "./components/detailed-user/detailed-user.component";
import {ListUsersComponent} from "./components/list-users/list-users.component";
import {TranslationLoaderResolver} from "../../shared/services/translation-loader.resolver";
import {
  ListProfilesPermissionsComponent
} from "./components/profile-permissions/list-profiles-permissions/list-profiles-permissions.component";
import {
  DetailedProfilesPermissionsComponent
} from "./components/profile-permissions/detailed-profiles-permissions/detailed-profiles-permissions.component";
import {SessionGuard} from "../../core/guards/session.guard";
import {Roles} from "../../shared/utils/roles-list";

const breadcrumbs = {
  title: 'BREADCRUMBS.USERS.TITLE',
  list: 'BREADCRUMBS.USERS.LIST',
  new: 'BREADCRUMBS.USERS.NEW',
  edit: 'BREADCRUMBS.USERS.EDIT',
  profilePermissions: 'BREADCRUMBS.USERS.PROFILE_PERMISSIONS',
  newProfilePermissions: 'BREADCRUMBS.USERS.PROFILE_PERMISSIONS.NEW',
  entities: 'BREADCRUMBS.ENTITIES'
}

const routes: Routes = [
  {
    path: '', component: UsersComponent,
    data: { breadcrumbs: [breadcrumbs.title] },
    resolve: { i18n: TranslationLoaderResolver },
    children: [
      {
        path: '', component: ListUsersComponent,
        data: { breadcrumbs: [breadcrumbs.entities, breadcrumbs.title, breadcrumbs.list], roles: Roles.MENU_ROUTES.USERS.MANAGEMENT },
        canActivate: [SessionGuard]
      },
      {
        path: 'new', component: DetailedUserComponent,
        data: { breadcrumbs: [breadcrumbs.entities, breadcrumbs.title, breadcrumbs.new], roles: Roles.MENU_ROUTES.USERS.MANAGEMENT },
        canActivate: [SessionGuard]
      },
      {
        path: 'edit', component: DetailedUserComponent,
        data: { breadcrumbs: [breadcrumbs.entities, breadcrumbs.title, breadcrumbs.edit], roles: Roles.MENU_ROUTES.USERS.MANAGEMENT },
        canActivate: [SessionGuard]
      },
      {
        path: 'profile-permissions',
        component: ListProfilesPermissionsComponent,
        data: { breadcrumbs: [breadcrumbs.entities, breadcrumbs.title, breadcrumbs.profilePermissions],
          roles: Roles.MENU_ROUTES.USERS.PROFILES },
        canActivate: [SessionGuard]
      },
      {
        path: 'profile-permissions/new',
        component: DetailedProfilesPermissionsComponent,
        data: { breadcrumbs: [breadcrumbs.entities, breadcrumbs.title, breadcrumbs.newProfilePermissions],
          roles: Roles.MENU_ROUTES.USERS.PROFILES },
        canActivate: [SessionGuard]
      }
    ]
  }
];



@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class UsersRoutingModule { }
