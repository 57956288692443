import { Pipe, PipeTransform } from '@angular/core';
import {AccessRolesEnum} from "../enums/access-roles.enum";

@Pipe({
  name: 'userRoleTranslateKey'
})
export class TranslateUserRolePipe implements PipeTransform {
  transform(status: AccessRolesEnum): string {
    let translateKey = status.toString();

    switch (status) {
      case AccessRolesEnum.PartnerAdmin:
        translateKey = 'SHARED.ENUM.USER_ROLES.PARTNER_ADMIN';
        break;
      case AccessRolesEnum.PartnerMember:
        translateKey = 'SHARED.ENUM.USER_ROLES.PARTNER_MEMBER';
        break;
      case AccessRolesEnum.Partner:
        translateKey = 'SHARED.ENUM.USER_ROLES.PARTNER';
        break;

      case AccessRolesEnum.Corporate:
        translateKey = 'SHARED.ENUM.USER_ROLES.CORPORATE';
        break;

      case AccessRolesEnum.CorporateAdmin:
        translateKey = 'SHARED.ENUM.USER_ROLES.CORPORATE_ADMIN';
        break;

      case AccessRolesEnum.System:
        translateKey = 'SHARED.ENUM.USER_ROLES.SYSTEM';
        break;
    }
    return translateKey;
  }

}
