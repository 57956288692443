import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {AccountSecurityComponent} from "./components/account-security/account-security.component";
import {EnableAuthComponent} from "./components/enable-auth/enable-auth.component";
import {QuickInfoModule} from "../../components/quick-info/quick-info.module";
import {MaterialElementsModule} from "../material-elements/material-elements.module";
import {NgxsModule} from "@ngxs/store";
import {AccountSecurityState} from "./states/account-security.state";
import {TranslateModule} from "@ngx-translate/core";
import {MjxInputModule} from "../mjx-input/mjx-input.module";
import {NgxMaskModule} from "ngx-mask";
import {ReactiveFormsModule} from "@angular/forms";
import {maskConfigFactory} from "../../utils/maskConfigFactory";
import {SessionState} from "../../../core/state/session/session.state";

@NgModule({
  declarations: [
    AccountSecurityComponent,
    EnableAuthComponent
  ],
  exports: [
    AccountSecurityComponent,
    EnableAuthComponent
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    QuickInfoModule,
    MaterialElementsModule,
    NgxsModule.forFeature([AccountSecurityState, SessionState]),
    TranslateModule,
    MjxInputModule,
    NgxMaskModule.forRoot(maskConfigFactory),
  ]
})
export class AccountSecurityModule { }
