import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MjxTableActionsModel } from '../../models/mjx-table-actions.model';
import { MjxTableEventsModel } from '../../models/mjx-table-events.model';

@Component({
  selector: 'mjx-table-action-button',
  templateUrl: './table-action-button.component.html',
  styleUrls: ['./table-action-button.component.scss']
})
export class TableActionButtonComponent implements OnInit {
  @Input() item: any;
  @Input() action: MjxTableActionsModel;

  @Output() buttonEvent = new EventEmitter<MjxTableEventsModel>();

  processedAction: MjxTableActionsModel;
  processedMenus: MjxTableActionsModel[];

  constructor() { }

  ngOnInit(): void {
    if (this.action.dynamicAction) {
      this.processedAction = this.action.dynamicAction(this.item);
    } else {
      this.processedAction = this.action;
    }
    if (this.action?.menus) {
      const tempMenus: MjxTableActionsModel[] = [];
      this.processedAction.menus.forEach((menu) => {
        if (menu?.dynamicAction) {
          menu = menu.dynamicAction(this.item);
        }
        tempMenus.push((menu?.dynamicAction) ? menu.dynamicAction(this.item) : menu);
      });

      this.processedMenus = tempMenus;
    }

    if (this.processedAction) {
      this.processedAction.permission = this.processedAction.permission ?? {roles: null, ignore: true};
    }
  }

  emitEvent(menu?: any) {
    if(menu){
     return this.buttonEvent.emit({ name: menu.action, data: this.item });
    }
    return this.buttonEvent.emit({ name: this.actionName, data: this.item });
  }

  get actionName() {
    return this.processedAction.action;
  }

  get color() {
    return this.processedAction.color ? { 'color': this.processedAction.color } : {};
  }
}
