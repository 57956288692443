export enum MjxTableColumnTypesEnum {
  Currency = 'currency',
  Text = 'text',
  Date = 'date',
  DateHourMillis = 'dateHourMillis',
  Actions = 'actions',
  CpfCnpj = 'cpfCnpj',
  DateHour = 'dateHour',
  Percentage = 'percentage',
  Phone = 'phone',
  Link = 'link',
  Icon = 'icon',
  Number = 'number',
  Array = 'array',
  ArrayObject = 'arrayObject',
}
