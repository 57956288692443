import {AfterViewInit, ChangeDetectorRef, Component, OnDestroy, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {MjxTabOption} from "../../../../shared/modules/mjx-navigation-tabs/models/mjx-table-option.model";
import {Store} from "@ngxs/store";
import {Subscription} from "rxjs";
import {map} from "rxjs/operators";
import {ActivatedRoute} from "@angular/router";
import {AccessRolesEnum} from "../../../../shared/enums/access-roles.enum";
import {isPartner} from "../../../../shared/utils/get-context";

@Component({
  selector: 'mjx-detailed-user',
  templateUrl: './detailed-user.component.html',
  styleUrls: ['./detailed-user.component.scss']
})
export class DetailedUserComponent implements OnInit, AfterViewInit, OnDestroy {
  private readonly FIRST_TAB = 0;
  tabs: MjxTabOption[] = [];
  selectedPartnerId: string;
  selectedUserId: string;
  selectedTabIndex = this.FIRST_TAB;
  isEdit = false;
  isProfile = false;

  @ViewChild('dataForm')
  dataFormRef: TemplateRef<any>;

  @ViewChild('accountSecurity')
  accountSecurityRef: TemplateRef<any>;

  currentUserSubs: Subscription;

  constructor(
    private store: Store,
    private activatedRoute: ActivatedRoute,
    private cdr: ChangeDetectorRef
  ) {
  }

  ngOnInit() {
    this.listenRouteState();
  }

  ngAfterViewInit() {
    this.setTabs();
    this.cdr.detectChanges();
  }

  ngOnDestroy() {
    if (this.currentUserSubs) {
      this.currentUserSubs.unsubscribe();
    }
  }

  private setTabs() {
    this.tabs = [this.dataFormTab];

    if (!isPartner && this.isProfile) {
      this.tabs.push(this.accountSecurityTab);
    }
  }
  private get dataFormTab(): MjxTabOption {
    return { icon: 'check', title: 'USERS.TABS.ACCESS_DATA', template: this.dataFormRef };
  }

  private get accountSecurityTab(): MjxTabOption {
    return { icon: 'security', title: 'USERS.TABS.SECURITY', template: this.accountSecurityRef };
  }

  private listenRouteState() {
    this.activatedRoute
      .paramMap
      .pipe(
        map(() => {
          this.isEdit = (window.document.documentURI).includes('edit');

          if (this.isEdit) {
            const userState =  window?.history?.state;

            this.selectedPartnerId = userState.refCustomer;
            this.selectedUserId = userState.id;

            if (userState?.roles) {
              this.isProfile = (userState.roles as any[]).includes(AccessRolesEnum.PartnerAdmin);
            }

            if (userState?.profile) {
              this.isProfile = true;
            }

            this.setTabs();
            this.cdr.detectChanges();
          }
        })
      )
      .subscribe();
  }
}
