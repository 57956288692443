import {FilterField} from "../../../shared/models/filter-field.model";
import {UsersFormFields} from "../enums/users-form-fields.enum";

export abstract class UsersFilterFields {
  static fields: FilterField[] = [
    { name: 'partners', translateKey: 'SHARED.FILTER.ALL_PARTNERS', disabled: false, show: true,
      formControlNames: [UsersFormFields.refCustomer]
    },
    { name: 'fullName', translateKey: 'USERS.TABLE.FULL_NAME', disabled: false, show: true,
      formControlNames: [UsersFormFields.name]
    },
    { name: 'email', translateKey: 'USERS.TABLE.EMAIL', disabled: false, show: true,
      formControlNames: [UsersFormFields.email]
    },
    { name: 'username', translateKey: 'USERS.TABLE.USERNAME', disabled: false, show: true,
      formControlNames: [UsersFormFields.username]
    },
    { name: 'userTypes', translateKey: 'USERS.FILTER.ALL_TYPES', disabled: false, show: true,
      formControlNames: [UsersFormFields.userType]
    }
  ];

  static partnerFields: FilterField[] = [
    { name: 'fullName', translateKey: 'USERS.FILTER.FULL_NAME', disabled: false, show: true,
      formControlNames: [UsersFormFields.name]
    },
    { name: 'email', translateKey: 'USERS.TABLE.EMAIL', disabled: false, show: true,
      formControlNames: [UsersFormFields.email]
    },
    { name: 'username', translateKey: 'USERS.TABLE.USERNAME', disabled: false, show: true,
      formControlNames: [UsersFormFields.username]
    }
  ];
}
