import {Injectable, Injector} from "@angular/core";
import {Action, NgxsOnInit, Selector, State, StateContext, Store} from "@ngxs/store";
import {catchError, finalize, tap, throwError} from "rxjs";
import {SnackbarService} from "../../../services/snackbar.service";
import {TranslateService} from "@ngx-translate/core";
import {AccountSecurityStateModel, defaultAccountSecurityState} from "./account-security-state.model";
import {
  ConfirmAppSetup,
  DeactivateAppSetup,
  GetAppSetupKey,
  GetAppSetupQrCode,
  ResetAppSetup
} from "./app-security.actions";
import {CommonResponse} from "../../../models/response.model";
import QRCode from 'qrcode';
import {SessionState} from "../../../../core/state/session/session.state";
import {PartnersApiService} from "../../../services/partners/partners-api.service";
import {isPartner} from "../../../utils/get-context";
import {CorporateApiService} from "../../../services/corporate/corporate-api.service";
import {ClearMFA, SetMFA} from "../../../../core/state/auth/auth.actions";
import {MFACodes} from "../../../../modules/login/models/mfa-codes.model";
import currentTenant from "../../../../../environments/tenant-config.json";
import {ChangeEnablingMfa} from "../../../../core/state/session/session.actions";

@State<AccountSecurityStateModel>({
  name: 'accountSecurity',
  defaults: defaultAccountSecurityState
})
@Injectable()
export class AccountSecurityState implements NgxsOnInit {
  @Selector()
  static isLoading(state: Partial<AccountSecurityStateModel>) {
    return state.isLoading;
  }

  @Selector()
  static isLoadingStep(state: Partial<AccountSecurityStateModel>) {
    return state.isLoadingStep;
  }

  @Selector()
  static isEmpty(state: Partial<AccountSecurityStateModel>) {
    return state.isEmpty;
  }

  @Selector()
  static hasError(state: Partial<AccountSecurityStateModel>) {
    return state.hasError;
  }

  @Selector()
  static appSetupKey(state: Partial<AccountSecurityStateModel>) {
    return state.appSetupKey;
  }

  @Selector()
  static appSetupQrCode(state: Partial<AccountSecurityStateModel>) {
    return state.appSetupQrCode;
  }

  private apiService;

  constructor(
    private snackbar: SnackbarService,
    private translate: TranslateService,
    private injector: Injector,
    private store: Store
  ) {
    if (isPartner) {
      this.apiService = <PartnersApiService>this.injector.get(PartnersApiService);
    } else {
      this.apiService = <CorporateApiService>this.injector.get(CorporateApiService)
    }
  }

  ngxsOnInit(ctx?: StateContext<any>): any {
    ctx.patchState(defaultAccountSecurityState)
  }

  @Action(ResetAppSetup)
  resetAppSetup(
    stateCtx: StateContext<AccountSecurityStateModel>) {

    stateCtx.patchState({
      isLoadingStep: false,
      appSetupKey: null,
      appSetupQrCode: null
    });
  }

  @Action(GetAppSetupKey)
  getAppSetupKey(
    stateCtx: StateContext<AccountSecurityStateModel>
  ) {
    stateCtx.patchState({isLoadingStep: true});

    return this.apiService
      .mfaAppSetupKey()
      .pipe(
        catchError((err) => {
          stateCtx.patchState({hasError: true});
          return throwError(err);
        }),
        tap((res: CommonResponse<any>) => {
          const { secret } = res.data;

          stateCtx.patchState({appSetupKey: secret});
          stateCtx.dispatch(new GetAppSetupQrCode(secret))
        }),
        finalize(() => {
          stateCtx.patchState({isLoadingStep: false});
        })
      );
  }

  @Action(GetAppSetupQrCode)
  getAppSetupQrCode(
    stateCtx: StateContext<AccountSecurityStateModel>,
    actions: GetAppSetupQrCode
  ) {
    const tokenName = this.getTokenName();
    const qrCodeCompanyName = currentTenant.qrCodeCompanyName;

    const otpAuthUrl = `otpauth://totp/${qrCodeCompanyName}:${tokenName}?secret=${actions.secret}&issuer=${qrCodeCompanyName}`;
    QRCode.toDataURL(otpAuthUrl, { errorCorrectionLevel: 'L' }, (err, url) => {
      if (err) {
        this.snackbar.error('QRCODE_FAILED');
      } else {
        stateCtx.patchState({ appSetupQrCode: url })
      }
    });
  }

  @Action(ConfirmAppSetup)
  confirmAppSetup(
    stateCtx: StateContext<AccountSecurityStateModel>,
    actions: ConfirmAppSetup
  ) {
    stateCtx.patchState({isLoadingStep: true});

    return this.apiService
      .mfaAppConfirm(actions.code)
      .pipe(
        catchError((err) => {
          stateCtx.patchState({hasError: true});
          return throwError(err);
        }),
        tap(() => {
          stateCtx.dispatch(new SetMFA({ [MFACodes.APP]: true }));
          stateCtx.dispatch(new ChangeEnablingMfa(false))
        }),
        finalize(() => {
          stateCtx.patchState({isLoadingStep: false});
        })
      );
  }

  @Action(DeactivateAppSetup)
  deactivateAppSetup(
    stateCtx: StateContext<AccountSecurityStateModel>,
    actions: DeactivateAppSetup
  ) {
    stateCtx.patchState({isLoading: true});

    return this.apiService
      .mfaAppRevoke(actions.code)
      .pipe(
        catchError((err) => {
          stateCtx.patchState({hasError: true});
          return throwError(err);
        }),
        tap(() => {
          stateCtx.dispatch(new ClearMFA());
        }),
        finalize(() => {
          stateCtx.patchState({isLoading: false});
        })
      );
  }

  private getTokenName(): string {
    let tokenName: string;

    const currentUser = this.store.selectSnapshot(SessionState.getCurrentUser);
    if (currentUser) {
      tokenName = currentUser?.username;
    } else {
      tokenName = this.store.selectSnapshot(SessionState.getUsername);
    }

    return tokenName;
  }
}
