import {Component, EventEmitter, Input, Output} from '@angular/core';

@Component({
  selector: 'mjx-empty-message',
  templateUrl: './empty-message.component.html',
  styleUrls: ['./empty-message.component.scss']
})
export class EmptyMessageComponent {
  @Input() emptyMessage = 'SHARED.EMPTY_MESSAGE';
  @Input() actionMessage = 'SHARED.UPDATE_BTN'
  @Input() icon: string;
  @Input() hasAction = false
  @Output() action: EventEmitter<any> = new EventEmitter<any>();
}
