import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'replace'
})
export class ReplacePipe implements PipeTransform {

  transform(value: string, replace: string, replacement: string): string {
    if (!value) return value;

    return value.replace(replace, replacement);
  }

}
