import { Injectable } from '@angular/core';
import {forkJoin, Observable, Subject, switchMap, tap} from "rxjs";
import {currentLang} from "../utils/language-utils";
import {TranslateService} from "@ngx-translate/core";
import {HttpClient} from "@angular/common/http";

@Injectable({
  providedIn: 'root'
})
export class TranslationLoaderUtilsService {
  private loadedModules: { [key: string]: string } = {};
  private reload$: Subject<boolean> = new Subject();

  constructor(private translate: TranslateService, private http: HttpClient) { }

  onReloadChanges(): Observable<boolean> {
    return this.reload$.asObservable();
  }

  loadSingeModule(module: string): Observable<any> {
    const name = `${module}`.toLowerCase();
    const lang = currentLang();
    this.translate.setDefaultLang(lang);

    // if (this.hasModule(module, lang)) {
    //   return of(true);
    // }
    this.loadedModules[module] = lang;

    return this.http.get(`./assets/i18n/${name}/${lang}.json`).pipe(
      tap(translations => {
        const translation = {[name.toUpperCase()]: translations};
        this.translate.setTranslation(lang, translation, true)
      })
    );
  }

  loadMultipleModules(modules: string[]): Observable<any> {
    const modules$: Observable<Object>[] = [];
    const lang = currentLang();

    for (let module of modules) {
      const name = `${module}`.toLowerCase();
      this.loadedModules[module] = lang;
      // if (!this.hasModule(module, lang)) {
      // }
      modules$.push(this.http.get(`./assets/i18n/${name}/${lang}.json`));

    }

    return forkJoin(modules$).pipe(tap(translations => {
        const translation = {};
        for (let i = 0; i < modules.length; i++) {
          translation[modules[i].toUpperCase()] = translations[i];
        }
        this.translate.setTranslation(lang, translation, true);
      })
    );
  }

  reloadTranslations(lang: string): Observable<any> {
    return this.translate.getTranslation(lang)
      .pipe(
        switchMap(() => this.loadMultipleModules(Object.keys(this.loadedModules))),
        tap(() => {
          this.translate.use(lang);
          this.reload$.next(true);
        })
      );
  }
}
