<div class="sections-container">
  <mjx-section [content]="profileName"></mjx-section>
  <br>
  <mjx-section [name]="'INTEGRATION.FORM.DASHBOARD'" [content]="dashboards"></mjx-section>
  <br>
  <mjx-section [name]="'INTEGRATION.FORM.USERS'" [content]="users"></mjx-section>
  <br>
  <mjx-section [name]="'INTEGRATION.FORM.RECEIVERS'" [content]="receivers"></mjx-section>
  <br>
  <mjx-section [name]="'INTEGRATION.FORM.SALES'" [content]="sales"></mjx-section>
  <br>
  <mjx-section [name]="'INTEGRATION.FORM.PAYMENTS'" [content]="payments"></mjx-section>
  <br>
  <mjx-section [name]="'INTEGRATION.FORM.ACCESS_KEYS'" [content]="accessKeys"></mjx-section>
</div>

<div class="actions">
  <button mat-button class="custom-button custom-secondary-button" routerLink="/users/profile-permissions" *hasPermission="{roles: Roles.ALL}">
    <span>{{ 'SHARED.CANCEL_BTN' | translate }}</span>
  </button>

  <button mat-button class="custom-button" (click)="save()">
    <span>{{ 'SHARED.SAVE_BTN' | translate }}</span>
  </button>
</div>

<ng-template #profileName>
  <form [formGroup]="permissionsForm" class="row">
    <mjx-input class="col-5">
      <mat-label>
        {{ 'USERS.FORM.PROFILE_TITLE' | translate }}
      </mat-label>
      <mat-form-field>
        <input matInput formControlName="profileName" autocomplete="off">
      </mat-form-field>
    </mjx-input>
  </form>
</ng-template>

<ng-template #dashboards>
  <form [formGroup]="dashboardForm" class="row">
    <mat-checkbox (click)="allSelect(dashboardForm, $event)"
      class="custom-checkbox col-auto" formControlName="all">{{'USERS.CHECKBOXES.ALL' | translate}}</mat-checkbox>
    <hr class="col-1 separator">
    <mat-checkbox class="custom-checkbox col-auto" formControlName="visualize">{{'USERS.CHECKBOXES.VIEW' | translate}}</mat-checkbox>
  </form>
</ng-template>

<ng-template #users>
  <form [formGroup]="usersForm" class="row">
    <mat-checkbox (click)="allSelect(usersForm, $event)"
      class="custom-checkbox col-auto" formControlName="all">{{'USERS.CHECKBOXES.ALL' | translate}}</mat-checkbox>
    <hr class="col-1 separator">
    <mat-checkbox class="custom-checkbox col-auto" formControlName="list">{{'USERS.CHECKBOXES.LIST' | translate}}</mat-checkbox>
    <mat-checkbox class="custom-checkbox col-auto" formControlName="create">{{'USERS.CHECKBOXES.CREATE' | translate}}</mat-checkbox>
    <mat-checkbox class="custom-checkbox col-auto" formControlName="update">{{'USERS.CHECKBOXES.UPDATE' | translate}}</mat-checkbox>
    <mat-checkbox class="custom-checkbox col-auto" formControlName="remove">{{'USERS.CHECKBOXES.REMOVE' | translate}}</mat-checkbox>
  </form>
</ng-template>

<ng-template #receivers>
  <form [formGroup]="receiversForm" class="row">
    <mat-checkbox (click)="allSelect(receiversForm, $event)"
      class="custom-checkbox col-auto" formControlName="all">{{'USERS.CHECKBOXES.ALL' | translate}}</mat-checkbox>
    <hr class="col-1 separator">
    <mat-checkbox class="custom-checkbox col-auto" formControlName="list">{{'USERS.CHECKBOXES.LIST' | translate}}</mat-checkbox>
    <mat-checkbox class="custom-checkbox col-auto" formControlName="create">{{'USERS.CHECKBOXES.CREATE' | translate}}</mat-checkbox>
    <mat-checkbox class="custom-checkbox col-auto" formControlName="update">{{'USERS.CHECKBOXES.UPDATE' | translate}}</mat-checkbox>
    <mat-checkbox class="custom-checkbox col-auto" formControlName="remove">{{'USERS.CHECKBOXES.REMOVE' | translate}}</mat-checkbox>
    <mat-checkbox class="custom-checkbox col-auto" formControlName="approve">{{'USERS.CHECKBOXES.APPROVE' | translate}}</mat-checkbox>
  </form>
</ng-template>

<ng-template #sales>
  <form [formGroup]="salesForm" class="row">
    <mat-checkbox (click)="allSelect(salesForm, $event)"
      class="custom-checkbox col-auto" formControlName="all">{{'USERS.CHECKBOXES.ALL' | translate}}</mat-checkbox>
    <hr class="col-1 separator">
    <mat-checkbox class="custom-checkbox col-auto" formControlName="list">{{'USERS.CHECKBOXES.LIST' | translate}}</mat-checkbox>
    <mat-checkbox class="custom-checkbox col-auto" formControlName="create">{{'USERS.CHECKBOXES.CREATE' | translate}}</mat-checkbox>
    <mat-checkbox class="custom-checkbox col-auto" formControlName="update">{{'USERS.CHECKBOXES.UPDATE' | translate}}</mat-checkbox>
    <mat-checkbox class="custom-checkbox col-auto" formControlName="remove">{{'USERS.CHECKBOXES.REMOVE' | translate}}</mat-checkbox>
    <mat-checkbox class="custom-checkbox col-auto" formControlName="reports">{{'USERS.CHECKBOXES.REPORTS' | translate}}</mat-checkbox>
  </form>
</ng-template>

<ng-template #payments>
  <form [formGroup]="paymentsForm" class="row">
    <mat-checkbox (click)="allSelect(paymentsForm, $event)"
      class="custom-checkbox col-auto" formControlName="all">{{'USERS.CHECKBOXES.ALL' | translate}}</mat-checkbox>
    <hr class="col-1 separator">
    <mat-checkbox class="custom-checkbox col-auto" formControlName="list">{{'USERS.CHECKBOXES.LIST' | translate}}</mat-checkbox>
    <mat-checkbox class="custom-checkbox col-auto" formControlName="create">{{'USERS.CHECKBOXES.CREATE' | translate}}</mat-checkbox>
    <mat-checkbox class="custom-checkbox col-auto" formControlName="update">{{'USERS.CHECKBOXES.UPDATE' | translate}}</mat-checkbox>
    <mat-checkbox class="custom-checkbox col-auto" formControlName="remove">{{'USERS.CHECKBOXES.REMOVE' | translate}}</mat-checkbox>
    <mat-checkbox class="custom-checkbox col-auto" formControlName="reports">{{'USERS.CHECKBOXES.REPORTS' | translate}}</mat-checkbox>
  </form>
</ng-template>

<ng-template #accessKeys>
  <form [formGroup]="accessKeysForm" class="row">
    <mat-checkbox (click)="allSelect(accessKeysForm, $event)"
      class="custom-checkbox col-auto" formControlName="all">{{'USERS.CHECKBOXES.ALL' | translate}}</mat-checkbox>
    <hr class="col-1 separator">
    <mat-checkbox class="custom-checkbox col-auto" formControlName="list">{{'USERS.CHECKBOXES.LIST' | translate}}</mat-checkbox>
    <mat-checkbox class="custom-checkbox col-auto" formControlName="create">{{'USERS.CHECKBOXES.CREATE' | translate}}</mat-checkbox>
    <mat-checkbox class="custom-checkbox col-auto" formControlName="remove">{{'USERS.CHECKBOXES.REMOVE' | translate}}</mat-checkbox>
    <mat-checkbox class="custom-checkbox col-auto" formControlName="status">{{'USERS.CHECKBOXES.STATE' | translate}}</mat-checkbox>
  </form>
</ng-template>
