import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Store } from '@ngxs/store';
import { GetCorporateTransactions, InvokeCorporateWebhook } from '../../states/transactions/transactions.actions';
import { Subscription } from 'rxjs';
import { TransactionsTypesEnum } from 'src/app/shared/enums/transactions-types.enum';

@Component({
  selector: 'mjx-webhook-confirm-dialog',
  templateUrl: './webhook-confirm-dialog.component.html',
  styleUrls: ['./webhook-confirm-dialog.component.scss']
})
export class WebhookConfirmDialogComponent implements OnInit {
  cancelBatchNotification = false;
  webhookSubscriptions: Subscription[] = [];
  invokeData = []
  resolvedCount = 0;
  allInvocationsCompleted = false;
  batchNotification = false;

  constructor(
    private store: Store,
    @Inject(MAT_DIALOG_DATA) public details: any
  ) { }

  ngOnInit() {
    this.invokeData = this.details.data.filter(transaction => transaction.state !== 'Registered')
  }

  async confirmBatchNotification() {
    this.details.function.clear()
    this.batchNotification = true;
    for (const transaction of  this.invokeData) {
      if (!this.cancelBatchNotification) {
        try {
          this.resolvedCount++
          await this.invokeWebhook(transaction.id);
        } catch (error) {
          console.error(`Error invoking webhook for transaction ${transaction.id}: ${error}`);
        }
      } else {
        break;
      }
    }
    if(this.resolvedCount === this.invokeData.length){
      this.allInvocationsCompleted = true;
    }
  }

  private invokeWebhook(transactionId: any): Promise<any> {
    return new Promise((resolve, reject) => {
      const subscription = this.store.dispatch(new InvokeCorporateWebhook(transactionId))
        .subscribe({
          next: (transaction) => {
            subscription.unsubscribe();
            resolve(transaction);
          },
          error: (error) => {
            subscription.unsubscribe();
            reject(error);
          }
        });
    });
  }

  cancelBatchNotificationFunction() {
    this.cancelBatchNotification = true;
    this.batchNotification = false
    this.webhookSubscriptions.forEach(sub => sub.unsubscribe());
  }

  closeBatchNotifications() {
    if(this.resolvedCount > 0) {
      this.store.dispatch(new GetCorporateTransactions(
        TransactionsTypesEnum.Pix,
        (this.details.filter)
      ))
    }
  }
}
