import { UntypedFormControl} from "@angular/forms";

export function cnpjValidator() {
  return (control: UntypedFormControl) => {
    if (!control.value) {
      return null;
    }

    const cnpj = control.value.replace(/[^\d]+/g, '');

    if (cnpj.length !== 14) {
      return { cnpj: true };
    }

    if (/^(\d)\1{13}$/.test(cnpj)) {
      return { cnpj: true };
    }

    let cnpjSize = cnpj.length - 2;
    let cnpjNumbers = cnpj.substring(0, cnpjSize);
    const cnpjDigits = cnpj.substring(cnpjSize);
    let sum = 0;
    let pos = cnpjSize - 7;

    for (let i = cnpjSize; i >= 1; i--) {
      sum += parseInt(cnpjNumbers.charAt(cnpjSize - i)) * pos--;
      if (pos < 2) pos = 9;
    }

    let resultado = sum % 11 < 2 ? 0 : 11 - (sum % 11);

    if (resultado !== parseInt(cnpjDigits.charAt(0))) {
      return { cnpj: true };
    }

    cnpjSize += 1;
    cnpjNumbers = cnpj.substring(0, cnpjSize);
    sum = 0;
    pos = cnpjSize - 7;

    for (let i = cnpjSize; i >= 1; i--) {
      sum += parseInt(cnpjNumbers.charAt(cnpjSize - i)) * pos--;
      if (pos < 2) pos = 9;
    }

    resultado = sum % 11 < 2 ? 0 : 11 - (sum % 11);

    if (resultado !== parseInt(cnpjDigits.charAt(1))) {
      return { cnpj: true };
    }

    return null;
  };
}

export function cpfValidator() {
  return (control: UntypedFormControl) => {
    if (!control.value) {
      return null;
    }

    const isValid = cpfValidation(control.value);
    return (isValid) ? null : { cpf: true };
  };
}

export function cpfMaxValueValidator() {
  return (control: UntypedFormControl) => {
    if (!control.value) {
      return null;
    }
    let isValid = true;

    const value = new Number(control.value);

    if (value > 45_000) {
      isValid = false;
    }

    return (isValid) ? null : { maxValueCpf: true };
  };
}

export function cpfValidation(cpf: string): boolean {
  const cleanCpf = cpf.replace(/[^\d]+/g,'');

  let isValid = true;

  if (cleanCpf.length !== 11) {
    isValid = false;
  }

  switch (cleanCpf) {
    case '00000000000':
    case '11111111111':
    case '22222222222':
    case '33333333333':
    case '44444444444':
    case '55555555555':
    case '66666666666':
    case '77777777777':
    case '88888888888':
    case '99999999999':
      isValid = false;
  }

  if (isValid) {
    let sum = 0;
    let rest;

    for (let i = 1; i <= 9; i++) {
      sum += parseInt(cleanCpf.substring(i - 1, i)) * (11 - i);
    }

    rest = (sum * 10) % 11;

    if (rest === 10 || rest === 11) {
      rest = 0;
    }

    if (rest !== parseInt(cleanCpf.substring(9, 10))) {
      isValid = false;
    }

    if (isValid) {
      sum = 0;

      for (let i = 1; i <= 10; i++) {
        sum += parseInt(cleanCpf.substring(i - 1, i)) * (12 - i);
      }
      rest = (sum * 10) % 11;

      if (rest === 10 || rest === 11) {
        rest = 0;
      }

      if (rest !== parseInt(cleanCpf.substring(10, 11))) {
        isValid = false;
      }
    }

  }
  return isValid;
}
