import {Component, OnInit} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from "@angular/forms";
import {UserFormModel, UserModel} from "../../../models/user.model";
import {Select, Store} from "@ngxs/store";
import {UsersState} from "../../../states/users.state";
import {Observable, of, Subscription} from "rxjs";
import {SessionState} from "../../../../../core/state/session/session.state";
import {AccountModel} from "../../../../../core/models/account.model";
import {UserTypeEnum} from "../../../../../shared/enums/user-type.enum";
import {PermissionEnum} from "../../../../../shared/models/permission.enum";
import {MatDialog} from "@angular/material/dialog";
import {ActivatedRoute, Router} from "@angular/router";
import {SessionService} from "../../../../../core/services/session.service";
import {ClearLinkedAccounts, GetAccountsData} from "../../../../../core/state/accounts/accounts.actions";
import {
  ClearUserDetails,
  CreateUser,
  EditUser,
  GetUserDetails,
  ResetSavingUsersState,
  UpdateUserPassword
} from "../../../states/users.actions";
import {AccessRolesEnum} from "../../../../../shared/enums/access-roles.enum";
import {QuickInfoMessage} from "../../../../../shared/components/quick-info/models/quick-info-message.model";
import {RoutesEnum} from "../../../../../shared/enums/routes.enum";
import {MatSelectChange} from "@angular/material/select";
import {map} from "rxjs/operators";
import {
  emailValidator,
  emptySpaces,
  lowercaseValidator,
  maxLengthTrimValidator,
  minLengthTrimValidator,
  numberValidator,
  specialCharacterValidator,
  uppercaseValidator,
  usernamePattern,
  whiteSpacesValidator
} from "../../../../../shared/utils/password-validators";
import {partnerUserTypeOptions, userTypeOptions} from "../../../../../shared/utils/user-type-options";
import {Roles} from "../../../../../shared/utils/roles-list";

@Component({
  selector: 'mjx-partner-users-data-form',
  templateUrl: './partner-users-data-form.component.html',
  styleUrls: ['./partner-users-data-form.component.scss']
})
export class PartnerUsersDataFormComponent implements OnInit {
  protected readonly EMPTY = '';

  isEdit = false;
  isProfile = false;
  isEditUserData = false;
  isEditAccountData = false;
  hasPartner = false;
  loadingFilter = false;
  hasUserType = false;
  showInfo = false;
  hideReset = false;
  userForm: UntypedFormGroup;
  user: UserModel;

  userTypeOptions = userTypeOptions;
  partnerUserTypeOptions = partnerUserTypeOptions;

  @Select(UsersState.getSavingState)
  isSaving$: Observable<boolean>;

  @Select(UsersState.getLoadingState)
  isLoading$: Observable<boolean>;

  @Select(UsersState.getUser)
  userData: Observable<UserModel>;

  @Select(SessionState.getCurrentUserPermissions)
  currentPermissions$: Observable<any>;

  selectedAccounts$: Observable<AccountModel[]> = of([]);

  permissionSub: Subscription;
  userTypeSubs: Subscription;
  refCustomerSubs: Subscription;
  detailedUserSubs: Subscription;
  userTypes = UserTypeEnum;
  selectedPartnerId: string;

  roles = Roles;
  permissionsEnum = PermissionEnum;

  constructor(
    protected fb: UntypedFormBuilder,
    protected dialog: MatDialog,
    protected router: Router,
    protected activatedRoute: ActivatedRoute,
    protected store: Store,
    protected sessionService: SessionService
  ) { this.buildUserForm(); }

  ngOnInit(): void {
    this.store.dispatch(new ClearLinkedAccounts());
    this.store.dispatch(new ClearUserDetails());
    this.listenRouteState();

    this.hasUserType = true;
    this.listenDetailedUser();

    this.store.dispatch(new ResetSavingUsersState());
  }

  ngOnDestroy() {
    if (this.detailedUserSubs && !this.detailedUserSubs.closed) {
      this.detailedUserSubs.unsubscribe();
    }
  }

  get disableAccountsForm(): boolean {
    if (this.selectedPartnerId && this.user.id) {
      return false;
    }

    if (this.sessionService.userHasRole(AccessRolesEnum.PartnerAdmin) && this.isEdit) {
      return false;
    }
    return true;
  }

  get selectedUserType(): UserTypeEnum {
    return this.userForm.get('userType')?.value;
  }

  get canSelectAccount(): boolean {
    const types = [UserTypeEnum.Operator, UserTypeEnum.PartnerMemberFin]
    return types.includes(this.selectedUserType);
  }

  get isPartnerUser(): boolean {
    const partners = [UserTypeEnum.Operator, UserTypeEnum.Admin, UserTypeEnum.PartnerMemberFin]
    return partners.includes(this.userForm.get('userType')?.value);
  }

  get pageTitle(): string {
    return `USERS.${this.isEdit ? 'EDIT' : 'NEW'}.TITLE`;
  }

  get inputDisabled(): boolean {
    return this.userForm.invalid;
  }

  get saveEditLabel(): string {
    let label = 'USERS.SAVE_BTN';
    if (!this.isEditUserData)  {
      label = 'USERS.SAVE_EDIT_USER';
    } else if(!(this.isEditAccountData && this.isProfile)) {
      label = 'USERS.SAVE_EDIT_ACCOUNT';
    }

    return label;
  }

  get infoMessages(): QuickInfoMessage[] {
    return [
      { icon: 'description', messages: ['USERS.INFO_TEXT'] },
    ]
  }

  changeInfoDialog() {
    this.showInfo = !this.showInfo;
  }

  emitUserType(event) {
    this.userForm?.get('roles')?.setValue([event.value]);
  }

  getAccounts(event: MatSelectChange) {
    const id = event.value;
    this.store.dispatch(new GetAccountsData(id));
  }

  doSave() {
    const formValue: UserFormModel = this.userForm.value;
    const userObj: UserFormModel = this.sanitizeForm(formValue);

    if (this.isEdit) {
      if (this.isProfile && this.isEditAccountData) {
        const {currentPassword, password, confirmPassword} = this.userForm.value;

        this.store.dispatch(new UpdateUserPassword(currentPassword, password, confirmPassword));

        this.disableEdition();
      } else if (this.isEditUserData || !this.isProfile) {
        this.store.dispatch(new EditUser(userObj, this.user.id));
      }
    } else {
      delete userObj.confirmPassword
      delete userObj.document;
      delete userObj.docType;

      if (userObj.refCustomer === null || userObj.refCustomer === undefined) {
        delete userObj.refCustomer;
      }

      if (userObj.userType === UserTypeEnum.PartnerMemberFin) {
        userObj.roles = [UserTypeEnum.PartnerMemberFin];
        delete userObj.userType;
      }

      this.store.dispatch(new CreateUser(userObj));
    }
  }

  editUserData() {
    this.disableEdition();

    this.isEditUserData = true;
  }

  editAccountData() {
    this.disableEdition();

    if (this.isProfile) {
      this.userForm.patchValue({
        password: '',
        currentPassword: '',
        confirmPassword: ''
      });

      this.userForm.get('username').disable();
    } else {
      this.userForm.patchValue({
        password: 'PLACEHOLDER#1a',
      });
    }

    this.isEditAccountData = true;
  }

  disableEdition() {
    this.isEditUserData = false;
    this.isEditAccountData = false;

    this.populateEditForm();

    if (this.isProfile) {
      this.userForm.patchValue({
        password: 'PLACEHOLDER#1a',
        currentPassword: 'PLACEHOLDER',
        confirmPassword: 'PLACEHOLDER#1a'
      });
    }
  }

  navigateBack() {
    if (this.sessionService.userHasRole(AccessRolesEnum.PartnerMember)) {
      this.router.navigateByUrl(`/${RoutesEnum.Sales}`);
    } else {
      this.router.navigateByUrl(`/${RoutesEnum.Users}`);
    }
  }

  protected populateEditForm() {
    let userType = this.user?.roles
      && this.user.roles.length > 0 ? this.user.roles[0] === 'Partner' ? this.user.roles[1] : this.user.roles[0] : null;

    if (this.user?.roles?.includes(UserTypeEnum.PartnerMemberFin)) {
      userType = UserTypeEnum.PartnerMemberFin.toString();
    }

    this.userForm.patchValue({
      email: this.user.email,
      fullName: this.user.fullName,
      userType,
      username: this.user.username,
    });

    this.hasUserType = true;
  }

  protected sanitizeForm(form: UserFormModel): UserFormModel {
    for (let key in form) {
      if (form[key] && key !== 'roles' && key !== 'refCustomer') {
        form[key] = form[key].trim();
      }
    }

    return form;
  }

  protected getUserDetails(userId: string) {
    this.listenDetailedUser();

    this.store.dispatch(new GetUserDetails(userId));
  }

  protected listenDetailedUser() {
    this.detailedUserSubs = this.userData.subscribe(user => {
      if (user) {
        this.isEdit = true;
        this.user = user;

        this.populateEditForm();
      }
    });
  }

  protected listenRouteState() {
    this.activatedRoute
      .paramMap
      .pipe(
        map(() => {
          const state: any = window?.history?.state;
          const isEditPage = (window.document.documentURI).includes('edit');

          this.isProfile = state?.profile ?? false;
          this.user = (state as UserModel);
          this.isEdit = isEditPage;

          if (state?.id) {
            if (state?.userType) {
              this.emitUserType({value: this.user.roles[1]});
            }

            if (state?.refCustomer) {
              this.selectedPartnerId = state?.refCustomer;
            }

            if (!this.isProfile) {
              this.getUserDetails(this.user.id);
            }

            this.buildUserForm();
            this.populateEditForm();
          } else if (isEditPage) {
            this.navigateBack();
          } else {
            this.buildUserForm();
          }

        })
      )
      .subscribe();
  }

  protected checkPasswords(group: UntypedFormGroup) {
    const NO_PASSWORD_PROFILES = [UserTypeEnum.Admin, UserTypeEnum.Operator];
    if (NO_PASSWORD_PROFILES.includes(group.controls['userType']?.value)) {
      return null
    }

    let pass = group.controls['password']?.value;
    let confirmPass = group.controls['confirmPassword']?.value;

    return pass === confirmPass ? null : { notSame: true }
  }

  protected buildUserForm() {
    if (this.isProfile) {
      this.userForm = this.fb.group({
        email: [this.EMPTY, [
          Validators.required,
          emailValidator(),
          minLengthTrimValidator(5),
          maxLengthTrimValidator(100),
          whiteSpacesValidator()
        ]],
        fullName: [this.EMPTY, [
          Validators.required,
          minLengthTrimValidator(5),
          maxLengthTrimValidator(100),
          whiteSpacesValidator()
        ]],
        userType: [this.EMPTY],
        username: [this.EMPTY, [
          Validators.required,
          minLengthTrimValidator(5),
          maxLengthTrimValidator(20),
          emptySpaces(),
          whiteSpacesValidator(),
          usernamePattern()
        ]],
        currentPassword: ['PLACEHOLDER#1a', [
          Validators.required,
          Validators.minLength(8),
          Validators.maxLength(250),
        ]],
        password: ['PLACEHOLDER', [
          Validators.required,
          Validators.minLength(8),
          Validators.maxLength(250),
          Validators.compose([
            lowercaseValidator(),
            uppercaseValidator(),
            numberValidator(),
            specialCharacterValidator()
          ])
        ]],
        confirmPassword: ['PLACEHOLDER#1a', [
          Validators.required,
          Validators.minLength(8),
          Validators.maxLength(250),
          Validators.compose([
            lowercaseValidator(),
            uppercaseValidator(),
            numberValidator(),
            specialCharacterValidator()
          ])
        ]]
      }, {validator: this.checkPasswords});
    } else if (!this.isEdit) {
      this.userForm = this.fb.group({
        userType: [this.EMPTY, [
          Validators.required
        ]],
        username: [this.EMPTY, [
          Validators.required,
          minLengthTrimValidator(5),
          maxLengthTrimValidator(20),
          emptySpaces(),
          usernamePattern()
        ]],
        email: [this.EMPTY, [
          Validators.required,
          emailValidator(),
          minLengthTrimValidator(5),
          maxLengthTrimValidator(100),
          whiteSpacesValidator()
        ]],
        fullName: [this.EMPTY, [
          Validators.required,
          minLengthTrimValidator(5),
          maxLengthTrimValidator(100),
          whiteSpacesValidator()
        ]],
        password: [this.EMPTY, [
          Validators.required,
          Validators.minLength(8),
          Validators.maxLength(250),
          Validators.compose([
            lowercaseValidator(),
            uppercaseValidator(),
            numberValidator(),
            specialCharacterValidator()
          ])
        ]],
        confirmPassword: [this.EMPTY, [
          Validators.required,
          Validators.minLength(8),
          Validators.maxLength(250),
          Validators.compose([
            lowercaseValidator(),
            uppercaseValidator(),
            numberValidator(),
            specialCharacterValidator()
          ])
        ]]
      }, {validator: this.checkPasswords});
    } else if (this.isEdit) {
      this.userForm = this.fb.group({
        email: [this.EMPTY, [
          Validators.required,
          emailValidator(),
          minLengthTrimValidator(5),
          maxLengthTrimValidator(100),
          whiteSpacesValidator()
        ]],
        fullName: [this.EMPTY, [
          Validators.required,
          minLengthTrimValidator(5),
          maxLengthTrimValidator(100),
          whiteSpacesValidator()
        ]],
        password: [this.EMPTY, [
          Validators.minLength(8),
          Validators.maxLength(250),
          Validators.compose([
            lowercaseValidator(),
            uppercaseValidator(),
            numberValidator(),
            specialCharacterValidator()
          ])
        ]],
        confirmPassword: [this.EMPTY, [
          Validators.minLength(8),
          Validators.maxLength(250),
          Validators.compose([
            lowercaseValidator(),
            uppercaseValidator(),
            numberValidator(),
            specialCharacterValidator()
          ])
        ]]
      });
    }
  }
}
