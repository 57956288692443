import {PartnerAccount} from "../../modules/partners/models/partner-account.model";

export function  orderPartnersAccountsByImplicitID(accounts: PartnerAccount[]): PartnerAccount[] {
  try {
    return accounts.sort((a, b) => {
      const aTempId = Number(a.accountNumber.substring(2, 5)).valueOf();
      const bTempId = Number(b.accountNumber.substring(2, 5)).valueOf();
      return aTempId - bTempId;
    });
  } catch (error) {
    return accounts;
  }
}
