import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SelectAccountComponent } from './components/select-account/select-account.component';
import {MaterialElementsModule} from "../../modules/material-elements/material-elements.module";
import {MjxInputModule} from "../../modules/mjx-input/mjx-input.module";
import {NgxsModule} from "@ngxs/store";
import {AccountsState} from "../../../core/state/accounts/accounts.state";
import {SharedPipesModule} from "../../pipes/shared-pipes.module";
import { TranslateModule } from '@ngx-translate/core';



@NgModule({
  declarations: [
    SelectAccountComponent
  ],
  imports: [
    CommonModule,
    MaterialElementsModule,
    MjxInputModule,
    SharedPipesModule,
    TranslateModule,
    NgxsModule.forFeature([AccountsState])
  ],
  exports: [
    SelectAccountComponent
  ]
})
export class ChangeAccountModule { }
