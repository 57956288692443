import {CustomSelectOptionModel} from "../models/custom-select-option.model";
import {DocumentsEnum} from "../modules/file-upload/enums/documents.enum";

export const licensesOptions: CustomSelectOptionModel<DocumentsEnum>[] = [
  { value: DocumentsEnum.CertificateOfCompanyIncorporation, translateKey: 'DOCUMENTS.COMPANY_INCORPORATION_CERTIFICATE'},
  { value: DocumentsEnum.CertificateOfGoodStanding, translateKey: 'DOCUMENTS.REGULARITY_CERTIFICATE'},
  { value: DocumentsEnum.CertificateOfIncumbency, translateKey: 'DOCUMENTS.INCUMBENCY_CERTIFICATE'},
  { value: DocumentsEnum.CorporateStructureChart, translateKey: 'DOCUMENTS.CORPORATE_STRUCTURE_FRAMEWORK'},
  { value: DocumentsEnum.LatestCompanyAssociationAddendum, translateKey: 'DOCUMENTS.MEMORANDUM_RECENT_ARTICLES_OF_ASSOCIATION'},
  { value: DocumentsEnum.CopyOfBankAccount, translateKey: 'DOCUMENTS.BANK_ACCOUNT_DETAILS'},
  { value: DocumentsEnum.OfficialAuthorizationOrLicense, translateKey: 'DOCUMENTS.AUTHORIZATION_OR_LICENSE_FINS'},
  { value: DocumentsEnum.StocksRegistration, translateKey: 'DOCUMENTS.REGISTRATION_SHARES'},
  { value: DocumentsEnum.FinancialStatement, translateKey: 'DOCUMENTS.LAST_FINANCIAL_DEMONSTRATIVE'},
  { value: DocumentsEnum.BankingChannel, translateKey: 'DOCUMENTS.BANKING_CHANNEL'},
  { value: DocumentsEnum.PassportOfPersonInCharge, translateKey: 'DOCUMENTS.PASSPORT_RESPONSIBLE'},
  { value: DocumentsEnum.SignedDueDiligence, translateKey: 'DOCUMENTS.DUE_DILIGENCE'},
  { value: DocumentsEnum.PSPContract, translateKey: 'DOCUMENTS.CONTRACT_PSP'},
  { value: DocumentsEnum.SocialContract, translateKey: 'DOCUMENTS.SOCIAL_CONTRACT'},
];

export const licensesDocsTranslate: CustomSelectOptionModel<DocumentsEnum>[] = [
  { value: DocumentsEnum.CertificateOfCompanyIncorporation, translateKey: 'DOCUMENTS.COMPANY_INCORPORATION_CERTIFICATE'},
  { value: DocumentsEnum.CertificateOfGoodStanding, translateKey: 'DOCUMENTS.REGULARITY_CERTIFICATE'},
  { value: DocumentsEnum.CertificateOfIncumbency, translateKey: 'DOCUMENTS.INCUMBENCY_CERTIFICATE'}
];
