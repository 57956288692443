import {Directive, ElementRef, EventEmitter, HostListener, Input, Output} from '@angular/core';
import {MatTabHeaderPaginationModel} from "../models/mat-tab-header-pagination.model";
import {MatHeaderPaginationEventsEnum} from "../enums/mat-header-pagination-events.enum";

@Directive({
  selector: '[mjxMatTabHeaderClick]'
})
export class MatTabHeaderClickDirective {
  @Input() currentTabIndex: number;
  @Output('tabPaginationChange') tabPagination = new EventEmitter<MatTabHeaderPaginationModel>();

  @HostListener('click', ['$event'])
  tabClick(event: any) {
    if (event.target) {
      const success = this.threatClickElement(event.target);

      if (!success) {
        const element = event.target.parentElement;
        this.threatClickElement(element);
      }
    }
  }

  private threatClickElement(element: any): boolean {
    const elementCssClasses: string = element.className;

    if (!elementCssClasses['includes']) {
    } else if (elementCssClasses.includes('mat-tab-header-pagination-after')) {
      this.tabPagination.emit({
        currentIndex: this.currentTabIndex,
        event: MatHeaderPaginationEventsEnum.After
      });

    } else if (elementCssClasses.includes('mat-tab-header-pagination-before')) {
      this.tabPagination.emit({
        currentIndex: this.currentTabIndex,
        event: MatHeaderPaginationEventsEnum.Before
      });
    }

    return true;
  }

}
