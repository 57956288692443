import { Pipe, PipeTransform } from '@angular/core';
import {PolicyResourceTypes} from "../../modules/configuration/enums/policy/policy-resource-type.enum";
import {environment} from "../../../environments/environment";

@Pipe({
  name: 'policyResourceTypeTranslateKey'
})
export class TranslatePolicyResourceTypePipe implements PipeTransform {
  transform(type: PolicyResourceTypes, isAdmin: boolean = false): string {
    let translateKey: string = type.toString();

    switch (type) {
      case PolicyResourceTypes.Corporate:
        translateKey = (isAdmin)
          ? 'SHARED.ENUM.POLICY_RESOURCE_TYPES.CORPORATE'
          : environment.applicationTitle;
        break;
      case PolicyResourceTypes.PartnerAccount:
        translateKey = (isAdmin)
          ? 'SHARED.ENUM.POLICY_RESOURCE_TYPES.PARTNER'
          : 'SHARED.ENUM.POLICY_RESOURCE_TYPES.YOUR_COMPANY' ;
        break;
    }

    return translateKey;
  }
}
