import {ErrorsReasonEnum} from "../enums/errors-reason.enum";

export const errorsReasonMap = {
  [ErrorsReasonEnum.TimeoutError]: "ERRORS.TIMEOUT_ERROR",
  [ErrorsReasonEnum.UserTypeNotAllowed]: 'ERRORS.USERTYPENOTALLOWED',
  [ErrorsReasonEnum.CredentialsNotFound]: 'ERRORS.CREDENTIALSNOTFOUND',
  [ErrorsReasonEnum.UserPasswordNotMatch]: 'ERRORS.USERPASSWORDNOTMATCH',
  [ErrorsReasonEnum.UserNotFound]: 'ERRORS.USERNOTFOUND',
  [ErrorsReasonEnum.UserUniqueUserName]: 'ERRORS.USERUNIQUEUSERNAME',
  [ErrorsReasonEnum.UserUniqueUserEmail]: 'ERRORS.USERUNIQUEUSEREMAIL',
  [ErrorsReasonEnum.CustomerInvalid]: 'ERRORS.CUSTOMERINVALID',
  [ErrorsReasonEnum.CustomerAccountNotActive]: 'ERRORS.CUSTOMERACCOUNTNOTACTIVE',
  [ErrorsReasonEnum.CustomerAccountRequired]: 'ERRORS.CUSTOMERACCOUNTREQUIRED',
  [ErrorsReasonEnum.CustomerAccountInvalid]: 'ERRORS.CUSTOMERACCOUNTINVALID',
  [ErrorsReasonEnum.CustomerAccountIsNotActive]: 'ERRORS.CUSTOMERACCOUNTISNOTACTIVE',
  [ErrorsReasonEnum.AuthenticationTokenInvalid]: 'ERRORS.AUTHENTICATIONTOKENINVALID',
  [ErrorsReasonEnum.AccessKeyUnauthorized]: 'ERRORS.ACCESSKEYUNAUTHORIZED',
  [ErrorsReasonEnum.BillingPlanShouldExistsOnlyOneStandardPlan]: 'ERRORS.BILLINGPLANSHOULDEXISTSONLYONESTANDARDPLAN',
  [ErrorsReasonEnum.BillingPlanShouldExistsOnlyActivePlan]: 'ERRORS.BILLINGPLANSHOULDEXISTSONLYACTIVEPLAN',
  [ErrorsReasonEnum.BillingPlanShouldExistsOnlyWaitingPlan]: 'ERRORS.BILLINGPLANSHOULDEXISTSONLYWAITINGPLAN',
  [ErrorsReasonEnum.BillingPlanNoStandardPlanFound]: 'ERRORS.BILLINGPLANNOSTANDARDPLANFOUND',
  [ErrorsReasonEnum.BillingPlanNoEditablePlanFound]: 'ERRORS.BILLINGPLANNOEDITABLEPLANFOUND',
  [ErrorsReasonEnum.BankingRoutingCNAEAlreadyExists]: 'ERRORS.BANKINGROUTINGCNAEALREADYEXISTS',
  [ErrorsReasonEnum.TransactionStateTransitionNotFoundForState]: 'ERRORS.TRANSACTIONSTATETRANSITIONNOTFOUNDFORSTATE',
  [ErrorsReasonEnum.TransactionStateTransitionInvalidForState]: 'ERRORS.TRANSACTIONSTATETRANSITIONINVALIDFORSTATE',
  [ErrorsReasonEnum.TransactionStateNotChanged]: 'ERRORS.TRANSACTIONSTATENOTCHANGED',
  [ErrorsReasonEnum.InvalidInput]: 'ERRORS.INVALIDINPUT',
  [ErrorsReasonEnum.InvalidRequestFormatError]: 'ERRORS.INVALIDREQUESTFORMATERROR',
  [ErrorsReasonEnum.InvalidServiceParameterError]: 'ERRORS.INVALIDSERVICEPARAMETERERROR',
  [ErrorsReasonEnum.AuthenticationError]: 'ERRORS.AUTHENTICATIONERROR',
  [ErrorsReasonEnum.AuthorizationError]: 'ERRORS.AUTHORIZATIONERROR',
  [ErrorsReasonEnum.EntityNotFound]: 'ERRORS.ENTITYNOTFOUND',
  [ErrorsReasonEnum.EntityVersionConflit]: 'ERRORS.ENTITYVERSIONCONFLIT',
  [ErrorsReasonEnum.InvalidRelationship]: 'ERRORS.INVALIDRELATIONSHIP',
  [ErrorsReasonEnum.MetadataError]: 'ERRORS.METADATAERROR',
  [ErrorsReasonEnum.OperationError]: 'ERRORS.OPERATIONERROR',
  [ErrorsReasonEnum.ResponseIncompleteError]: 'ERRORS.RESPONSEINCOMPLETEERROR',
  [ErrorsReasonEnum.ServiceError]: 'ERRORS.SERVICEERROR',
  [ErrorsReasonEnum.CoreServiceError]: 'ERRORS.CORESERVICEERROR',
  [ErrorsReasonEnum.JobTaskError]: 'ERRORS.JOBTASKERROR',
  [ErrorsReasonEnum.BankingIntegrationError]: 'ERRORS.BANKINGINTEGRATIONERROR',
  [ErrorsReasonEnum.BankingTransactionError]: 'ERRORS.BANKINGTRANSACTIONERROR',
  [ErrorsReasonEnum.BankingUnknownTransactionStateAtProvider]: 'ERRORS.BANKINGUNKNOWNTRANSACTIONSTATEATPROVIDER',
  [ErrorsReasonEnum.BankingUnsupportedTransactionType]: 'ERRORS.BANKINGUNSUPPORTEDTRANSACTIONTYPE',
  [ErrorsReasonEnum.BankingAccountForBalanceNotFound]: 'ERRORS.BANKINGACCOUNTFORBALANCENOTFOUND',
  [ErrorsReasonEnum.BankingAccountTransactionAlreadyProcessed]: 'ERRORS.BANKINGACCOUNTTRANSACTIONALREADYPROCESSED',
  [ErrorsReasonEnum.BankingServiceParameterRequired]: 'ERRORS.BANKINGSERVICEPARAMETERREQUIRED',
  [ErrorsReasonEnum.BankingServiceConfigNotFound]: 'ERRORS.BANKINGSERVICECONFIGNOTFOUND',
  [ErrorsReasonEnum.BankingMissingConfig]: 'ERRORS.BANKINGMISSINGCONFIG',
  [ErrorsReasonEnum.BankingServiceInstanceNotFound]: 'ERRORS.BANKINGSERVICEINSTANCENOTFOUND',
  [ErrorsReasonEnum.BankingRoutingConfigAlreadyExists]: 'ERRORS.BANKINGROUTINGCONFIGALREADYEXISTS',
  [ErrorsReasonEnum.WebhookServiceError]: 'ERRORS.WEBHOOKSERVICEERROR',
  [ErrorsReasonEnum.PartnerUserShouldBeMemberOnly]: 'ERRORS.PARTNERUSERSHOULDBEMEMBERONLY',
  [ErrorsReasonEnum.NoConnection]: 'ERRORS.NO_CONNECTION',
  [ErrorsReasonEnum.AccessDenied]: 'ERRORS.ACCESS_DENIED',
  [ErrorsReasonEnum.BadRequestError]: 'ERRORS.BADREQUESTERROR',
  [ErrorsReasonEnum.CustomerAccountInsufficientFunds]: 'ERRORS.CUSTOMERACCOUNTINSUFFICIENTFUNDS',
  [ErrorsReasonEnum.TransactionTaxNumberNotAllowed]: 'ERRORS.TRANSACTIONTAXNUMBERNOTALLOWED',
  [ErrorsReasonEnum.TransferBetweenPartnersNotAllowed]: 'ERRORS.TRANSFERBETWEENPARTNERSNOTALLOWED',
  [ErrorsReasonEnum.TransferToSameAccountNotAllowed]: 'ERRORS.TRANSFERTOSAMEACCOUNTNOTALLOWED',
  [ErrorsReasonEnum.CounterpartyDeathNotice]: 'ERRORS.COUNTERPARTYDEATHNOTICE',
  [ErrorsReasonEnum.CounterpartyAgeUnder18]: 'ERRORS.COUNTERPARTYAGEUNDER18',
  [ErrorsReasonEnum.CounterpartyAgeAbove80]: 'ERRORS.COUNTERPARTYAGEABOVE80',
  [ErrorsReasonEnum.CounterpartyTaxNumberInvalid]: 'ERRORS.COUNTERPARTYTAXNUMBERINVALID',
  [ErrorsReasonEnum.AppAuthNotValid]: 'ERRORS.APPAUTHNOTVALID',
  [ErrorsReasonEnum.MFAAppAlreadyEnabled]: 'ERRORS.MFAAPPALREADYENABLED',
  [ErrorsReasonEnum.NotFoundError]: 'ERRORS.NOTFOUNDERROR',
  [ErrorsReasonEnum.TransactionPixKeyValidationFail]: 'ERRORS.TRANSACTIONPIXKEYVALIDATIONFAIL',
  [ErrorsReasonEnum.TransactionInvalidPixRecipient]: 'ERRORS.TRANSACTIONINVALIDPIXRECIPIENT',
  [ErrorsReasonEnum.TransactionRequestReceiverMustBeApproved]: 'ERRORS.TRANSACTIONREQUESTRECEIVERMUSTBEAPPROVED',
  [ErrorsReasonEnum.BillingPlanNotFound]: 'ERRORS.BILLINGPLANNOTFOUND',
  [ErrorsReasonEnum.QRCodeLogoSizeTooLarge]: 'ERROS.QRCODELOGOSIZETOOLARGE',
  [ErrorsReasonEnum.UnknownError] : 'ERRORS.UNKNOWN_ERROR_CAUSE',
  [ErrorsReasonEnum.PolicyRuleAlreadyExists]: 'ERRORS.POLICYRULEALREADYEXISTS',
  [ErrorsReasonEnum.NetworkRequestFail]: 'ERRORS.NETWORKREQUESTFAIL',
  [ErrorsReasonEnum.TransactionBankCashoutNotAllowed]: 'ERRORS.TRANSACTIONBANKCASHOUTNOTALLOWED',
  [ErrorsReasonEnum.AnalyticsHeatmapFeatureNotEnabled]: 'ERRORS.ANALYTICSHEATMAPFEATURENOTENABLED',
  [ErrorsReasonEnum.MFAAppNotEnabled]: 'ERRORS.MFAAPPNOTENABLED'
}
