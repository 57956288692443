import {Injectable} from '@angular/core';
import {MatPaginatorIntl} from "@angular/material/paginator";
import {TranslateService} from "@ngx-translate/core";
import {TranslationLoaderUtilsService} from "../../../services/translation-loader-utils.service";
import {Subscription} from "rxjs";

@Injectable()
export class MjxMatPaginatorIntl extends MatPaginatorIntl {
  public reloadSubs: Subscription;
  public translateSubs: Subscription
  constructor(
    private translate: TranslateService,
    private translationLoader: TranslationLoaderUtilsService
  ) {
    super();
    this.listenTranslationReload();
    this.getAsyncTranslations();
  }

  private customRangeLabel(label: string): any {
    return (page: number, pageSize: number, length: number): string => {
      length = Math.max(length, 0);
      const startIndex = page * pageSize;
      const endIndex = startIndex < length ? Math.min(startIndex + pageSize, length) : startIndex + pageSize;
      return `${(startIndex + 1)} - ${endIndex} ${label} ${length}`;
    }
  }

  private listenTranslationReload() {
    this.reloadSubs = this.translationLoader
      .onReloadChanges()
      .subscribe(() => {
        this.getAsyncTranslations(true);
      });
  }

  private getAsyncTranslations(notify = false) {
    this.translateSubs = this.translate.get([
      'PAGINATOR.ITENS_PER_PAGE',
      'PAGINATOR.FIRST_PAGE',
      'PAGINATOR.PREVIOUS_PAGE',
      'PAGINATOR.NEXT_PAGE',
      'PAGINATOR.LAST_PAGE',
      'PAGINATOR.RANGE_LABEL'
    ]).subscribe((translations) => {
      this.itemsPerPageLabel = translations['PAGINATOR.ITENS_PER_PAGE'];
      this.firstPageLabel = translations['PAGINATOR.FIRST_PAGE'];
      this.previousPageLabel = translations['PAGINATOR.PREVIOUS_PAGE'];
      this.nextPageLabel = translations['PAGINATOR.NEXT_PAGE'];
      this.lastPageLabel = translations['PAGINATOR.LAST_PAGE'];
      this.getRangeLabel = this.customRangeLabel(translations['PAGINATOR.RANGE_LABEL']);

      if (notify) {
        this.changes.next();
      }
    })
  }
}
