import { Pipe, PipeTransform } from '@angular/core';
import {TransactionTypeEnum} from "../../modules/transactions/enums/transaction-type.enum";

@Pipe({
  name: 'transactionTypeTranslateKey'
})
export class TranslateTransactionTypePipe implements PipeTransform {
  transform(status): string {
    let translateKey: string = status;

    switch (status) {
      case TransactionTypeEnum.Credit:
        translateKey = 'SHARED.ENUM.PAYMENT_TYPES.CREDIT'
        break;
      case TransactionTypeEnum.Debit:
        translateKey = 'SHARED.ENUM.PAYMENT_TYPES.DEBIT'
        break;
    }

    return translateKey;
  }
}
