export enum ErrorsReasonEnum {
  TimeoutError = 'TimeoutError',
  UserTypeNotAllowed = 'UserTypeNotAllowed',
  CredentialsNotFound = 'CredentialsNotFound',
  UserPasswordNotMatch = 'UserPasswordNotMatch',
  UserNotFound = 'UserNotFound',
  UserUniqueUserName = 'UserUniqueUserName',
  UserUniqueUserEmail = 'UserUniqueUserEmail',
  CustomerInvalid = 'CustomerInvalid',
  CustomerAccountNotActive = 'CustomerAccountNotActive',
  CustomerAccountRequired = 'CustomerAccountRequired',
  CustomerAccountInvalid = 'CustomerAccountInvalid',
  CustomerAccountIsNotActive = 'CustomerAccountIsNotActive',
  AuthenticationTokenInvalid = 'AuthenticationTokenInvalid',
  AccessKeyUnauthorized = 'AccessKeyUnauthorized',
  BillingPlanShouldExistsOnlyOneStandardPlan = 'BillingPlanShouldExistsOnlyOneStandardPlan',
  BillingPlanShouldExistsOnlyActivePlan = 'BillingPlanShouldExistsOnlyActivePlan',
  BillingPlanShouldExistsOnlyWaitingPlan = 'BillingPlanShouldExistsOnlyWaitingPlan',
  BillingPlanNoStandardPlanFound = 'BillingPlanNoStandardPlanFound',
  BillingPlanNoEditablePlanFound = 'BillingPlanNoEditablePlanFound',
  TransactionStateTransitionNotFoundForState = 'TransactionStateTransitionNotFoundForState',
  TransactionStateTransitionInvalidForState = 'TransactionStateTransitionInvalidForState',
  TransactionStateNotChanged = 'TransactionStateNotChanged',
  InvalidInput = 'InvalidInputError',
  InvalidRequestFormatError = 'InvalidRequestFormatError',
  InvalidServiceParameterError = 'InvalidServiceParameterError',
  AuthenticationError = 'AuthenticationError',
  AuthorizationError = 'AuthorizationError',
  EntityNotFound = 'EntityNotFound',
  EntityVersionConflit = 'EntityVersionConflit',
  InvalidRelationship = 'InvalidRelationship',
  MetadataError = 'MetadataError',
  OperationError = 'OperationError',
  ResponseIncompleteError = 'ResponseIncompleteError',
  ServiceError = 'ServiceError',
  CoreServiceError = 'CoreServiceError',
  JobTaskError = 'JobTaskError',
  BankingIntegrationError = 'BankingIntegrationError',
  BankingTransactionError = 'BankingTransactionError',
  BankingUnknownTransactionStateAtProvider = 'BankingUnknownTransactionStateAtProvider',
  BankingUnsupportedTransactionType = 'BankingUnsupportedTransactionType',
  BankingAccountForBalanceNotFound = 'BankingAccountForBalanceNotFound',
  BankingAccountTransactionAlreadyProcessed = 'BankingAccountTransactionAlreadyProcessed',
  BankingServiceParameterRequired = 'BankingServiceParameterRequired',
  BankingServiceConfigNotFound = 'BankingServiceConfigNotFound',
  BankingMissingConfig = 'BankingMissingConfig',
  BankingServiceInstanceNotFound = 'BankingServiceInstanceNotFound',
  BankingRoutingCNAEAlreadyExists = 'BankingRoutingCNAEAlreadyExists',
  BankingRoutingConfigAlreadyExists = 'BankingRoutingConfigAlreadyExists',
  WebhookServiceError = 'WebhookServiceError',
  PartnerUserShouldBeMemberOnly = 'PartnerUserShouldBeMemberOnly',
  NoConnection = 'NoConnection',
  AccessDenied = 'AccessDenied',
  BadRequestError = 'BadRequestError',
  CustomerAccountInsufficientFunds = 'CustomerAccountInsufficientFunds',
  TransferBetweenPartnersNotAllowed = 'TransferBetweenPartnersNotAllowed',
  TransferToSameAccountNotAllowed = 'TransferToSameAccountNotAllowed',
  CounterpartyDeathNotice = 'CounterpartyDeathNotice',
  CounterpartyAgeUnder18 = 'CounterpartyAgeUnder18',
  CounterpartyAgeAbove80 = 'CounterpartyAgeAbove80',
  CounterpartyTaxNumberInvalid = 'CounterpartyTaxNumberInvalid',
  AppAuthNotValid = 'AppAuthNotValid',
  MFAAppAlreadyEnabled = 'MFAAppAlreadyEnabled',
  NotFoundError = 'NotFoundError',
  TransactionPixKeyValidationFail = 'TransactionPixKeyValidationFail',
  TransactionRequestReceiverMustBeApproved = 'TransactionRequestReceiverMustBeApproved',
  TransactionTaxNumberNotAllowed = 'TransactionTaxNumberNotAllowed',
  BillingPlanNotFound = 'BillingPlanNotFound',
  QRCodeLogoSizeTooLarge = 'QRCodeLogoSizeTooLarge',
  UnknownError = 'UnknownError',
  PolicyRuleAlreadyExists = 'PolicyRuleAlreadyExists',
  NetworkRequestFail = 'NetworkRequestFail',
  TransactionInvalidPixRecipient = "TransactionInvalidPixRecipient",
  TransactionBankCashoutNotAllowed = "TransactionBankCashoutNotAllowed",
  AnalyticsHeatmapFeatureNotEnabled = "AnalyticsHeatmapFeatureNotEnabled",
  MFAAppNotEnabled = "MFAAppNotEnabled",
}
