import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  CanLoad,
  Route,
  Router,
  RouterStateSnapshot, UrlSegment,
  UrlTree
} from '@angular/router';
import { Observable } from 'rxjs';
import {SessionService} from "../services/session.service";
import {JWTokenService} from "../services/jwtoken.service";
import {Store} from "@ngxs/store";
import {SessionState} from "../state/session/session.state";
import {isPartner} from "../../shared/utils/get-context";
import {RoutesEnum} from "../../shared/enums/routes.enum";
import {User} from "../models/user.model";
import {Roles} from "../../shared/utils/roles-list";
import {defaultUsersRoutesMap} from "../../shared/utils/maps/default-users-routes.map";
import {AccessRolesEnum} from "../../shared/enums/access-roles.enum";

@Injectable({
  providedIn: 'root'
})
export class SessionGuard implements CanActivate, CanLoad {

  roles = Roles;
  constructor(
    private sessionService: SessionService,
    private jwtService: JWTokenService,
    private store: Store,
    private router: Router
  ) {

  }
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

    const enabled = this.checkSession(route);
    const hasPermission = this.checkPermission(route);

    return enabled && hasPermission;
  }

  checkPermission(route: ActivatedRouteSnapshot): boolean {
    const { roles } = route.data;

    if (roles?.length) {
      const userPermissions: string[] = this.store.selectSnapshot(SessionState.getCurrentUserPermissions);
      const resourcePermission = userPermissions.some((p) => roles.includes(p));

      if (!resourcePermission) {
        let currentPermission;

        if (userPermissions?.length > 0 && userPermissions[0] === AccessRolesEnum.Partner) {
          currentPermission = userPermissions[1]
        } else {
          currentPermission = userPermissions[0]
        }

        const defaultRoute = defaultUsersRoutesMap[currentPermission];
        this.router.navigate([`/${defaultRoute}`])
        return false;
      }
    }

    return true;
  }

  private checkSession(route: ActivatedRouteSnapshot): boolean {
    const token = this.store.selectSnapshot(SessionState.getToken);
    const hasToken = !!token;
    // const routeRoles: string[] = route.data?.['roles'];
    // const hasRouteRoles = !!routeRoles;
    // let canAccess = !isPartner;
    //
    // if (isPartner) {
    //   const user: User = this.jwtService.decodeToken(token);
    //   if (user.ar && hasRouteRoles) {
    //     for (let role of user.ar) {
    //       if (routeRoles.includes(role)) {
    //         canAccess = true;
    //         break;
    //       }
    //     }
    //   } else if (!hasRouteRoles) {
    //     canAccess = true;
    //   }
    // }
    //
    // const enable = hasToken && canAccess;
    //
    // if (!enable) {
    //   this.router.navigateByUrl(`/${RoutesEnum.Home}`)
    // }

    return hasToken;
  }

  canLoad(route: Route, segments: UrlSegment[]): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return true;
  }
}
