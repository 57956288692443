import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MjxNavigationTabsComponent } from './mjx-navigation-tabs.component';
import {TranslateModule} from "@ngx-translate/core";
import {MatTabsModule} from "@angular/material/tabs";
import {MatIconModule} from "@angular/material/icon";
import {SharedDirectivesModule} from "../../directives/shared-directives.module";

@NgModule({
  declarations: [
    MjxNavigationTabsComponent
  ],
  exports: [
    MjxNavigationTabsComponent
  ],
    imports: [
        CommonModule,

        TranslateModule,
        MatTabsModule,
        MatIconModule,
        TranslateModule,
        SharedDirectivesModule
    ]
})
export class MjxNavigationTabsModule { }
