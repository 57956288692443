import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {environment} from "../../../../environments/environment";
import {PartnerSite} from "../models/parrner-site.model";
import {routes} from "../../../../environments/vendor/routes/routes";
import {CommonResponse} from "../../../shared/models/response.model";

const API = environment.apiUrl;

@Injectable({
  providedIn: 'root'
})
export class PartnersSitesService {

  constructor(
    private http: HttpClient
  ) { }

  getSites(partnerId: number) {
    let url = `${API}/${routes?.customers}/${partnerId}/sites`;
    return this.http
      .get<CommonResponse<PartnerSite[]>>(url);
  }

  deleteSite(partnerId: number, siteId: string) {
    let url = `${API}/${routes?.customers}/${partnerId}/sites/${siteId}`;
    return this.http
      .delete<void>(url);
  }

  createSite(site: PartnerSite, partnerId: number) {
    let url = `${API}/${routes?.customers}/${partnerId}/sites`;
    return this.http
      .post<void>(url, site);
  }

  editSite(site: PartnerSite, siteId: string, partnerId: number) {
    let url = `${API}/${routes?.customers}/${partnerId}/sites/${siteId}`;
    return this.http
      .patch<void>(url, site);
  }
}
