import { Pipe, PipeTransform } from '@angular/core';
import {PixEnum} from "../enums/pix.enum";
import {verifyPixKey} from "../utils/verify-pix-key";

@Pipe({
  name: 'mjxInferPixKey'
})
export class InferPixKeyPipe implements PipeTransform {
  transform(pixKey: string): PixEnum {
    return verifyPixKey(pixKey);
  }
}
