import {Component, OnDestroy, OnInit} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup} from "@angular/forms";
import {Observable, race, Subscription } from "rxjs";
import {map} from "rxjs/operators";
import {Roles} from "../../../../../shared/utils/roles-list";

@Component({
  selector: 'mjx-detailed-profiles-permissions',
  templateUrl: './detailed-profiles-permissions.component.html',
  styleUrls: ['./detailed-profiles-permissions.component.scss']
})
export class DetailedProfilesPermissionsComponent implements OnInit, OnDestroy {
  permissionsForm: UntypedFormGroup;
  dashboardForm: UntypedFormGroup;
  usersForm: UntypedFormGroup;
  receiversForm: UntypedFormGroup;
  salesForm: UntypedFormGroup;
  paymentsForm: UntypedFormGroup;
  accessKeysForm: UntypedFormGroup;

  checkboxesListener: Subscription;

  Roles = Roles;

  constructor(
    private fb: UntypedFormBuilder
  ) { this.buildForm(); }

  ngOnInit(): void {
    this.listenCheckboxes();
  }

  ngOnDestroy() {
    if (this.checkboxesListener) {
      this.checkboxesListener.unsubscribe();
    }
  }

  save() {

  }

  allSelect(form: UntypedFormGroup, event: MouseEvent) {
    event.preventDefault();

    const values = Object.keys(form.value);
    const isSelected = form?.value?.all;
    const newValue = {};

    values.forEach(key => newValue[key] = !isSelected);

    form.patchValue(newValue);
  }

  private get checkboxForms(): Observable<any>[] {
    const checkboxes = [
      this.dashboardForm,
      this.usersForm,
      this.receiversForm,
      this.salesForm,
      this.paymentsForm,
      this.accessKeysForm
    ];

    return checkboxes.map(form =>
      form.valueChanges
        .pipe(
          map(value => form)
        )
    );
  }

  private listenCheckboxes() {
    this.checkboxesListener =  race(this.checkboxForms)
      .subscribe((res: UntypedFormGroup) => {

        if (res) {
          const isAllChecked = res.get('all').value;
          const values = Object.values(res.value);
          values.shift();

          if (isAllChecked && values.includes(false)) {
            res.get('all').setValue(false);
          } else if (!isAllChecked && !values.includes(false)) {
            res.get('all').setValue(true);
          }

          this.checkboxesListener.unsubscribe();
          this.listenCheckboxes();
        }
      })
  }

  private buildForm() {
    this.permissionsForm = this.fb.group({
      profileName: [null]
    });

    this.dashboardForm = this.fb.group({
      all: [false],
      visualize: [false]
    });

    this.usersForm = this.fb.group({
      all: [false],
      list: [false],
      create: [false],
      update: [false],
      remove: [false]
    });

    this.receiversForm = this.fb.group({
      all: [false],
      list: [false],
      create: [false],
      update: [false],
      remove: [false],
      approve: [false],
    });

    this.salesForm = this.fb.group({
      all: [false],
      list: [false],
      create: [false],
      update: [false],
      remove: [false],
      reports: [false],
    });

    this.paymentsForm = this.fb.group({
      all: [false],
      list: [false],
      create: [false],
      update: [false],
      remove: [false],
      reports: [false],
    });

    this.accessKeysForm = this.fb.group({
      all: [false],
      list: [false],
      create: [false],
      remove: [false],
      status: [false],
    });
  }
}
