import { Injectable } from '@angular/core';
import {ActivatedRoute, Event, NavigationEnd, Router} from "@angular/router";
import {filter, map} from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class ListenBreadcrumbService {

  listen(router: Router, activatedRoute: ActivatedRoute, breadcrumbs: string[]) {
    return router.events.pipe(
      filter((event: Event) => event instanceof NavigationEnd),
      map(() => activatedRoute),
      map(route => {
        let data = (route.snapshot.firstChild.data as any);

        if (route.snapshot.firstChild.firstChild) {
          data = (route.snapshot.firstChild.firstChild.data as any)
        }

        return data.breadcrumbs;
      })
    )
  }
}
