// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

import {ROUTE_CONTEXT} from "./vendor/routes/routes";

import currentTenant from "./tenant-config.json";

export const environment = {
  production: true,

  apiUrl: currentTenant.sandboxApiUrls.apiUrl,
  partnerHostUrl: currentTenant.sandboxApiUrls.partnerHostUrl,
  context: ROUTE_CONTEXT,
  applicationTitle: currentTenant.name,
  defaultLang: 'pt-br',
  applicationHostName: currentTenant.hostname,
  documentationUrl: currentTenant.documentationUrl,
  exportStorageUrl: currentTenant.localApiUrls.exportStorageUrl,

  version: 'c5102eea02aeeec706ec1f5541ff7621cec93477413bae42889d83baed58329f'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
