import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';

const routes: Routes = [
  {
    path: '', redirectTo: 'login', pathMatch: 'full'
  },
  {
    path: 'login', loadChildren: () => import('./modules/login/login.module').then(m => m.LoginModule)
  },
  {
    path: 'users',
    loadChildren: () => import('./modules/users/users.module').then(m => m.UsersModule),
  },
  {
    path: 'reset/:key',
    loadChildren: () => import('./modules/reset-password/reset-password.module').then(m => m.ResetPasswordModule),
    pathMatch: 'full',
  },
  {
    path: 'transactions',
    loadChildren: () => import('./modules/transactions/transactions.module').then(m => m.TransactionsModule),
  },
  {
    path: 'receivers',
    loadChildren: () => import('./modules/receivers/receivers.module').then(m => m.ReceiversModule),
  },
  {
    path: 'users',
    loadChildren: () => import('./modules/users/users.module').then(m => m.UsersModule),
  },
  {
    path: 'entries',
    loadChildren: () => import('./modules/entries/entries.module').then(m => m.EntriesModule),
  },
  {
    path: 'integration',
    loadChildren: () => import('./modules/integration/integration.module').then(m => m.SettingsModule),
  },
  {
    path: 'configuration',
    loadChildren: () => import('./modules/configuration/configuration.module').then(m => m.ConfigurationModule),
  },
  {
    path: 'queries',
    loadChildren: () => import('./modules/queries/queries.module').then(m => m.QueriesModule),
  },
  {
    path: 'home',
    loadChildren: () => import('./modules/home/home.module').then(m => m.HomeModule),
  },
  {
    path: 'heatmap',
    loadChildren: () => import('./modules/heatmap/heatmap.module').then(m => m.HeatmapModule),
  },
  {
    path: '*', redirectTo: 'home', pathMatch: 'full'
  },
  {
    path: '**', redirectTo: 'home', pathMatch: 'full'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
