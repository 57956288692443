import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'startsWith'
})
export class StartsWithPipe implements PipeTransform {

  transform(value: string, prefix: string): boolean {
    if (!value || !prefix) {
      return false;
    }

    return value.startsWith(prefix);
  }

}
