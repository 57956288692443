export class RegexUtils {
  static CPF = new RegExp(/^[0-9]{3}\.?[0-9]{3}\.?[0-9]{3}\-?[0-9]{2}$/);
  static CNPJ = new RegExp(/^[0-9]{2}\.?[0-9]{3}\.?[0-9]{3}\/?[0-9]{4}\-?[0-9]{2}$/);
  static CPF_CNPJ_NO_SPECIAL_CHARS = new RegExp(/\.|\-|\//g);
  static UUID = new RegExp(/[0-9,a-f,A-F]{8}-[0-9,a-f,A-F]{4}-[0-9,a-f,A-F]{4}-[0-9,a-f,A-F]{4}-[0-9,a-f,A-F]{12}/);

  // DICT API (1.9.1)
  // www.bcb.gov.br/content/estabilidadefinanceira/pix/API-DICT.html#tag/Key
  static PIX_CPF = new RegExp(/^[0-9]{11}$/);
  static PIX_CNPJ = new RegExp(/^[0-9]{14}$/);
  static PIX_PHONE = new RegExp(/^\+[1-9][0-9]\d{1,14}$/);

  /**
   * bacen provided regex: ^[a-z0-9.!#$&'*+\/=?^_`{}~-]+@a-z0-9?(?:\.a-z0-9?)*$
   *
   * for jasvascript regex: ^[a-z0-9.!#$&'*+\/=?^_`{}~-]+@[a-z0-9?]+(?:\.[a-z0-9?]+)*$
   **/
  static PIX_EMAIL = new RegExp(/^[a-z0-9.!#$&'*+\/=?^_`{}~-]+@[a-z0-9?]+(?:\.[a-z0-9?]+)*$/);
  static PIX_EVP = new RegExp(/[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}/);
}
