import { Injectable } from '@angular/core';
import {MatSnackBar} from "@angular/material/snack-bar";
import {TranslateService} from "@ngx-translate/core";

@Injectable({
  providedIn: 'root'
})
export class SnackbarService {

  constructor(
    private matSnackBar: MatSnackBar,
    private translate: TranslateService
  ) { }

  success(message: string, action?: string) {
    this.matSnackBar.open(this.translate.instant(message), action, {
      panelClass: ['yellow-snackbar', 'custom-snackbar'],
      horizontalPosition: 'right'
    });
  }

  error(message: string, action?: string) {
    this.matSnackBar.open(this.translate.instant(message), action, {
      panelClass: ['red-snackbar', 'custom-snackbar'],
      horizontalPosition: 'right'
    });
  }
}
