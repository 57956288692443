import { Pipe, PipeTransform } from '@angular/core';
import {AccountModel} from "../../../core/models/account.model";
import {isPartner} from "../../../shared/utils/get-context";

@Pipe({
  name: 'isLinkedAccount'
})
export class IsLinkedAccountPipe implements PipeTransform {

  transform(account: AccountModel, selectedAccounts: string[]): boolean {
    return selectedAccounts.includes(`${this.getAccountId(account)}`);
  }

  private getAccountId(account: AccountModel) {
    return (isPartner) ? account.uid : account.id;
  }
}
