<h4 *ngIf="allFiltersHidden">{{'FILTER_FIELDS_EMPTY' | translate}}</h4>
<form [formGroup]="formGroup" class="forms-container row custom-row">
  <ng-container
    *ngFor="let field of selectedFilters"
    [ngTemplateOutlet]="field.templateRef">
  </ng-container>

  <div class="actions-container col-auto">
    <button data-qa="filter-button"
      mat-button class="custom-button filter-btn" (click)="filter.emit()" [disabled]="formGroup.invalid || allFiltersHidden || disableRule">
      <mat-icon>{{searchButtonIcon}}</mat-icon>
      <span>{{searchButtonName | translate}}</span>
    </button>
    <button mat-icon-button [disabled]="false" class="custom-secondary-button icon-btn" (click)="clear.emit()">
      <mat-icon>close</mat-icon>
    </button>

    <button mat-icon-button class="custom-secondary-button icon-btn"
            *ngIf="showFilterActions"
            [ngClass]="{'activated-filter': activated}"
            [matTooltipHideDelay]="10"
            [matTooltipPosition]="'above'"
            [disabled]="false"
            matTooltip="{{ 'FILTER_FIELDS' | translate }}"
            [matMenuTriggerFor]="menu">
      <mat-icon>more_vert</mat-icon>
    </button>
  </div>
</form>

<mat-menu #menu="matMenu" classList="filter-menu">
  <ng-container *ngFor="let field of fieldsToDisplay; index as i">
    <div mat-menu-item *ngIf="!field?.hiddenFromActions"
         (click)="$event.stopPropagation()" [ngClass]="{blockMouse: field.disabled}">
      <mat-checkbox style="height: auto; width: auto"
                    [ngClass]="{blockMouse: field.disabled}"
                    [disabled]="field.disabled"
                    [(ngModel)]="field.show"
                    (change)="savefilterState()"
      >
          <span (mousedown)="$event.stopPropagation()" (click)="checkLabel($event)">
            {{ field.translateKey | translate }}
        </span>

      </mat-checkbox>
    </div>
  </ng-container>
</mat-menu>
