<div class="actions">
  <button mat-button class="custom-button" routerLink="new" >
    <span>{{ 'USERS.NEW_PROFILE_BTN' | translate }}</span>
  </button>
</div>

<ng-container *ngIf="(isLoading$ | async)" [ngTemplateOutlet]="loadingSpinner"></ng-container>
<ng-container *ngIf="!(isLoading$ | async) && (isEmpty$ | async)" [ngTemplateOutlet]="emptyMessage"></ng-container>
<mjx-table [hidden]="(isLoading$ | async) || (isEmpty$ | async)"
           [displayedColumns]="tableColumns"
           [columnsDefinitions]="tableColumnsDef"
           [dataSource]="(dataSource$ | async) | datasource"
           (tableEvents)="listenTableEvents($event)"
></mjx-table>

<ng-template #loadingSpinner>
  <div class="spinner-container">
    <mat-progress-spinner class="custom-spinner"
                          diameter="60"
                          mode="indeterminate"
    ></mat-progress-spinner>
  </div>
</ng-template>

<ng-template #emptyMessage>
  <mjx-empty-message icon="badge" emptyMessage="INTEGRATION.TABLE.EMPTY_PROFILES"></mjx-empty-message>
</ng-template>
