<div class="title-container" mat-dialog-title>
  <span>
  {{'TRANSACTIONS.SYNC_MODAL.TITLE' | translate}}
  </span>

  <button class="close-modal-btn" mat-icon-button mat-dialog-close cdkFocusInitial>
    <mat-icon>close</mat-icon>
  </button>
</div>

<mat-divider></mat-divider>

<div mat-dialog-content class="data-container content mt-6">
  <span class="padding-top-span" *ngIf="!batchSyncs && !cancelBatchSync && !allSyncsCompleted">{{'TRANSACTIONS.SYNC_MODAL.CONTENT' | translate:{count: syncData.length} }}</span>

  <mat-icon *ngIf="(!cancelBatchSync && !allSyncsCompleted) && batchSyncs" [class]="batchSyncs ? 'sync-icon rotating' : 'sync-icon'">sync</mat-icon>
  <mat-icon *ngIf="allSyncsCompleted" class="sync-icon">check</mat-icon>
  <mat-icon *ngIf="cancelBatchSync && (resolvedCount < syncData.length)" class="sync-icon error-icon">error</mat-icon>

  <span *ngIf="batchSyncs && !cancelBatchSync && !allSyncsCompleted">{{'TRANSACTIONS.SYNC_MODAL.NOTIFICATION' | translate:{transactionsCount: resolvedCount, count: syncData.length} }}</span>
  <span *ngIf="allSyncsCompleted || cancelBatchSync">
    {{'TRANSACTIONS.SYNC_MODAL.RESOLVED_NOTIFICATION' | translate:{transactionsCount: resolvedCount, count: syncData.length} }}
  </span>
</div>

<mat-divider></mat-divider>

<div mat-dialog-actions class="actions">
  <button *ngIf="!batchSyncs || cancelBatchSync || allSyncsCompleted" class="custom-secondary-button" mat-button [mat-dialog-close]="false" cdkFocusInitial>
    {{ 'TRANSACTIONS.SYNC_MODAL.CLOSE' | translate }}
  </button>
  <button *ngIf="batchSyncs && !allSyncsCompleted" class="custom-button cancel-button" mat-button (click)="cancelBatchSyncFunction()">
   {{'TRANSACTIONS.SYNC_MODAL.CANCEL' | translate}}
  </button>
  <button class="custom-button" mat-button [disabled]="batchSyncs || cancelBatchSync" (click)="confirmBatchSyncs()">
    {{'TRANSACTIONS.SYNC_MODAL.CONFIRM' | translate}}
  </button>
</div>

