import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import {Clipboard} from '@angular/cdk/clipboard';
import { Router } from '@angular/router';
import { RoutesEnum } from 'src/app/shared/enums/routes.enum';

@Component({
  selector: 'mjx-reset-password-dialog',
  templateUrl: './reset-password-dialog.component.html',
  styleUrls: ['./reset-password-dialog.component.scss']
})
export class ResetPasswordDialogComponent implements OnInit {
  copied: boolean = false;
  isNewUser: boolean = false;
  userDetails = undefined;

  constructor(
    private translateService: TranslateService,
    private clipboard: Clipboard,
    private dialogRef: MatDialogRef<ResetPasswordDialogComponent>,
    private router: Router,
    @Inject(MAT_DIALOG_DATA) public data
  ) {
    if (data.isNewUser) {
      this.userDetails = data.userDetails;
    }
   }

  ngOnInit(): void {
  }

  goEdit() {
    if (this.isNewUser) {
      this.router.navigateByUrl(`/${RoutesEnum.Users}/edit`, {
        state: this.userDetails
      });
    } else {
      this.dialogRef.close(this.userDetails);
    }
  }

  copy() {
    this.copied = this.clipboard.copy(this.data.link);
  }

  truncateLink(resetUrl) {
    return resetUrl.length < 50 ? resetUrl : `${resetUrl.substring(0, 50)}...`;
  }
}
