import {Component, Input, OnInit, ViewEncapsulation} from '@angular/core';

@Component({
  selector: 'mjx-input',
  templateUrl: './mjx-input.component.html',
  styleUrls: ['./mjx-input.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class MjxInputComponent implements OnInit {
  @Input() rounded = false;

  constructor() { }

  ngOnInit(): void {
  }

}
