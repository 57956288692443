<div class="mt-3 mb-3">
  <ng-container *ngIf="(isLoading$ | async) || isLoading" [ngTemplateOutlet]="loadingSpinner"></ng-container>
  <mjx-table [dataSource]="(linkedAccounts$ | async) | datasource"
             [displayedColumns]="tableColumns"
             [columnsDefinitions]="tableColumnsDef"
             [hiddenPagination]="true"
             (tableEvents)="listenTableEvents($event)"
             [hidden]="(isLoading$ | async) || isLoading"
             [showFooter]="showTableFooter">
    <mjx-custom-mat-column columnName="footer">
      <ng-template>
        <div class="row">
          <mjx-input class="col-xs-8 col-md-6 col-10 custom-select">
            <mat-select [value]="null" #accountSelect [placeholder]="'USERS.SELECT_ACCOUNT_PLACEHOLDER' | translate"
                        (selectionChange)="saveAccount($event.value)">
              <mat-option *ngIf="(loadingAccounts$ | async)" class="loading-option">
                <mat-spinner diameter="16" mode="indeterminate" class="loading-spinner"></mat-spinner>
              </mat-option>
              <mat-option [value]="null"
                *ngIf="!(loadingAccounts$ | async)
                && selectedAccounts.length >= (accounts$ | async)?.length">
                {{ noAccountsMsg | translate }}
              </mat-option>
              <ng-container *ngFor="let account of (accounts$ | async)">
                <mat-option [hidden]="account | isLinkedAccount: selectedAccounts"
                  [value]="account">
                  <span>{{ account?.accountNumber }}</span> <span *ngIf="account?.accountName">- {{ account?.accountName }}</span>
                </mat-option>
              </ng-container>
            </mat-select>
          </mjx-input>
        </div>
      </ng-template>
    </mjx-custom-mat-column>
  </mjx-table>
  <div class="add-to-table" (click)="addNewAccount()" *ngIf="!isNewActive && !this.allSelected">
    <span>{{ 'USERS.ADD_NEW_ACCOUNT' | translate }}</span>
  </div>
</div>

<ng-template #loadingSpinner>
  <div class="spinner-container">
    <mat-progress-spinner
      class="custom-spinner"
      diameter="60"
      mode="indeterminate"
    ></mat-progress-spinner>
  </div>
</ng-template>

  <ng-template #loadingSaveSpinner>
    <mat-spinner diameter="24" mode="indeterminate" class="custom-spinner"></mat-spinner>
  </ng-template>
