import {IConfig} from "ngx-mask";
import {StorageKeysEnum} from "../enums/storage-keys.enum";
import {Languages} from "../enums/languages.enum";

const enMaskConfig: Partial<IConfig> = {
  decimalMarker: '.',
  thousandSeparator: ','
}

const brMaskConfig: Partial<IConfig> = {
  decimalMarker: ',',
  thousandSeparator: '.'
}

const esMaskConfig: Partial<IConfig> = {
  decimalMarker: ',',
  thousandSeparator: '.'
}

export const maskConfigFactory: () => Partial<IConfig> = () => {
  const currentLang = localStorage.getItem(StorageKeysEnum.Language);
  const maskMap = { [Languages.US]: brMaskConfig, [Languages.BR]: enMaskConfig, [Languages.ES]: esMaskConfig };
  return maskMap[currentLang]
};

