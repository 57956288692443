export class SetLoggedUser {
  static readonly type = '[Session] Set Logged User';
  constructor(public token: string) { }
}

export class SetToken {
  static readonly type = '[Session] Set Token';
  constructor(public token: string) { }
}

export class ClearSession {
  static readonly type = '[Session] Clear Session';
}

export class GetCurrentUser {
  static readonly type = '[Session] Get Current User Data';
}

export class EnableMfa {
  static readonly type = '[Session] Enable Mfa';
  constructor(public enable: boolean, public username: string) { }
}

export class ChangeEnablingMfa {
  static readonly type = '[Session] Change Enabling Mfa';
  constructor(public state: boolean) { }
}


