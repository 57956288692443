import {MjxTableDataSource} from "../../../shared/modules/mjx-table/mjx-table-datasource";
import {CreateUserModel, UserModel} from "../models/user.model";

export interface UsersStateModel {
  isLoading: boolean;
  isEmpty: boolean;
  hasError: boolean;
  usersDatasource: MjxTableDataSource<UserModel>;
  userDetails: UserModel;
  fetchingKey: boolean;
  resetKey: string;

  isSaving: boolean;
  hasSavingError: boolean;
  currentUser: CreateUserModel;

  hasErrorList: boolean;
}

export const defaultUserState = {
  hasError: false,
  isEmpty: false,
  isLoading: false,
  usersDatasource: undefined,
  userDetails: undefined,
  currentUser: undefined,
  fetchingKey: false,
  resetKey: undefined,

  isSaving: false,
  hasSavingError: false,

  hasErrorList: false
}
