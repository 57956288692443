import {Directive, Input, TemplateRef} from '@angular/core';
import {UntypedFormControl} from "@angular/forms";

@Directive({
  selector: '[mjxFilterField]'
})
export class MjxFilterFieldDirective {
  @Input() fieldName: string;
  @Input() parentForm: UntypedFormControl;
  constructor(public templateRef: TemplateRef<any>) {}
}
