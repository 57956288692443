<div class="info-container" [ngStyle]="topDistance">
  <div class="info-message" [ngStyle]="setupStyle">
    <div class="info-section" *ngFor="let info of messages">
      <div class="icon">
        <mat-icon>{{ info.icon }}</mat-icon>
      </div>
      <div class="text">
        <div *ngFor="let message of info.messages">
            {{ message | translate }}
        </div>
        <ng-container *ngFor="let additional of info.additional; let i = index">
          <span class="additional">{{i+1}}. {{ additional | translate }}</span>
          <br>
        </ng-container>
      </div>
    </div>
    <div class="footer-section">
      <div class="icon">
        <mat-icon>help</mat-icon>
      </div>
      <div class="text">
        <span>{{ getFooterInfo() }}</span>
      </div>
    </div>
  </div>
</div>
