import { Pipe, PipeTransform } from '@angular/core';
import {countriesList} from "../utils/countries-list";
import {currentLangTranslateCountryProperty} from "../utils/language-utils";

@Pipe({
  name: 'translateCountry'
})
export class TranslateCountryPipe implements PipeTransform {

  transform(name: string, code: string): unknown {
    const country = countriesList.filter(country => country.sigla === code)[0];

    if (country) {
      const property = currentLangTranslateCountryProperty();
      return country[property];
    }

    return name;
  }
}
