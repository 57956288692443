<ng-container *ngIf="breadcrumbs && breadcrumbs.length">
  <div class="breadcrumb-container">
    <nav class="breadcrumbs">
      <a *ngFor="let bread of breadcrumbs; let i = index" class="style-bread">
        <ng-container *ngIf="i > 0"> > </ng-container>
        <span *ngIf="i === 0">{{ bread | translate }}</span>
        <ng-container *ngIf="i > 0">
          <span *ngIf="routedBreadcrumbs[i] === null">{{ bread | translate }}</span>
          <ng-container *ngIf="routedBreadcrumbs[i] !== null">
            <span *ngIf="routedBreadcrumbs[i] === currentRoute">{{ bread | translate }}</span>
            <span *ngIf="routedBreadcrumbs[i] !== currentRoute" class="action"
                  [routerLink]="routedBreadcrumbs[i]">
            {{ bread | translate }}
          </span>
          </ng-container>
        </ng-container>
      </a>
    </nav>
      <ng-content select="[breadActions]"></ng-content>
  </div>
</ng-container>
