import {Injectable, Injector, NgZone} from "@angular/core";
import {Action, NgxsOnInit, Selector, State, StateContext, Store} from "@ngxs/store";
import {
  Authenticate,
  AuthenticateWithMFA,
  CheckSession,
  ClearMFA,
  Logout,
  SetMFA, RefreshToken,
  VerifyTokenValidity
} from "./auth.actions";
import {Router} from "@angular/router";
import {LoginService} from "../../../modules/login/services/login.service";
import {catchError, finalize, throwError} from "rxjs";
import {map, tap} from "rxjs/operators";
import {SnackbarService} from "../../../shared/services/snackbar.service";
import {SessionService} from "../../services/session.service";
import {RoutesEnum} from "../../../shared/enums/routes.enum";
import {ClearSession, EnableMfa, GetCurrentUser, SetLoggedUser, SetToken} from "../session/session.actions";
import {SessionState} from "../session/session.state";
import {JWTokenService} from "../../services/jwtoken.service";
import {MFA, User} from "../../models/user.model";
import {isPartner} from "../../../shared/utils/get-context";
import {PartnersApiService} from "../../../shared/services/partners/partners-api.service";
import {CorporateApiService} from "../../../shared/services/corporate/corporate-api.service";
import {RefreshTokenService} from "../../services/refresh-token.service";

class AuthStateModel {
  isLoading: boolean;
  hasError: boolean;
  mfa: MFA[];
  user: User;
}

const defaultAuthSate: AuthStateModel = {
  isLoading: false,
  hasError: false,
  mfa: undefined,
  user: undefined
}

@State<AuthStateModel>({
  name: 'auth',
  defaults: defaultAuthSate
})
@Injectable()
export class AuthState implements NgxsOnInit {
  private apiService;

  constructor(
    private store: Store,
    private router: Router,
    private loginService: LoginService,
    private sessionService: SessionService,
    private ngZone: NgZone,
    private snackbar: SnackbarService,
    private jwtTokenService: JWTokenService,
    private injector: Injector,
    private refreshTokenService: RefreshTokenService
  ) {
    if (isPartner) {
      this.apiService = <PartnersApiService>this.injector.get(PartnersApiService);
    } else {
      this.apiService = <CorporateApiService>this.injector.get(CorporateApiService)
    }
  }

  ngxsOnInit(ctx?: StateContext<any>): any {
    ctx.patchState({isLoading: false, hasError: false });
  }

  @Selector()
  static isLoading(state: AuthStateModel): boolean {
    return state.isLoading;
  }

  @Selector()
  static hasError(state: AuthStateModel): boolean {
    return state.hasError;
  }

  @Selector()
  static getMFA(state: AuthStateModel) {
    return state.mfa;
  }

  @Selector()
  static getAuthenticatedUser(state: AuthStateModel) {
    return state.user;
  }

  @Selector()
  static getUserRoles(state: AuthStateModel) {
    return state.user?.ar;
  }

  @Action(Authenticate)
  login(
    stateCtx: StateContext<AuthStateModel>,
    action: Authenticate
  ) {
    stateCtx.patchState({ isLoading: true, hasError: false });
    return this.loginService.login(action.payload)
      .pipe(
        tap((token: string) => {
          const user: User = this.jwtTokenService.decodeToken(token);
          stateCtx.patchState({ user })

          if (isPartner) {
            this.partnerAuth(user, token, stateCtx);
          } else {
            this.corporateAuth(user, token, stateCtx, action.payload.login);
          }
        }),
        catchError((err) => {
          stateCtx.patchState({ hasError: true });
          return throwError(err);
        }),
        finalize(() => {
          stateCtx.patchState({ isLoading: false });
        })
      );
  }

  @Action(AuthenticateWithMFA)
  loginWithMFA(
    stateCtx: StateContext<AuthStateModel>,
    action: AuthenticateWithMFA
  ) {
    stateCtx.patchState({ isLoading: true, hasError: false });

    return this.apiService.mfaValidate(action.mfaCodes)
      .pipe(
        map((token: string) => {
          this.store.dispatch(new SetLoggedUser(token));
          this.store.dispatch(new GetCurrentUser());
        }),
        catchError((err) => {
          stateCtx.patchState({ hasError: true });
          return throwError(err);
        }),
        finalize(() => {
          stateCtx.patchState({ isLoading: false });
        })
      );
  }

  @Action(Logout)
  logout() {
    this.refreshTokenService.stopListen();
    this.store.dispatch(new ClearSession());
    this.store.reset({});
    this.ngZone.run(() => this.router.navigateByUrl(`/${RoutesEnum.Login}`));
  }

  @Action(CheckSession)
  checkSession() {
    const token = (this.store.selectSnapshot(SessionState.getToken));

    if (!!token) {
      this.ngZone.run(() => this.router.navigateByUrl(`/${RoutesEnum.Home}`));
    }
  }

  @Action(ClearMFA)
  clearMfa(stateCtx: StateContext<AuthStateModel>) {
    stateCtx.patchState({ mfa: null });
  }

  @Action(SetMFA)
  setMfa(
    stateCtx: StateContext<AuthStateModel>,
    actions: SetMFA
  ) {
    stateCtx.patchState({ mfa: [actions.mfa] });
  }

  @Action(VerifyTokenValidity)
  verifyTokenValidity(
    stateCtx: StateContext<any>
  ) {
    const token = (this.store.selectSnapshot(SessionState.getToken));
    const user = this.jwtTokenService.decodeToken(token);

    stateCtx.patchState({ user });

    if (!user) {
      stateCtx.dispatch(new Logout());

    } else {
      const expTime = new Date(user.exp * 1000);
      const now = new Date();
      const timeLeft = expTime.getTime() - now.getTime();

      if (timeLeft <= 0) {
        stateCtx.dispatch(new Logout())
      }
    }
  }

  private partnerAuth(user: User, token: string, stateCtx: StateContext<AuthStateModel>) {
    this.store.dispatch(new SetLoggedUser(token));

    if (user?.mf && user.mf.length) {
      stateCtx.patchState({ mfa: user.mf });
    } else {

      this.store.dispatch(new GetCurrentUser());
    }
  }

  @Action(RefreshToken)
  refreshToken(
    stateCtx: StateContext<AuthStateModel>,
    actions: RefreshToken
  ) {
    return this.sessionService.refreshToken(actions.token)
      .pipe(
        map((token: string) => {
          this.store.dispatch(new SetToken(token));
        }),
        catchError((err) => {
          stateCtx.patchState({ hasError: true });
          return throwError(err);
        }),
        finalize(() => {
          stateCtx.patchState({ isLoading: false });
        })
      );
  }

  private corporateAuth(user: User, token: string, stateCtx: StateContext<AuthStateModel>, username: string) {
    this.store.dispatch(new SetLoggedUser(token));

    if (user?.mf && user.mf.length) {

      user.mf.forEach((mfa) => {
        if (mfa?.app === false) {
          this.store.dispatch(new EnableMfa(true, username));
          return;
        }
      })

      stateCtx.patchState({ mfa: user.mf });
    } else {
      this.store.dispatch(new GetCurrentUser());
    }
  }

  private isLoginPage(): boolean {
    return window.location
      .pathname
      .includes(RoutesEnum.Login.toString());
  }
}
