import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {MatTabHeaderClickDirective} from "./mat-tab-header-click.directive";
import { HasPermissionDirective } from './has-permission.directive';

@NgModule({
  declarations: [
    MatTabHeaderClickDirective,
    HasPermissionDirective,
  ],
  exports: [
    MatTabHeaderClickDirective,
    HasPermissionDirective
  ],
  imports: [
    CommonModule
  ]
})
export class SharedDirectivesModule { }
