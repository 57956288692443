import { Injectable } from '@angular/core';
import {RequestDataFilterModel, RequestFilterModel} from "../models/request-filter.model";
import {HttpParams} from "@angular/common/http";

@Injectable({
  providedIn: 'root'
})
export class HttpUtilsService {

  createQueryParamByFilterObject(filter: RequestFilterModel): string {
    let queryParam = '';
    try {
      const filterStr = JSON.stringify(filter);
      queryParam = encodeURIComponent(filterStr)
    } finally {
    }
    return queryParam;

  }

  createQueryParamByObject(filter: any): string {
    let queryParam = '';


    if (filter.dataset) {
      queryParam += `dataset.limit=${filter.dataset.limit}`
      queryParam += `&dataset.offset=${filter.dataset.offset}&`
    }

    delete filter.dataset;

    const keys = Object.keys(filter);

    for (let i = 0; i < keys.length; i++) {
      if (filter[keys[i]]) {
        queryParam += `${keys[i]}=${encodeURIComponent(filter[keys[i]])}`;

        if (i < (keys.length - 1)) {
          queryParam += '&';
        }
      }
    }

    if (queryParam.substring(queryParam.length - 1) === '&') {
      queryParam = queryParam.substring(0, queryParam.length - 1);
    }

    return queryParam;
  }

  parseFilterParams(filterObj?: RequestDataFilterModel<any>): HttpParams {
    if (!filterObj) return null;
    let tempFilterObj = { };

    if (filterObj.dataset) {
      tempFilterObj = {
        'dataset.limit': filterObj.dataset.limit,
        'dataset.offset': filterObj.dataset.offset,
      }
    }


    if (filterObj.startDate && filterObj.endDate) {
      const dateObj = {
        startDate: filterObj.startDate,
        endDate: filterObj.endDate
      }

      tempFilterObj = { ...tempFilterObj, ...dateObj };
    }

    if (filterObj.where) {
      tempFilterObj = { ...tempFilterObj, ...filterObj.where }
    }

    return new HttpParams({ fromObject: tempFilterObj });
  }
}
