import {Component, EventEmitter, HostListener, Input, OnInit, Output} from '@angular/core';
import {QuickInfoMessage} from "./models/quick-info-message.model";
import {CompanyUtils} from "../../utils/company-utils";
import {TranslateService} from "@ngx-translate/core";

@Component({
  selector: 'mjx-quick-info',
  templateUrl: './quick-info.component.html',
  styleUrls: ['./quick-info.component.scss']
})
export class QuickInfoComponent implements OnInit {
  @Input() messages: QuickInfoMessage[];
  @Input() maxWidth = '512px';
  @Input() width = 'inherit';
  @Input() set position(pos: 'left' | 'right') {
    this.alignment = { [pos]: '0px'}
  }
  @Input() set topProximity(value: string) {
    if (value) {
      this.topDistance = { top: value };
    }
  }

  private mouseIn = false;
  private firstLoad = false;

  @Output('closeDialog') closeDialog = new EventEmitter<boolean>();

  @HostListener('mouseenter', ['$event']) onMouseEnter() {
    this.mouseIn = true;
  }

  @HostListener('mouseleave', ['$event']) onMouseLeave() {
    this.mouseIn = false;
  }

  @HostListener('window:click', ['$event']) onClick() {
    if (!this.mouseIn && !this.firstLoad) {
      this.closeDialog.emit(true);
    }

    this.firstLoad = false;
  }

  topDistance: any = { top: '4px' };
  alignment: any = { 'left': '0px' };

  constructor(private translate: TranslateService) {
  }

  ngOnInit() {
    this.firstLoad = true;
  }

  get setupStyle() {
    return {
      'width': this.width,
      'max-width': this.maxWidth,
      ...this.alignment
    }
  }

  getFooterInfo() {
    return this.translate.instant('INFO.FAST_SUPPORT', {COMPANY_NAME: CompanyUtils.companyName})
  }
}
