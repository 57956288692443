import { FormControl, UntypedFormControl, ValidatorFn} from '@angular/forms';

function lowercaseValidator() {
  return (control: UntypedFormControl) => {
    if (!control.value) {
      return null;
    }
    return /^(?=.*[a-z])/.test(control.value) ? null : { lowercase: true };
  };
}

function uppercaseValidator() {
  return (control: UntypedFormControl) => {
    if (!control.value) {
      return null;
    }
    return /^(?=.*[A-Z])/.test(control.value) ? null : { uppercase: true };
  };
}

function numberValidator() {
  return (control: UntypedFormControl) => {
    if (!control.value) {
      return null;
    }
    return /^(?=.*\d)/.test(control.value) ? null : { number: true };
  };
}

function specialCharacterValidator() {
  return (control: UntypedFormControl) => {
    if (!control.value) {
      return null;
    }
    return /[\u0021-\u002F\u003A-\u0040\u005B-\u0060\u007B-\u007E]+/.test(control.value) ? null : { specialCharacter: true };
  };
}

function usernamePattern() {
  return (control: UntypedFormControl) => {
    const value: string = control.value;
    if (!value) {
      return null;
    }
    return /^[0-9a-zA-Z]+$/.test(value.trim()) ? null : { usernamePattern: true };
  };
}

function emptySpaces() {
  return (control: UntypedFormControl) => {
    const value: string = control.value;
    if (!value) {
      return null;
    }
    return /^[^\s].*[^\s]$/.test(value.trim()) ? null : { emptySpaces: true };
  };
}

function whiteSpacesValidator() {
  return (control: UntypedFormControl) => {
    const value: string = control.value;

    if (!value) {
      return null;
    }

    if (value.trim().length === 0) {
      return { whiteSpaces: true };
    } else {
      return null;
    }
  };
}

function minLengthTrimValidator(minLength: number): ValidatorFn {
  return (control: FormControl): { [key: string]: any } | null => {
    const value: string = control.value;

    if (!value) {
      return null;
    }

    const trimmedValue = value.trim();
    const isValid = trimmedValue.length >= minLength;

    return isValid ? null : { minLengthTrim: true };
  };
}

function maxLengthTrimValidator(maxLength: number): ValidatorFn {
  return (control: FormControl): { [key: string]: any } | null => {
    const value: string = control.value;

    if (!value) {
      return null;
    }

    const trimmedValue = value.trim();
    const isValid = trimmedValue.length <= maxLength;

    return isValid ? null : { maxLengthTrim: true };
  };
}

function emailValidator(): ValidatorFn {
  return (control: FormControl) => {
    const value: string = control.value;
    if (!value) {
      return null;
    }
    return /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(value.trim()) ? null : {email: true};
  }
}

function maxNumberValidator(max: number): ValidatorFn {
  return (control: FormControl): { [key: string]: any } | null => {
    const value = control.value;
    if (value !== 0 && value > max) {

      return { maxNumber: { value } };
    }

    return null;
  };
}

export {
  lowercaseValidator,
  uppercaseValidator,
  numberValidator,
  specialCharacterValidator,
  usernamePattern,
  emptySpaces,
  whiteSpacesValidator,
  minLengthTrimValidator,
  maxLengthTrimValidator,
  emailValidator,
  maxNumberValidator
}
