import { Pipe, PipeTransform } from '@angular/core';
import { ApiKeyReviewEnum } from '../enums/api-key-review.enum';
import {ApiKeysStateEnum} from "../enums/api-keys-state.enum";

@Pipe({
  name: 'apiKeysStateTranslateKey'
})
export class TranslateApiKeysStatePipe implements PipeTransform {
  transform(status: string): string {
    let translateKey = status;

    switch (status) {
      case ApiKeysStateEnum.Revoked:
        translateKey = 'INTEGRATION.API_KEY_STATE.REVOKED'
        break;
      case ApiKeysStateEnum.Active:
        translateKey = 'INTEGRATION.API_KEY_STATE.ACTIVE'
        break;
      case ApiKeysStateEnum.Inactive:
        translateKey = 'INTEGRATION.API_KEY_STATE.INACTIVE'
        break;
      case ApiKeyReviewEnum.Approved:
        translateKey = 'INTEGRATION.API_KEY_STATE.APPROVED'
        break;
      case ApiKeyReviewEnum.Pending:
        translateKey = 'INTEGRATION.API_KEY_STATE.PENDING'
        break;
      case ApiKeyReviewEnum.Rejected:
        translateKey = 'INTEGRATION.API_KEY_STATE.REJECTED'
        break;
    }
    return translateKey;
  }
}
