import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EmptyMessageComponent } from './empty-message.component';
import {TranslateModule} from "@ngx-translate/core";
import {MatIconModule} from "@angular/material/icon";
import {MatButtonModule} from "@angular/material/button";



@NgModule({
  declarations: [
    EmptyMessageComponent
  ],
  exports: [
    EmptyMessageComponent
  ],
  imports: [
    CommonModule,
    TranslateModule.forChild({extend: true}),
    MatIconModule,
    MatButtonModule
  ]
})
export class EmptyMessageModule { }
