<ng-container *ngIf="!onlyIcon; else iconTemplate">
  <ng-container *ngIf="!type; else buttonTypesTemplate">
    <div class="item">
      <button
        mat-stroked-button
        [disabled]="disabled"
        *ngIf="selected; else notSelectedButtonTemplate" [class]="versionButton ? 'version-button mat-stroked-button cashpague-login-btn' : 'mat-stroked-button cashpague-login-btn'">
        <div class="btn-content">
          <mat-icon class="icon" *ngIf="icon">{{icon}}</mat-icon>
          <mat-icon [svgIcon]="customIcon" class="custom-icon" *ngIf="customIcon"></mat-icon>
          <span class="button-text">{{ name }}</span>

          <mat-icon class="go">chevron_right</mat-icon>
        </div>
      </button>
    </div>

    <ng-template #notSelectedButtonTemplate>
      <button mat-button [class]="versionButton ? 'color-button raised-button cashpague-login-btn' :'raised-button cashpague-login-btn'" [disabled]="disabled">
        <div class="btn-content">
          <mat-icon class="icon" *ngIf="icon">{{icon}}</mat-icon>
          <mat-icon [svgIcon]="customIcon" class="custom-icon" *ngIf="customIcon"></mat-icon>
          <span class="button-text">{{ name }}</span>

          <mat-icon class="go">chevron_right</mat-icon>
        </div>
      </button>
    </ng-template>
  </ng-container>
</ng-container>

<ng-template #buttonTypesTemplate>
  <div class="cashpague-login-btn">
    <button mat-flat-button [disabled]="disabled">
      <div class="btn-content">
        <mat-icon class="icon" *ngIf="icon">{{icon}}</mat-icon>
        <span class="button-text">{{ name }}</span>
      </div>
    </button>
  </div>

</ng-template>

<ng-template #iconTemplate>
  <button mat-icon-button [disabled]="disabled">
    <mat-icon>{{icon}}</mat-icon>
  </button>
</ng-template>
