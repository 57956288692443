export enum AccessRolesEnum {
  Partner = "Partner",
  PartnerAdmin = "PartnerAdmin",
  PartnerMember = "PartnerMember",
  PartnerMemberFin = "PartnerMemberFin",

  Corporate = 'Corporate',
  CorporateAdmin = 'CorporateAdmin',
  CorporateMember = 'CorporateMember',
  CorporateSupport = 'CorporateSupport',

  System = 'System'
}
