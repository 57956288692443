import { DataSource } from '@angular/cdk/collections';
import { MatPaginator } from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {merge, Observable, of as observableOf} from "rxjs";
import {map} from "rxjs/operators";

export function compare(a: string | number, b: string | number, isAsc: boolean): number {
  return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
}

export class MjxTableDataSource<T> extends DataSource<T> {
  total: number;
  data: T[];
  paginator: MatPaginator | undefined;
  sort: MatSort | undefined;

  constructor(data = [], total = 0, getSortedData = null) {
    super();
    this.data = data;
    this.total = total;

    if (getSortedData) {
      this.getSortedData = getSortedData;
    }
  }

  connect(): Observable<T[]> {
    if (this.paginator && this.sort) {
      return merge(observableOf(this.data), this.paginator.page, this.sort.sortChange)
        .pipe(
          map(() => {
            return this.getSortedData([...this.data ]);
          }));
    } else {
      throw Error('Please set the paginator and sort on the data source before connecting.');
    }
  }

  disconnect(): void {}

  restore(data: T[] = []) {
    this.data = data;
  }

  insert(data: T) {
    if (data instanceof Array) {
      this.insertMany(data);
    } else {
      this.insertOne(data);
    }
  }

  getSortedData(data: T[]): T[] {
    if (!this.sort || !this.sort.active || this.sort.direction === '') {
      return data;
    }

    return data.sort((a, b) => {
      const isAsc = this.sort?.direction === 'asc';
      const property = this.sort?.active;

      switch (property) {
        default:
          return compare(a[property], b[property], isAsc);
      }
    });
  }

  private insertMany(data: T[]) {
    this.data.push(...data);
  }

  private insertOne(data: T, index?: number) {
    if (index > -1) {
      this.data[index] = data;

    } else {
      this.data.push(data);
    }
    this.total += 1;
  }
}
