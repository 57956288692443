import {AccessRolesEnum} from "../../enums/access-roles.enum";
import {RoutesEnum} from "../../enums/routes.enum";

export const defaultUsersRoutesMap = {
  [AccessRolesEnum.CorporateAdmin]: RoutesEnum.Home.toString(),
  [AccessRolesEnum.CorporateMember]: RoutesEnum.Home.toString(),
  [AccessRolesEnum.CorporateSupport]: `${RoutesEnum.Transactions}/${RoutesEnum.Sales}`,
  [AccessRolesEnum.PartnerAdmin]: RoutesEnum.Home.toString(),
  [AccessRolesEnum.PartnerMember]: `${RoutesEnum.Transactions}/${RoutesEnum.Sales}`,
  [AccessRolesEnum.PartnerMemberFin]: RoutesEnum.Receivers,
  [AccessRolesEnum.Partner]: RoutesEnum.Home.toString(),
  [AccessRolesEnum.Corporate]: RoutesEnum.Home.toString(),
  [AccessRolesEnum.System]: RoutesEnum.Home.toString()
}
