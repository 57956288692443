<mjx-input *ngIf="(currentAccount$ | async); else noAccountAvailable" class="select-account-input">
  <mat-form-field>
    <mat-select [value]="(currentAccount$ | async | getAccountId)" [compareWith]="compareAccounts">
      <mat-option *ngFor="let account of (accounts$ | async)"
                  (click)="setCurrentAccount(account)"
                  [value]="account | getAccountId"
                  class="select-account-option">
        <div class="option-container">
          <span>
            {{ account.accountNumber }}</span>&nbsp;<span *ngIf="account.accountName">- {{account.accountName}}</span>

        </div>
      </mat-option>
    </mat-select>
  </mat-form-field>
</mjx-input>

<ng-template #noAccountAvailable>
  <mjx-input class="select-account-input">
    <mat-form-field [floatLabel]="'never'">
      <mat-select [value]="null" [placeholder]="'NO_AVAILABLE_ACCOUNT' | translate">
        <mat-option [value]="null" class="select-account-option">
          <div class="option-container">
            {{'NO_AVAILABLE_ACCOUNT' | translate}}
          </div>
        </mat-option>
      </mat-select>
    </mat-form-field>
  </mjx-input>
</ng-template>
