import {Inject, Injectable} from '@angular/core';
import {
  Resolve,
  RouterStateSnapshot,
  ActivatedRouteSnapshot
} from '@angular/router';
import {Observable} from 'rxjs';
import {TRANSLATE_MODULE_NAME} from "../tokens/translate-module-name.token";
import {TranslationLoaderUtilsService} from "./translation-loader-utils.service";

@Injectable({
  providedIn: 'any'
})
export class TranslationLoaderResolver implements Resolve<boolean> {
  constructor(@Inject(TRANSLATE_MODULE_NAME) public modules: string[], private translationUtils: TranslationLoaderUtilsService) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> {
    if (this.modules.length === 1) {
      return this.translationUtils.loadSingeModule(this.modules[0]);
    } else {
      return this.translationUtils.loadMultipleModules(this.modules);
    }
  }
}
