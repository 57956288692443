export enum PolicyTypes {
  TransactionBankNumberCheck = 'TransactionBankNumberCheck',
  TransactionPersonalLimit = 'TransactionPersonalLimit',
  TransactionCashOutTaxNumberCheck = 'TransactionCashOutTaxNumberCheck',
  TransactionCashInTaxNumberCheck = 'TransactionCashInTaxNumberCheck',
  PartnerTransactionCashOutTaxNumberCheck = 'PartnerTransactionCashOutTaxNumberCheck',
  PartnerTransactionCashInTaxNumberCheck = 'PartnerTransactionCashInTaxNumberCheck',
  PartnerTransactionCashInCashOutTax = 'PartnerTransactionCashInCashOutTax',
  TransactionCashInCashOutTax = 'TransactionCashInCashOutTax'
}
