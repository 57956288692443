import {ContextRoutesInterface} from "../../../app/shared/interfaces/context-routes.interface";

const ROUTE_CONTEXT = 'partners';

const routes: ContextRoutesInterface = {
  login: `${ROUTE_CONTEXT}/auth/login`,
  transactions: `${ROUTE_CONTEXT}/account/transactions`,
  documents: `${ROUTE_CONTEXT}/documents`,
  accounts: `${ROUTE_CONTEXT}/accounts`,
  receivers: `${ROUTE_CONTEXT}/receivers`,
  users: `${ROUTE_CONTEXT}/users`,
  locations: 'system/locations',
  apiKeys: `api-keys`,
  whoAmI: `${ROUTE_CONTEXT}/auth/whoAmI`,
  mfa: `${ROUTE_CONTEXT}/auth/mfa`,
  participants: `${ROUTE_CONTEXT}/participants`
}

export {
  ROUTE_CONTEXT,
  routes
};
