import {compare, MjxTableDataSource} from "../../../shared/modules/mjx-table/mjx-table-datasource";
import {UserModel} from "../models/user.model";

export class UsersTableDatasource extends MjxTableDataSource<UserModel> {
  constructor(data = [], total = 0) {
    super(data, total);
  }

  override getSortedData(data: UserModel[]): UserModel[] {
    if (!this.sort || !this.sort.active || this.sort.direction === '') {
      return data;
    }

    return data.sort((a, b) => {
      const isAsc = this.sort?.direction === 'asc';
      const property = this.sort?.active;

      switch (property) {
        case 'metaUpdatedAt':
          return compare(new Date(a.metaUpdatedAt).getTime(), new Date(b.metaUpdatedAt).getTime(), isAsc)
        case 'metaCreatedAt':
          return compare(new Date(a.metaCreatedAt).getTime(), new Date(b.metaCreatedAt).getTime(), isAsc)
        default:
          return compare(a[property], b[property], isAsc);
      }
    });
  }
}
