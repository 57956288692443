import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MjxSectionComponent } from './components/mjx-section/mjx-section.component';
import {TranslateModule} from "@ngx-translate/core";

@NgModule({
  declarations: [
    MjxSectionComponent
  ],
  exports: [
    MjxSectionComponent
  ],
  imports: [
    CommonModule,
    TranslateModule
  ]
})
export class MjxSectionsModule { }
