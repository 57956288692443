import {Injectable} from '@angular/core';
import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest, HttpStatusCode
} from '@angular/common/http';
import {catchError, Observable, takeUntil, throwError, timeout} from 'rxjs';
import {Store} from "@ngxs/store";
import {map} from "rxjs/operators";
import {SessionState} from "../state/session/session.state";
import {HttpCancelService} from "../services/http-cancel.service";
import {ThreatError, ThreatMultipleErrors} from "../../shared/states/errors/errors.actions";
import {ApiError, ApiErrorDetailedItem, ApiErrorReason, ApiErrorResponse} from "../models/api-error.model";
import {ErrorsReasonEnum} from "../../shared/enums/errors-reason.enum";
import {Logout, VerifyTokenValidity} from "../state/auth/auth.actions";
import {AuthState} from "../state/auth/auth.state";

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(
    private store: Store,
    private httpCancel: HttpCancelService
  ) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const token = this.store.selectSnapshot(SessionState.getToken);
    const DEFAULT_TIMEOUT = 27_000;
    let req;

    if (request.url.includes('storage.s3')) {
      req = request;
    } else {
      if (!request.url.includes('auth/refresh')) {
        this.setLastRequestTimestamp();
      }
      req = request.clone({
        headers: request.headers.set('Authorization', token ? `Bearer ${token}`: '')
      });
    }

    this.store.dispatch(new VerifyTokenValidity());

    return next.handle(req).pipe(
      takeUntil(this.httpCancel.onCancelPendingRequests()),
      timeout(DEFAULT_TIMEOUT),
      catchError((err: HttpErrorResponse) => {
        const OFFLINE = 0;
        let detailedError: ApiError;
        let errorResponse: ApiErrorResponse;

        if (err.name === ErrorsReasonEnum.TimeoutError.toString()) {
          this.store.dispatch(new ThreatError(ErrorsReasonEnum.TimeoutError))
          return throwError(err);
        }

        if (err.status === OFFLINE) {
          this.store.dispatch(new ThreatError(ErrorsReasonEnum.NoConnection));

        } else if (err.status === HttpStatusCode.Forbidden) {
          const user = this.store.selectSnapshot(AuthState.getAuthenticatedUser);

          if (user?.mf && user.mf.length) {
            this.store.dispatch(new Logout());
          }

          this.store.dispatch(new ThreatError(ErrorsReasonEnum.AccessDenied));

        } else if (err.status === HttpStatusCode.Unauthorized) {

          if (err.error) {
            errorResponse = this.getErrorReason(err.error);
            detailedError = errorResponse.error;

            this.threatErrorReason(detailedError);
          } else {
            this.store.dispatch(new ThreatError(ErrorsReasonEnum.AuthenticationTokenInvalid));
          }
        } else if (err.status === HttpStatusCode.BadRequest) {
          const error = err?.error?.error ?? err.error;
          this.store.dispatch(new ThreatError(error.message as any))

        } else {
          errorResponse = this.getErrorReason(err.error);
          detailedError = errorResponse.error;

          this.threatErrorReason(detailedError);
        }

        return throwError(err);
      }),
      map((event: HttpEvent<any>) => {
        return event;
      }),
    )
  }

  private getErrorReason(httpError: ApiErrorResponse): ApiErrorResponse {
    try {
      if (httpError.error) {
        return  httpError;
      } else {
        throw new Error();
      }

    } catch {
      return JSON.parse(httpError as any as string)
    }
  }

  private threatErrorReason(detailedError: ApiError) {
    if (detailedError.details && (detailedError.details as ApiErrorReason).reason) {
      this.store.dispatch(new ThreatError((detailedError.details as ApiErrorReason).reason));

    } else if (detailedError.details && (detailedError.details as ApiErrorDetailedItem[]).values()) {
      this.store.dispatch(new ThreatMultipleErrors(detailedError.details as ApiErrorDetailedItem[]));

    } else if (detailedError.name) {
      this.store.dispatch(new ThreatError(detailedError.name as ErrorsReasonEnum));

    } else {
      this.store.dispatch(new ThreatError());
    }
  }

  private setLastRequestTimestamp() {
    localStorage.setItem('lastRequest', new Date().getTime().toString());
  }
}
