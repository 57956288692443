import { Injectable } from '@angular/core';
import {Observable} from "rxjs";
import {CurrentUser, User} from "../models/user.model";
import {HttpClient} from "@angular/common/http";
import {routes} from "../../../environments/vendor/routes/routes";
import {environment} from "../../../environments/environment";
import {CommonResponse} from "../../shared/models/response.model";
import {SessionState} from "../state/session/session.state";
import {Store} from "@ngxs/store";
import {JWTokenService} from "./jwtoken.service";
import {AccessRolesEnum} from "../../shared/enums/access-roles.enum";

const API = environment.apiUrl;

@Injectable({
  providedIn: 'root'
})
export class SessionService {

  constructor(
    private http: HttpClient,
    private store: Store,
    private jwtService: JWTokenService
  ) { }

  currentUseRoles(): string[] {
    const token = this.store.selectSnapshot(SessionState.getToken);
    const user: User = this.jwtService.decodeToken(token);
    return user.ar;
  }

  userHasRole(role: AccessRolesEnum): boolean {
    const userRoles = this.currentUseRoles();
    return userRoles?.includes(role);
  }

  getCurrentUser(): Observable<CommonResponse<CurrentUser>> {
    return this.http.get<CommonResponse<CurrentUser>>(`${API}/${routes.whoAmI}`);
  }

  getUser(): User {
    const token = this.store.selectSnapshot(SessionState.getToken);
    return this.jwtService.decodeToken(token);
  }

  refreshToken(token: string): Observable<string> {
    return this.http.post(`${API}/${routes.auth}/refresh`, token, {responseType: 'text'});
  }
}
