import {Directive, Input, OnInit, TemplateRef, ViewContainerRef} from '@angular/core';
import {Store} from '@ngxs/store';
import {SessionState} from 'src/app/core/state/session/session.state';
import {HasPermissionParameter} from '../models/has-permission-parameter.model';

@Directive({
  selector: '[hasPermission]'
})
export class HasPermissionDirective {
  @Input() set hasPermission(parameter: HasPermissionParameter) {
    if (parameter.ignore) {
      this.showComponent();
      return;
    }

    if (!parameter.hide && this.externalCondition(parameter)
      && this.permissions && this.permissions.some((p) => parameter.roles.includes(p))) {
      this.showComponent();
      return;
    }

    // *hasPermission="{resource: resourceEnum.Permissions, require: permissionsEnum.Edit, hide: condition}"
    // *hasPermission="{roles: []}"

    this.removeComponent();
  }

  permissions: any;

  constructor(
    private store: Store,
    private templateRef: TemplateRef<any>,
    private viewContainerRef: ViewContainerRef,
  ) {
    this.extractEntityPermissions();
  }

  removeComponent(): void {
    this.viewContainerRef.clear();
  }

  showComponent(): void {
    this.viewContainerRef.clear();
    this.viewContainerRef.createEmbeddedView(
      this.templateRef
    ).rootNodes[0];
  }

  externalCondition(parameter: HasPermissionParameter) {
    return (parameter.externalConditionToShow && parameter.externalConditionToShow === true) || !parameter.externalConditionToShow;
  }

  private extractEntityPermissions() {
    this.permissions = this.store.selectSnapshot(SessionState.getCurrentUserPermissions);
  }
}
