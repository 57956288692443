import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MjxSelectComponent } from './components/mjx-select/mjx-select.component';
import {MjxInputModule} from "../mjx-input/mjx-input.module";
import {ReactiveFormsModule} from "@angular/forms";
import {TranslateModule} from "@ngx-translate/core";
import {MaterialElementsModule} from "../material-elements/material-elements.module";
import { AutoClearSelectDirective } from './directives/auto-clear-select.directive';



@NgModule({
  declarations: [
    MjxSelectComponent,
    AutoClearSelectDirective
  ],
  exports: [
    MjxSelectComponent
  ],
  imports: [
    CommonModule,
    MaterialElementsModule,
    MjxInputModule,
    ReactiveFormsModule,
    TranslateModule.forChild({extend: true})
  ]
})
export class MjxSelectModule { }
