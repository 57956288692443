<div class="title-container" mat-dialog-title>
  <span>
  {{'TRANSACTIONS.INVOKE_MODAL.TITLE' | translate}}
  </span>

  <button class="close-modal-btn" mat-icon-button mat-dialog-close cdkFocusInitial (click)="closeBatchNotifications()">
    <mat-icon>close</mat-icon>
  </button>
</div>

<mat-divider></mat-divider>

<div mat-dialog-content class="data-container content">
  <span [class]="(this.details.data.length > invokeData.length) ? 'padding-top': ''" *ngIf="(this.details.data.length > invokeData.length) && !batchNotification && !cancelBatchNotification && !allInvocationsCompleted">
    {{'TRANSACTIONS.INVOKE_MODAL.INVOKES_SELECTED' | translate:{selected: details.data.length, count: invokeData.length} }}
  </span>
  <span [class]="(this.details.data.length === invokeData.length) ? 'padding-top-span': ''" *ngIf="!batchNotification && !cancelBatchNotification && !allInvocationsCompleted">{{'TRANSACTIONS.INVOKE_MODAL.CONTENT' | translate:{count: invokeData.length} }}</span>

  <mat-icon *ngIf="(!cancelBatchNotification && !allInvocationsCompleted) && batchNotification" [class]="batchNotification ? 'sync-icon rotating' : 'sync-icon'">sync</mat-icon>
  <mat-icon *ngIf="allInvocationsCompleted" class="sync-icon">check</mat-icon>
  <mat-icon *ngIf="cancelBatchNotification && (resolvedCount < invokeData.length)" class="sync-icon error-icon">error</mat-icon>

  <span *ngIf="batchNotification && !cancelBatchNotification && !allInvocationsCompleted">
    {{'TRANSACTIONS.INVOKE_MODAL.NOTIFICATION' | translate:{transactionsCount: resolvedCount, count: invokeData.length} }}
  </span>
  <span *ngIf="allInvocationsCompleted || cancelBatchNotification">
    {{'TRANSACTIONS.INVOKE_MODAL.RESOLVED_NOTIFICATION' | translate:{transactionsCount: resolvedCount, count: invokeData.length} }}
  </span>
</div>

<mat-divider></mat-divider>

<div mat-dialog-actions class="actions">
  <button
    *ngIf="!batchNotification || cancelBatchNotification || allInvocationsCompleted"
    class="custom-secondary-button"
    mat-button
    [mat-dialog-close]="false"
    cdkFocusInitial
    (click)="closeBatchNotifications()"
  >
    {{ 'TRANSACTIONS.INVOKE_MODAL.CLOSE' | translate }}
  </button>
  <button *ngIf="batchNotification && !allInvocationsCompleted" class="custom-button cancel-button" mat-button (click)="cancelBatchNotificationFunction()">
   {{'TRANSACTIONS.INVOKE_MODAL.CANCEL' | translate}}
  </button>
  <button class="custom-button" mat-button [disabled]="batchNotification || cancelBatchNotification" (click)="confirmBatchNotification()">
    {{'TRANSACTIONS.INVOKE_MODAL.CONFIRM' | translate}}
  </button>
</div>

