import { Injectable } from '@angular/core';
import {StorageKeysEnum} from "../../shared/enums/storage-keys.enum";
import {Logout, RefreshToken} from "../state/auth/auth.actions";
import {Store} from "@ngxs/store";
import {SessionState} from "../state/session/session.state";

@Injectable({
  providedIn: 'root'
})
export class RefreshTokenService {
  checkInterval: any;

  constructor(private store: Store) {
  }

  startListen() {
    const token = this.store.selectSnapshot(SessionState.getToken);
    this.checkLastRequest(token);
  }

  stopListen() {
    clearInterval(this.checkInterval);
  }

  private checkLastRequest(token: string) {
    const MAX_DIFF_IN_SECONDS = 3600;
    const RELOAD_PERIOD_IN_SECONDS = 30;

    clearInterval(this.checkInterval);
    this.checkInterval = setInterval(() => {
      const lastRequest = localStorage.getItem(StorageKeysEnum.LastRequest);


      if (lastRequest) {
        const lastRequestTimestamp = new Number(lastRequest).valueOf();
        const now = new Date();
        const diff = (now.getTime() - lastRequestTimestamp) / 1000;

        if (diff > MAX_DIFF_IN_SECONDS) {
          this.store.dispatch(new Logout());
          clearInterval(this.checkInterval);
        } else {
          this.store.dispatch(new RefreshToken(token));
        }
      }
    }, RELOAD_PERIOD_IN_SECONDS * 1000);
  }
}
