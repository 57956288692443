import {Injectable, NgZone} from "@angular/core";
import {Action, NgxsOnInit, Selector, State, StateContext, Store} from "@ngxs/store";
import {defaultPartnersStateModel, PartnersStateModel} from "./partners-state.model";
import {
  CreatePartner,
  CreatePartnerBilligPlan,
  EditPartner,
  EditPartnerBillingPlan,
  GetPartnerBillingPlans,
  GetPartnerDetails,
  ClearPartnersState,
  CreatePartnerAccount,
  CreatePartnerSite,
  DeletePartner,
  EditPartnerAccountState,
  DeletePartnerSite,
  EditPartnerAccountName,
  GetPartnerAccounts,
  GetPartners,
  GetPartnerSites,
  GetPartnersList,
  EditActivePartnerBillingPlan,
  ArchivePartnerBillingPlan,
  ClearPartnersAccounts,
  ClearPartnerBillingPlansState,
  GetAccountSelectedBanks,
  SetAccountOperationBanks,
  EditPartnerSite,
  SetAccountSecurityKey, GetAccountSecurityKey, PartnerGetAccountSecurityKey, PartnerSetAccountSecurityKey
} from "./partners.actions";
import {catchError, finalize, tap, throwError} from "rxjs";
import {CommonResponse} from "../../../shared/models/response.model";
import {PaginationModel} from "../../../shared/models/pagination.model";
import {PartnerModel} from "../models/partner.model";
import {SnackbarService} from "../../../shared/services/snackbar.service";
import {Router} from "@angular/router";
import {RoutesEnum} from "../../../shared/enums/routes.enum";
import {PartnerFormModel} from "../models/partner-form.model";
import {PartnersSitesService} from "../services/partners-sites.service";
import {MjxTableDataSource} from "../../../shared/modules/mjx-table/mjx-table-datasource";
import {PartnerSite} from "../models/parrner-site.model";
import { PartnersAccountsService } from '../services/partners-accounts.service';
import { PartnerAccount } from '../models/partner-account.model';
import { PartnersBillingPlansService } from '../services/partners-billing-plans.service';
import { PartnerBillingPlan } from '../models/partner-billing-plan.model';
import { ScheduleStatusEnum } from '../enums/schedule-status.enum';
import {CorporateApiService} from "../../../shared/services/corporate/corporate-api.service";
import {AccountUserPreferencesObject} from "../models/account-user-preferences-object.model";
import {PartnersService} from "../services/partners.service";
import {PartnersApiService} from "../../../shared/services/partners/partners-api.service";
import {SessionState} from "../../../core/state/session/session.state";
import {AccessRolesEnum} from "../../../shared/enums/access-roles.enum";
import {orderPartnersAccountsByImplicitID} from "../../../shared/utils/order-partner-accounts";

@State<PartnersStateModel>({
  name: 'partners',
  defaults: defaultPartnersStateModel
})
@Injectable()
export class PartnersState implements NgxsOnInit {

  constructor(
    private partnersService: PartnersService,
    private partnerApiService: PartnersApiService,
    private corporateService: CorporateApiService,
    private partnersSitesService: PartnersSitesService,
    private partnersBillingPlansService: PartnersBillingPlansService,
    private partAccService: PartnersAccountsService,
    private snackbar: SnackbarService,
    private store: Store,
    private router: Router,
    private ngZone: NgZone,
  ) { }

  @Selector()
  static hasErrorList(state: Partial<PartnersStateModel>) {
    return state.hasErrorList;
  }

  @Selector()
  static getErrorState(state: Partial<PartnersStateModel>) {
    return state.hasError;
  }

  @Selector()
  static getLoadingState(state: Partial<PartnersStateModel>) {
    return state.isLoading;
  }

  @Selector()
  static getEmptyState(state: Partial<PartnersStateModel>) {
    return state.isEmpty;
  }

  @Selector()
  static getDatasourceState(state: Partial<PartnersStateModel>) {
    return state.partnersDatasource;
  }

  @Selector()
  static getSavingErrorState(state: Partial<PartnersStateModel>) {
    return state.hasSavingError;
  }

  @Selector()
  static getSavingState(state: Partial<PartnersStateModel>) {
    return state.isSaving;
  }

  @Selector()
  static getPartnersList(state: Partial<PartnersStateModel>) {
    return state?.partners;
  }

  @Selector()
  static getPartnersListLoading(state: Partial<PartnersStateModel>) {
    return state?.loadingList
  }

  @Selector()
  static getPartnerDetailsLoading(state: Partial<PartnersStateModel>) {
    return state?.isLoadingDetails;
  }

  @Selector()
  static getPartnerDetailed(state: Partial<PartnersStateModel>) {
    return state?.detailedPartner;
  }

  @Selector()
  static getPartnerSitesDatasource(state: Partial<PartnersStateModel>) {
    return state?.sites;
  }

  @Selector()
  static getPartnerSitesEmptyState(state: Partial<PartnersStateModel>) {
    return state?.isEmptySites;
  }

  @Selector()
  static getPartnerAccountsLoading(state: Partial<PartnersStateModel>) {
    return state?.isLoadingAccounts;
  }

  @Selector()
  static getPartnerAccountsDataSource(state: Partial<PartnersStateModel>) {
    return state?.accountsDataSource;
  }

  @Selector()
  static getPartnerAccountsEmptyState(state: Partial<PartnersStateModel>) {
    return state?.isEmptyAccounts;
  }

  @Selector()
  static getPartnerSitesLoading(state: Partial<PartnersStateModel>) {
    return state?.isLoadingSites;
  }

  @Selector()
  static getBillingPlansEmptyState(state: Partial<PartnersStateModel>) {
    return state?.isEmptyBillingPlans;
  }

  @Selector()
  static getBillingPlansIsLoading(state: Partial<PartnersStateModel>) {
    return state?.isLoadingBillingPlans;
  }

  @Selector()
  static getBillingPlansDataSource(state: Partial<PartnersStateModel>) {
    return state?.billigPlans;
  }

  @Selector()
  static getCanCreateBillingPlans(state: Partial<PartnersStateModel>) {
    return state?.canCreateBillingPlans;
  }

  @Selector()
  static getPartnerAccounts(state: Partial<PartnersStateModel>) {
    return state?.accounts;
  }

  @Selector()
  static cashInBanks(state: Partial<PartnersStateModel>) {
    return state?.cashInBanks;
  }

  @Selector()
  static cashOutBanks(state: Partial<PartnersStateModel>) {
    return state?.cashOutBanks;
  }

  @Selector()
  static loadingBanks(state: Partial<PartnersStateModel>) {
    return state?.isLoadingBanks;
  }

  @Selector()
  static savingBanks(state: Partial<PartnersStateModel>) {
    return state?.isSavingBanks;
  }

  @Selector()
  static loadingPreferences(state: Partial<PartnersStateModel>) {
    return state?.isLoadingPreferences;
  }

  @Selector()
  static savingPreferences(state: Partial<PartnersStateModel>) {
    return state?.isSavingPreferences;
  }

  @Selector()
  static preferenceKey(state: Partial<PartnersStateModel>) {
    return state?.preferencesKey;
  }

  ngxsOnInit(ctx?: StateContext<PartnersStateModel>): any {
    ctx.setState(defaultPartnersStateModel)
  }

  @Action(ClearPartnersState)
  clearState(stateCtx: StateContext<PartnersStateModel>) {
    stateCtx.setState(defaultPartnersStateModel);
  }

  @Action(ClearPartnersAccounts)
  clearAccounts(stateCtx: StateContext<PartnersStateModel>) {
    stateCtx.patchState({
      accounts: []
    });
  }

  @Action(GetPartnerDetails)
  getPartnerDetails(stateCtx: StateContext<PartnersStateModel>, actions: GetPartnerDetails) {
    stateCtx.patchState({
      isLoadingDetails: true
    });

    return this.partnersService
      .getPartnerDetails(actions.id)
      .pipe(
        tap((res: CommonResponse<PartnerFormModel>) => {
          const detailedPartner = res.data;

          stateCtx.patchState({
            detailedPartner
          })
        }),
        catchError((err) => {
          this.snackbar.error('PARTNERS.FAIL_MSG.DETAILS');
          this.router.navigateByUrl(`${RoutesEnum.Partners}`);
          return throwError(err);
        }),
        finalize(() => {
          stateCtx.patchState({
            isLoadingDetails: false
          })
        })
      );
  }

  @Action(GetPartnersList)
  getPartnersList(
    stateCtx: StateContext<PartnersStateModel>
  ) {
    stateCtx.patchState({
      loadingList: true
    });

    return this.partnersService
      .getPartners(null)
      .pipe(
        tap((res: CommonResponse<PaginationModel<PartnerModel>>) => {
          const partners = res.data.items.sort((a, b) => {
              const nameA = a.companyName.toUpperCase(); // ignore upper and lowercase
              const nameB = b.companyName.toUpperCase(); // ignore upper and lowercase
              if (nameA < nameB) {
                return -1;
              }
              if (nameA > nameB) {
                return 1;
              }

              return 0
          });

          stateCtx.patchState({
            partners
          })
        }),
        catchError((err) => {
          this.snackbar.error('SHARED.FAIL_MSG.PARTNERS.LIST');
          stateCtx.patchState({
            hasError: true
          })
          return throwError(err);
        }),
        finalize(() => {
          stateCtx.patchState({
            loadingList: false
          })
        })
      );
  }

  @Action(GetPartners)
  getPartners(
    stateCtx: StateContext<PartnersStateModel>,
    actions: GetPartners
  ) {
    stateCtx.patchState({
      isLoading: true,
      hasErrorList: false,
      isEmpty: false
    })

    return this.partnersService
      .getPartners(actions.filter)
      .pipe(
        catchError((err) => {
          this.snackbar.error('SHARED.FAIL_MSG.PARTNERS.LIST')
          stateCtx.patchState({
            hasErrorList: true
          })
          return throwError(err);
        }),
        tap((res: CommonResponse<PaginationModel<PartnerModel>>) => {
          const partners = res.data.items;
          const dataSource = new MjxTableDataSource<PartnerModel>(partners, res.data.total);

          stateCtx.patchState({
            partnersDatasource: dataSource,
            billigPlans: undefined,
            isEmpty: (dataSource.total === 0)
          });

          this.store.dispatch(new ClearPartnerBillingPlansState());
        }),
        finalize(() => {
          stateCtx.patchState({
            isLoading: false
          })
        })
      );
  }

  @Action(DeletePartner)
  deletePartner(
    stateCtx: StateContext<PartnersStateModel>,
    actions: DeletePartner
  ) {
    stateCtx.patchState({
      isLoading: true,
      hasError: false,
      isEmpty: false
    });

    return this.partnersService
      .deletePartner(actions.id)
      .pipe(
        catchError((err) => {
          this.snackbar.error('PARTNERS.FAIL_MSG.DELETE');
          stateCtx.patchState({
            hasError: true
          })
          return throwError(err);
        }),
        tap(() => {
          this.snackbar.success('PARTNERS.SUCCESS_MSG.DELETE');

          this.store.dispatch(new GetPartners(actions.filter));
        })
      );
  }

  @Action(CreatePartner)
  createPartner(
    stateCtx: StateContext<PartnersStateModel>,
    actions: CreatePartner
  ) {
    stateCtx.patchState({
      isSaving: true,
      hasError: false
    });

    return this.partnersService
      .createPartner(actions.partner)
      .pipe(
        catchError((err) => {
          this.snackbar.error('PARTNERS.FAIL_MSG.NEW');
          stateCtx.patchState({
            hasSavingError: true
          })
          return throwError(err);
        }),
        tap((res) => {
          this.snackbar.success('PARTNERS.SUCCESS_MSG.NEW');
          this.navigateBack({...res.data, refLicense: actions.partner?.refLicense, customerType: actions.partner.customerType});
        }),
        finalize(() => {
          stateCtx.patchState({
            isSaving: false
          });
        })
      )
  }

  @Action(EditPartner)
  editPartner(
    stateCtx: StateContext<PartnersStateModel>,
    actions: EditPartner
  ) {
    stateCtx.patchState({
      isSaving: true,
      hasError: false
    });

    return this.partnersService
      .editPartner(actions.partner, actions.id)
      .pipe(
        catchError((err) => {
          this.snackbar.error('PARTNERS.FAIL_MSG.EDIT');
          stateCtx.patchState({
            hasSavingError: true
          })
          return throwError(err);
        }),
        tap(() => {
          this.snackbar.success('PARTNERS.SUCCESS_MSG.EDIT');
          this.navigateToList();
        }),
        finalize(() => {
          stateCtx.patchState({
            isSaving: false
          });
        })
      )
  }

  @Action(CreatePartnerSite)
  createPartnerSite(
    stateCtx: StateContext<PartnersStateModel>,
    actions: CreatePartnerSite
  ) {
    stateCtx.patchState({
      isLoadingSites: true
    });

    return this.partnersSitesService
      .createSite(actions.site, actions.partnerId)
      .pipe(
        tap(() => {
          stateCtx.dispatch(new GetPartnerSites(actions.partnerId));
          this.snackbar.success('PARTNERS.SUCCESS_MSG.NEW_SITE');
        }),
        catchError((err) => {
          stateCtx.patchState({
            isLoadingSites: false
          });
          return throwError(err);
        })
      )
  }

  @Action(EditPartnerSite)
  editPartnerSite(
    stateCtx: StateContext<PartnersStateModel>,
    actions: EditPartnerSite
  ) {
    stateCtx.patchState({
      isLoadingSites: true
    });

    return this.partnersSitesService
      .editSite(actions.site, actions.siteId, actions.partnerId)
      .pipe(
        tap(() => {
          stateCtx.dispatch(new GetPartnerSites(actions.partnerId));
          this.snackbar.success('PARTNERS.SUCCESS_MSG.EDIT_SITE');
        }),
        catchError((err) => {
          stateCtx.patchState({
            isLoadingSites: false
          });
          return throwError(err);
        })
      )
  }

  @Action(DeletePartnerSite)
  deletePartnerSite(
    stateCtx: StateContext<PartnersStateModel>,
    actions: DeletePartnerSite
  ) {
    stateCtx.patchState({
      isLoadingSites: true
    });

    return this.partnersSitesService
      .deleteSite(actions.partnerId, actions.siteId)
      .pipe(
        tap(() => {
          stateCtx.dispatch(new GetPartnerSites(actions.partnerId));
          this.snackbar.success('PARTNERS.SUCCESS_MSG.DELETE_SITE');
        }),
        catchError((err) => {
          stateCtx.patchState({
            isLoadingSites: false
          });
          return throwError(err);
        })
      )
  }

  @Action(GetPartnerSites)
  getPartnersSites(
    stateCtx: StateContext<PartnersStateModel>,
    actions: GetPartnerSites
  ) {
    stateCtx.patchState({
      isLoadingSites: true
    });

    return this.partnersSitesService
      .getSites(actions.partnerId)
      .pipe(
        tap((res) => {
          stateCtx.patchState({
            sites: new MjxTableDataSource<PartnerSite>(res.data, res.data.length),
            isEmptySites: (res.data.length === 0)
          })
        }),
        catchError((err) => {
          this.snackbar.error('PARTNERS.FAIL_MSG.LIST_SITES');
          return throwError(err);
        }),
        finalize(() => {
          stateCtx.patchState({
            isLoadingSites: false
          });
        })
      )
  }

  @Action(GetPartnerAccounts)
  getPartnersAccounts(
    stateCtx: StateContext<PartnersStateModel>,
    actions: GetPartnerAccounts
  ) {
    stateCtx.patchState({
      isLoadingAccounts: true,
      accountsDataSource: null,
      isEmptyAccounts: false,
      accounts: null
    });

    return this.partAccService
      .getAccounts(actions.partnerId)
        .pipe(
          tap((res: CommonResponse<PaginationModel<PartnerAccount>>) => {
            const ordenedAccounts = orderPartnersAccountsByImplicitID(res.data.items);

            stateCtx.patchState({
              accountsDataSource: new MjxTableDataSource<PartnerAccount>(res.data.items, res.data.items.length),
              isEmptyAccounts: (res.data.items.length === 0),
              accounts: ordenedAccounts
            })
          }),
          catchError((err) => {
            this.snackbar.error('SHARED.FAIL_MSG.PARTNERS.LIST_ACCOUNTS');

            return throwError(err);
          }),
          finalize(() => {
            stateCtx.patchState({
              isLoadingAccounts: false
            });
          })
      )
  }

  @Action(CreatePartnerAccount)
  createPartnerAccount(
    stateCtx: StateContext<PartnersStateModel>,
    actions: CreatePartnerAccount
  ) {
    stateCtx.patchState({
      isLoadingAccounts: true
    });

    return this.partAccService
      .createAccount(actions.partnerId)
      .pipe(
        tap(() => {
          stateCtx.dispatch(new GetPartnerAccounts(actions.partnerId));

          this.snackbar.success('PARTNERS.SUCCESS_MSG.NEW_ACCOUNT');
        }),
        catchError((err) => {
          this.snackbar.error('PARTNERS.FAIL_MSG.NEW_ACCOUNT');

          stateCtx.patchState({
            isLoadingAccounts: false
          });

          return throwError(err);
        })
      )
  }

  @Action(EditPartnerAccountState)
  editPartnerAccountState(
    stateCtx: StateContext<PartnersStateModel>,
    actions: EditPartnerAccountState
  ) {
    stateCtx.patchState({
      isLoadingAccounts: true
    });

    return this.partAccService
      .updateState(actions.accountId, actions.accountState)
      .pipe(
        tap(() => {
          this.snackbar.success('ACCOUNTS.SUCCESS_SAVE_PREFERENCE');
        }),
        catchError((err) => {
          stateCtx.patchState({
            isLoadingAccounts: false
          });

          return throwError(err);
        })
      )
  }


  @Action(EditPartnerAccountName)
  editPartnerAccountName(
    stateCtx: StateContext<PartnersStateModel>,
    actions: EditPartnerAccountName
  ) {
    stateCtx.patchState({
      isLoadingAccounts: true
    });

    return this.partAccService
      .updateName(actions.accountId, {accountName: actions.accountName})
      .pipe(
        tap(() => {
          stateCtx.dispatch(new GetPartnerAccounts(actions.partnerId));

          this.snackbar.success('PARTNERS.SUCCESS_MSG.EDIT_UPDATE_NAME');
        }),
        catchError((err) => {
          this.snackbar.error('PARTNERS.FAIL_MSG.EDIT_UPDATE_NAME');

          stateCtx.patchState({
            isLoadingAccounts: false
          });

          return throwError(err);
        })
      )
  }


  @Action(GetPartnerBillingPlans)
  getPartnerBillingPlans(
    stateCtx: StateContext<PartnersStateModel>,
    actions: GetPartnerBillingPlans
  ) {
    stateCtx.patchState({
      isLoadingBillingPlans: true,
      canCreateBillingPlans: true,
      isEmptyBillingPlans: false,
      hasError: false
    })

    return this.partnersBillingPlansService
      .getBillingPlans(actions.partnerId)
      .pipe(
        catchError((err) => {
          this.snackbar.error('PARTNERS.FAIL_MSG.LIST_BILLING_PLAN')
          stateCtx.patchState({
            hasError: true
          })

          return throwError(err);
        }),
        tap((res: CommonResponse<PaginationModel<PartnerBillingPlan>>) => {
          const sortPlans = (a: PartnerBillingPlan, b: PartnerBillingPlan) => {
            const dateA = new Date(a.scheduleStartsAt).getTime();
            const dateB = new Date(b.scheduleStartsAt).getTime();

            return -(dateA - dateB);
          };

          const billingPlans = res.data.items;

          billingPlans.sort(sortPlans);

          const theresWaiting = billingPlans.filter(res => res.scheduleStatus === ScheduleStatusEnum.Waiting);

          const dataSource =
            new MjxTableDataSource<PartnerBillingPlan>(billingPlans, res.data.total);

          stateCtx.patchState({
            billigPlans: dataSource,
            canCreateBillingPlans: (theresWaiting.length === 0),
            isEmptyBillingPlans: (dataSource.total === 0)
          })
        }),
        finalize(() => {
          stateCtx.patchState({
            isLoadingBillingPlans: false
          })
        })
      );
  }

  @Action(ClearPartnerBillingPlansState)
  clarPartnerBillingPlans(
    stateCtx: StateContext<PartnersStateModel>,
  ) {
    stateCtx.patchState({
      billigPlans: undefined,
      isEmptyBillingPlans: true,
    })
  }

  @Action(EditPartnerBillingPlan)
  editPartnerBillingPlan(
    stateCtx: StateContext<PartnersStateModel>,
    actions: EditPartnerBillingPlan
  ) {
    stateCtx.patchState({
      isLoadingBillingPlans: true,
      isEmptyBillingPlans: false,
      hasError: false
    });

    return this.partnersBillingPlansService
      .updateBillingPlan(actions.billingPlan)
      .pipe(
        catchError((err) => {
          this.snackbar.error('PARTNERS.FAIL_MSG.EDIT_BILLING_PLAN');

          stateCtx.patchState({
            hasSavingError: true
          })

          return throwError(err);
        }),
        tap(() => {
          this.snackbar.success('PARTNERS.SUCCESS_MSG.EDIT_BILLING_PLAN');

          stateCtx
            .dispatch(new GetPartnerBillingPlans(actions.billingPlan.refCustomer));
        }),
        finalize(() => {
          stateCtx.patchState({
            isLoadingBillingPlans: false
          });
        })
      )
  }

  @Action(EditActivePartnerBillingPlan)
  editActivePartnerBillingPlan(
    stateCtx: StateContext<PartnersStateModel>,
    actions: EditActivePartnerBillingPlan
  ) {
    stateCtx.patchState({
      isLoadingBillingPlans: true,
      isEmptyBillingPlans: false,
      hasError: false
    });

    return this.partnersBillingPlansService.
      updateActiveBillingPlan(actions.billingPlan)
      .pipe(
        catchError((err) => {
          this.snackbar.error('PARTNERS.FAIL_MSG.EDIT_BILLING_PLAN');

          stateCtx.patchState({
            hasSavingError: true
          })

          return throwError(err);
        }),
        tap(() => {
          this.snackbar.success('PARTNERS.SUCCESS_MSG.EDIT_BILLING_PLAN');

          stateCtx
            .dispatch(new GetPartnerBillingPlans(actions.billingPlan.refCustomer));
        }),
        finalize(() => {
          stateCtx.patchState({
            isLoadingBillingPlans: false
          });
        })
      )
  }

  @Action(ArchivePartnerBillingPlan)
  archivePartnerBillingPlan(
    stateCtx: StateContext<PartnersStateModel>,
    actions: ArchivePartnerBillingPlan
  ) {
    stateCtx.patchState({
      isLoadingBillingPlans: true,
      isEmptyBillingPlans: false,
      hasError: false
    });

    return this.partnersBillingPlansService
      .archiveBillingPlan(actions.billingPlan)
      .pipe(
        tap(() => {
          stateCtx.dispatch(new GetPartnerBillingPlans(actions.billingPlan.refCustomer));
          this.snackbar.success('PARTNERS.SUCCESS_MSG.DELETE_BILLING_PLAN');
        }),
        catchError((err) => {
          this.snackbar.error('PARTNERS.FAIL_MSG.DELETE_BILLING_PLAN');

          stateCtx.patchState({
            isLoadingBillingPlans: false
          });

          return throwError(err);
        })
      )
  }

  @Action(CreatePartnerBilligPlan)
  createPartnerBillingPlan(
    stateCtx: StateContext<PartnersStateModel>,
    actions: CreatePartnerBilligPlan
  ) {
    stateCtx.patchState({
      isLoadingBillingPlans: true
    });

  return this.partnersBillingPlansService
    .createBillingPlan(actions.billingPlan)
    .pipe(
      tap(() => {
        stateCtx.dispatch(new GetPartnerBillingPlans(actions.billingPlan.refCustomer));

        this.snackbar.success('PARTNERS.SUCCESS_MSG.NEW_BILLING_PLAN');
      }),
      catchError((err) => {
        this.snackbar.error('PARTNERS.FAIL_MSG.NEW_BILLING_PLAN');

        stateCtx.patchState({
          isLoadingBillingPlans: false
        })

        return throwError(err);
      })
    );
  }

  @Action(GetAccountSelectedBanks)
  getSelectedBanks(
    ctx: StateContext<PartnersStateModel>,
    actions: GetAccountSelectedBanks
  ) {
    ctx.patchState({
      isLoadingBanks: true
    });

    return this.corporateService
      .getAccountSelectedBanks(actions.accountId)
      .pipe(
        tap((res) => {
          ctx.patchState({
            cashInBanks: (res.data) ? res.data.routing.pixCashIn: [],
            cashOutBanks: (res.data) ? res.data.routing.pixCashOut: []
          });
        }),
        catchError(err => throwError(err)),
        finalize(() => ctx.patchState({ isLoadingBanks: false }))
      )
  }

  @Action(SetAccountOperationBanks)
  setOperationBank(
    ctx: StateContext<PartnersStateModel>,
    actions: SetAccountOperationBanks
  ) {
    ctx.patchState({
      isSavingBanks: true
    });

    return this.corporateService.setAccountOperationsBank(
      actions.accountId,
      actions.configuration
    ).pipe(
        tap(() => {
          this.snackbar.success('ACCOUNTS.SUCCESS_SAVE_PREFERENCE')
        }),
        catchError(err => throwError(err)),
        finalize(() => ctx.patchState({ isSavingBanks: false }))
      );
  }

  @Action(SetAccountSecurityKey)
  setAccountSecurityKey(
    ctx: StateContext<PartnersStateModel>,
    actions: SetAccountSecurityKey
  ) {
    ctx.patchState({
      isSavingPreferences: true
    });

    return this.corporateService.setAccountSecurityKey(
      actions.accountId,
      {
        security: {
          messaging: {
            key: actions.key
          }
        }
      }
    ).pipe(
      tap(() => {
        this.snackbar.success('PARTNERS.SUCCESS_MSG.PREFERENCES')
      }),
      catchError(err => throwError(err)),
      finalize(() => ctx.patchState({ isSavingPreferences: false }))
    );
  }


  @Action(GetAccountSecurityKey)
  getAccountSecurityKey(
    ctx: StateContext<PartnersStateModel>,
    actions: GetAccountSecurityKey
  ) {
    ctx.patchState({
      isLoadingPreferences: true,
      preferencesKey: null
    });

    const userPermissions = this.store.selectSnapshot(SessionState.getCurrentUserPermissions);
    const userPermitted = userPermissions.includes(AccessRolesEnum.CorporateAdmin);
    if (userPermitted) {
      return this.corporateService.getAccountSecurityKey(
        actions.accountId
      ).pipe(
        tap((res: CommonResponse<AccountUserPreferencesObject>) => {
          ctx.patchState({
            preferencesKey: res.data?.security?.messaging?.key
          })
        }),
        catchError(err => throwError(err)),
        finalize(() => ctx.patchState({ isLoadingPreferences: false }))
      );
    } else {
      return null;
    }

  }


  @Action(PartnerSetAccountSecurityKey)
  partnerSetAccountSecurityKey(
    ctx: StateContext<PartnersStateModel>,
    actions: PartnerSetAccountSecurityKey
  ) {
    ctx.patchState({
      isSavingPreferences: true
    });

    return this.partnerApiService.setAccountSecurityKey(
      actions.accountId,
      {
        security: {
          messaging: {
            key: actions.key
          }
        }
      }
    ).pipe(
      tap(() => {
        this.snackbar.success('SHARED.PREFERENCES.PREFERENCES_UPDATED')
      }),
      catchError(err => throwError(err)),
      finalize(() => ctx.patchState({ isSavingPreferences: false }))
    );
  }

  @Action(PartnerGetAccountSecurityKey)
  partnerGetAccountSecurityKey(
    ctx: StateContext<PartnersStateModel>,
    actions: PartnerGetAccountSecurityKey
  ) {
    ctx.patchState({
      isLoadingPreferences: true,
      preferencesKey: null
    });

    return this.partnerApiService.getAccountSecurityKey(
      actions.accountId
    ).pipe(
      tap((res: CommonResponse<AccountUserPreferencesObject>) => {
        ctx.patchState({
          preferencesKey: res.data?.security?.messaging?.key
        })
      }),
      catchError(err => throwError(err)),
      finalize(() => ctx.patchState({ isLoadingPreferences: false }))
    );
  }

  private navigateToList() {
    this.ngZone.run(() => this.router.navigateByUrl(`/${RoutesEnum.Partners}`));
  }

  private navigateBack(partner: PartnerFormModel) {
    this.ngZone.run(() => {
      this.router.navigateByUrl(`/${RoutesEnum.Partners}/edit`, {
        state: partner
      })
    })
  }
}
