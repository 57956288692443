import {UserTypeEnum} from "../enums/user-type.enum";

export interface UserTypeOption {
  user: UserTypeEnum,
  translateKey: string;
}

export const userTypeOptions: UserTypeOption[] = [
  { user: UserTypeEnum.Corporate, translateKey: 'USERS.USER_TYPE.MASTER' },
  { user: UserTypeEnum.CorporateSupport, translateKey: 'USERS.USER_TYPE.CORPORATE_SUPPORT' },
  { user: UserTypeEnum.Admin, translateKey: 'USERS.USER_TYPE.ADMIN' },
  { user: UserTypeEnum.PartnerMemberFin, translateKey: 'USERS.USER_TYPE.PARTNER_FIN' },
  { user: UserTypeEnum.Operator, translateKey: 'USERS.USER_TYPE.OPERATOR' }
]

export const partnerUserTypeOptions: UserTypeOption[] = [
  { user: UserTypeEnum.PartnerMemberFin, translateKey: 'USERS.USER_TYPE.PARTNER_FIN' },
  { user: UserTypeEnum.Operator, translateKey: 'USERS.USER_TYPE.OPERATOR' }
]

export const filterUserTypeOptions = [
  { user: 'Corporate', translateKey: 'USERS.USER_TYPE.MASTER' },
  { user: 'Partner', translateKey: 'USERS.USER_TYPE.PARTNER' }
]
