import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { PaginationModel } from 'src/app/shared/models/pagination.model';

import { environment } from '../../../../environments/environment';
import { routes } from '../../../../environments/vendor/routes/routes';

import { CommonResponse } from '../../../shared/models/response.model';
import { PartnerAccount } from '../models/partner-account.model';
import {AccessRolesEnum} from "../../../shared/enums/access-roles.enum";

const API = environment.apiUrl;

@Injectable({
  providedIn: 'root'
})
export class PartnersAccountsService {

  constructor(
    private http: HttpClient
    ) { }

  getAccounts(partnerId: number) {
    const url = `${API}/${routes?.accounts}?refCustomer=${partnerId}`;

    return this.http
      .get<CommonResponse<PaginationModel<PartnerAccount>>>(url);
  }

  createAccount(partnerId) {
    const url = `${API}/${routes?.accounts}`;

    return this.http.post<CommonResponse<PartnerAccount>>(url, {
      refCustomer: Number.parseInt(partnerId),
      accountType: AccessRolesEnum.Partner
    });
  }

  updateState(accountId: number, accountState: string) {
    const url = `${API}/${routes?.accounts}/${accountId}/state/${accountState}`;

    return this.http.put<CommonResponse<PartnerAccount>>(url, null);
  }

  updateName(accountId: number, accountName: any) {
    const url = `${API}/${routes?.accounts}/${accountId}/name`;

    return this.http.put<CommonResponse<PartnerAccount>>(url, accountName);
  }
}
