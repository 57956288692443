import { CustomSelectOptionModel } from 'src/app/shared/models/custom-select-option.model';

export enum TransactionRequestStatusEnum {
  Pending =   'Pending',
  Rejected =  'Rejected',
  Approved =  'Approved',
  Paid =      'Paid',
  Executed = 'Executed',
  Cancelled =  'Canceled'
}

export const transactionRequestStatusOption: CustomSelectOptionModel<TransactionRequestStatusEnum>[] = [
  { value: null, translateKey: 'SHARED.FILTER.ALL' },
  { value: TransactionRequestStatusEnum.Pending, translateKey: 'SHARED.ENUM.TRANSACTION_STATUS.PENDING' },
  { value: TransactionRequestStatusEnum.Approved, translateKey: 'SHARED.ENUM.TRANSACTION_STATUS.APPROVED' },
  { value: TransactionRequestStatusEnum.Rejected, translateKey: 'SHARED.ENUM.TRANSACTION_STATUS.REJECTED' },
  { value: TransactionRequestStatusEnum.Cancelled, translateKey: 'SHARED.ENUM.TRANSACTION_STATUS.CANCELLED' }
];
