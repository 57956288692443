import {Component, Input, OnDestroy, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {SnackbarService} from "../../../../services/snackbar.service";
import {QuickInfoMessage} from "../../../../components/quick-info/models/quick-info-message.model";
import {Actions, ActionType, ofActionSuccessful, Select, Store} from "@ngxs/store";
import {Observable, Subscription, tap} from "rxjs";
import {MatDialog} from "@angular/material/dialog";
import {ConfirmDialogComponent} from "../../../../components/confirm-dialog/confirm-dialog.component";
import {ConfirmDialogModel} from "../../../../models/confirm-dialog.model";
import {UntypedFormControl, Validators} from "@angular/forms";
import {AuthState} from "../../../../../core/state/auth/auth.state";
import {isPartner} from "../../../../utils/get-context";
import {AccountSecurityState} from "../../states/account-security.state";
import {ConfirmAppSetup, DeactivateAppSetup, ResetAppSetup} from "../../states/app-security.actions";
import {MFA} from "../../../../../core/models/user.model";

@Component({
  selector: 'account-security',
  templateUrl: './account-security.component.html',
  styleUrls: ['./account-security.component.scss']
})
export class AccountSecurityComponent implements OnInit, OnDestroy {
  @Input() isProfile: boolean;
  pageHeight: string;

  @Select(AccountSecurityState.isLoading)
  isLoading$: Observable<boolean>;

  private pageSubscriptions: Map<number, Subscription> = new Map();
  showInfo = false;
  isEnabling = false;
  isAppEnabled = false;
  isDeactivating = true;
  isPartner = isPartner.valueOf();
  disabledMFA = false;
  setupCode = new UntypedFormControl(null, [Validators.required, Validators.minLength(6)]);

  @ViewChild('customModalContent') customModalContent: TemplateRef<any>;
  @ViewChild('customModalTitle') customModalTitle: TemplateRef<any>;
  @ViewChild('customModalActions') customModalActions: TemplateRef<any>;

  constructor(
    private snackbar: SnackbarService,
    private dialog: MatDialog,
    private store: Store,
    private actions$: Actions
  ) { }

  ngOnInit() {
    this.listenAppSecurityActions(1, DeactivateAppSetup, () => {
      this.isDeactivating = false;
      this.isEnabling = false;
      this.isAppEnabled = false;
      this.setupCode.setValue(null);
    });

    this.listenAppSecurityActions(1, ConfirmAppSetup, () => {
      this.isDeactivating = false;
      this.isEnabling = false;
      this.isAppEnabled = true;
      this.confirmActivation();
      this.setupCode.setValue(null);
    });

    this.listenMFAState();
  }

  ngOnDestroy() {
    this.unsubscribeAll();
  }

  enableAuth() {
    this.store.dispatch(new ResetAppSetup());
    this.isEnabling = true;
  }

  disableAuth() {
    this.store.dispatch(new DeactivateAppSetup(this.setupCode.value));
  }

  deactivateAuth() {
    this.isDeactivating = true;
    this.dialog.open(ConfirmDialogComponent, {
      panelClass: 'custom-modal',
      width: '500px',
      data: this.modalDialogData
    });
  }

  cancelMfa() {
    this.isEnabling = false;
  }

  changeInfoStatus() {
    this.showInfo = !this.showInfo

    if (this.showInfo) {
      setTimeout(() => {
        const elements = document.getElementsByClassName('security-container');

        if (elements[0]) {
          const element = elements[0];
          this.pageHeight = `${element?.scrollHeight}px`;
        }
      }, 0)
    } else {
      this.pageHeight = null;
    }
  }

  get infoMessages(): QuickInfoMessage[] {
    return [
      {
        messages: ['INTEGRATION.SECURITY.G_AUTH.INFO1'],
        icon: 'verified_user'
      }
    ]
  }

  private checkMfa(mfa: MFA[]) {
    if (!mfa) {
      this.disabledMFA = true;
      return;
    } else {
      this.disabledMFA = false;
    }

    mfa.forEach(mfa => {
      if (mfa?.app !== null) {
        this.isAppEnabled = mfa.app;
      }
    })
  }

  private confirmActivation() {
    this.dialog.open(ConfirmDialogComponent, {
      panelClass: 'custom-modal',
      width: '500px',
      data: this.modalDialogData
    });
  }

  private get modalDialogData(): ConfirmDialogModel {
    return  {
      customTitleTemplate: this.customModalTitle,
      customContentTemplate: this.customModalContent,
      customActionsTemplate: this.customModalActions
    }
  }

  private listenMFAState() {
    const subs = this.store.select(AuthState.getMFA)
      .subscribe((mfa: MFA[]) => {
        this.checkMfa(mfa);
      });

    this.pageSubscriptions.set(2, subs);
  }

  private listenAppSecurityActions(id: number, action: ActionType, callback: Function) {
    const subs = this.actions$
      .pipe(
        ofActionSuccessful(action),
        tap(() => callback())
      ).subscribe();

    this.pageSubscriptions.set(id, subs);
  }

  private unsubscribeAll() {
    this.pageSubscriptions.forEach((subs) => {
      if (!subs.closed) {
        subs.unsubscribe();
      }
    });
  }
}
