import { Pipe, PipeTransform } from '@angular/core';
import {MerchantsTransactionStatusEnum} from "../../modules/merchants/enums/merchants-transaction-status.enum";
import { TransactionStateEnum } from 'src/app/modules/transactions/enums/transaction-state.enum';
import { ApprovalStatus } from 'src/app/modules/transactions/enums/approval-status.enum';
import { TransactionRequestStatusEnum } from 'src/app/modules/transactions/enums/transaction-request-status.enum';

@Pipe({
  name: 'transactionStatusTranslateKey'
})
export class TranslateTransactionStatusPipe implements PipeTransform {
  transform(status): string {
    let translateKey: string = status;
    switch (status) {
      case MerchantsTransactionStatusEnum.APPROVED:
      case ApprovalStatus.APPROVED:
      case TransactionRequestStatusEnum.Approved:
        translateKey = 'SHARED.ENUM.TRANSACTION_STATUS.APPROVED'
        break;

      case TransactionStateEnum.Rejected:
      case ApprovalStatus.REJECTED:
      case TransactionRequestStatusEnum.Rejected:
        translateKey = 'SHARED.ENUM.TRANSACTION_STATUS.REJECTED';
        break;

      case ApprovalStatus.PAID:
      case TransactionRequestStatusEnum.Paid:
        translateKey = 'SHARED.ENUM.TRANSACTION_STATUS.PAID'
        break;

      case ApprovalStatus.REVIEW:
      case TransactionRequestStatusEnum.Pending:
        translateKey = 'SHARED.ENUM.TRANSACTION_STATUS.REVIEW'
        break;

      case MerchantsTransactionStatusEnum.EXECUTED:
      case TransactionRequestStatusEnum.Executed:
        translateKey = 'SHARED.ENUM.TRANSACTION_STATUS.EXECUTED'
        break;

      case TransactionStateEnum.Cancelled:
      case TransactionStateEnum.Canceled:
        translateKey = 'SHARED.ENUM.TRANSACTION_STATUS.CANCELLED';
        break;

      case MerchantsTransactionStatusEnum.ALL:
        translateKey = 'SHARED.ENUM.TRANSACTION_STATUS.ALL'
        break;
      case MerchantsTransactionStatusEnum.REVISION:
        translateKey = 'SHARED.ENUM.TRANSACTION_STATUS.REVISION'
        break;
      case MerchantsTransactionStatusEnum.DENIED:
        translateKey = 'SHARED.ENUM.TRANSACTION_STATUS.DENIED'
        break;
      case MerchantsTransactionStatusEnum.RECEIPT:
        translateKey = 'SHARED.ENUM.TRANSACTION_STATUS.RECEIPT'
        break;
      case MerchantsTransactionStatusEnum.PENDING:
        translateKey = 'SHARED.ENUM.TRANSACTION_STATUS.PENDING'
        break;
      case TransactionStateEnum.Created:
        translateKey = 'SHARED.ENUM.TRANSACTION_STATUS.CREATED';
        break;
      case TransactionStateEnum.Registered:
        translateKey = 'SHARED.ENUM.TRANSACTION_STATUS.REGISTERED';
        break;
      case TransactionStateEnum.Error:
        translateKey = 'SHARED.ENUM.TRANSACTION_STATUS.ERROR';
        break;
      case TransactionStateEnum.Completed:
        translateKey = 'SHARED.ENUM.TRANSACTION_STATUS.COMPLETED';
        break;
      case TransactionStateEnum.Refund:
        translateKey = 'SHARED.ENUM.TRANSACTION_STATUS.REFUND';
        break;
      case TransactionStateEnum.Reversed:
        translateKey = 'SHARED.ENUM.TRANSACTION_STATUS.REVERSED';
        break;
    }
    return translateKey;
  }

}
