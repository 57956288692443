<div class="sections-container">
  <mjx-quick-info
    *ngIf="showInfo"
    [messages]="infoMessages"
    [position]="'right'"
    [topProximity]="'16px'"
    (mouseleave)="changeInfoDialog()">
  </mjx-quick-info>
  <ng-container *ngIf="!(isLoading$ | async); else loadingDataSpinner"
                [ngTemplateOutlet]="(isEdit) ? editSection : createSection">
  </ng-container>
</div>

<div class="actions" *ngIf="!hasUserType && !isEdit; else saveActions">
  <button mat-button class="custom-button custom-secondary-button" (click)="navigateBack()">
    <span>{{ 'USERS.CANCEL_BTN' | translate }}</span>
  </button>
</div>

<ng-template #createSection>
  <mjx-section [content]="userTypeTemplate"></mjx-section>
  <br>
  <mjx-section [ngClass]="{'disabled-field': !hasUserType}" [name]="'USERS.FORM.ACCOUNT_DATA.TITLE'" [content]="accountDataRef"></mjx-section>
  <br>
  <mjx-section [ngClass]="{'disabled-field': !hasUserType}" [name]="'USERS.FORM.USER_DATA.TITLE'" [content]="userInputsTemplate"></mjx-section>
  <br>
  <mjx-section *ngIf="canSelectAccount" [ngClass]="{'disabled-field': disableAccountsForm}" [name]="'USERS.TABS.LINKED_ACCOUNTS'" [content]="linkedAccounts"></mjx-section>
</ng-template>

<ng-template #editSection>
  <mjx-section *ngIf="!isProfile" [content]="userTypeTemplate"></mjx-section>
  <br *ngIf="!isProfile">
  <mjx-section [name]="'USERS.FORM.ACCOUNT_DATA.TITLE'" [content]="editAccountDataRef"
    [ngClass]="{'disabled-edit-field': !isEditAccountData}">

    <div class="section-actions custom-hint" (click)="changeInfoDialog()">
      <mat-icon>help</mat-icon>
      <span>{{ 'USERS.HELP_MSG' | translate }}</span>
    </div>

    <div *ngIf="!isEditAccountData" class="section-buttons custom-hint" (click)="editAccountData()">
      <span>{{ 'USERS.EDIT_BUTTON' | translate }}</span>
      <mat-icon>edit</mat-icon>
    </div>
  </mjx-section>
  <br>
  <mjx-section [name]="'USERS.FORM.USER_DATA.TITLE'" [content]="userInputsTemplate"
    [ngClass]="{'disabled-edit-field': !isEditUserData}">

    <div class="section-actions custom-hint" (click)="changeInfoDialog()">
      <mat-icon>help</mat-icon>
      <span>{{ 'USERS.HELP_MSG' | translate }}</span>
    </div>

    <div *ngIf="!isEditUserData" class="section-buttons custom-hint" (click)="editUserData()">
      <span>{{ 'USERS.EDIT_BUTTON' | translate }}</span>
      <mat-icon>edit</mat-icon>
    </div>
  </mjx-section>
  <br>
  <mjx-section *ngIf="canSelectAccount" [ngClass]="{'disabled-field': disableAccountsForm}" [name]="'USERS.TABS.LINKED_ACCOUNTS'" [content]="linkedAccounts"></mjx-section>
</ng-template>

<ng-template #linkedAccounts>
  <users-linked-accounts [partnerId]="selectedPartnerId" [userId]="this.user?.id"></users-linked-accounts>
</ng-template>

<ng-template #accountDataRef>
  <div class="data-section-content">
    <form [formGroup]="userForm" class="row align-items-start">
      <mjx-input [ngClass]="'col-md-4'">
        <mat-label>{{ 'USERS.FORM.USER_DATA.USER_NAME' | translate }}</mat-label>
        <mat-form-field floatLabel="never" class="errors-form-field">
          <input matInput formControlName="username" readonly onfocus="this.removeAttribute('readonly');">

          <mat-error *ngIf="userForm.controls['username'].hasError('usernamePattern')">
            <strong>{{ 'SHARED.FORM_VALIDATORS.USERNAME_PATTERN' | translate }}</strong>
          </mat-error>
          <mat-error *ngIf="userForm.controls['username'].hasError('emptySpaces')">
            <strong>{{ 'SHARED.FORM_VALIDATORS.EMPTY_SPACES' | translate }}</strong>
          </mat-error>
          <mat-error *ngIf="userForm.controls['username'].hasError('required')">
            <strong>{{ 'MANDATORY' | translate }}</strong>
          </mat-error>
          <mat-error *ngIf="userForm.controls['username'].hasError('minLengthTrim')">
            <strong>{{ 'MINLENGTH' | translate: {min:5} }}</strong>
          </mat-error>
          <mat-error *ngIf="userForm.controls['username'].hasError('maxLengthTrim')">
            <strong>{{ 'MAXLENGTH' | translate }}</strong>
          </mat-error>
          <mat-error *ngIf="userForm.controls['username'].hasError('whiteSpaces')">
            <strong>{{ 'HAS_WHITE_SPACES' | translate }}</strong>
          </mat-error>
        </mat-form-field>
      </mjx-input>

      <mjx-input [ngClass]="'col-md-4'" *ngIf="(selectedUserType === userTypes.Corporate || selectedUserType === userTypes.CorporateSupport)">
        <mat-label>{{ 'USERS.FORM.USER_DATA.PASSWORD' | translate }}</mat-label>
        <mat-form-field floatLabel="never" class="errors-form-field">
          <input matInput type="password" formControlName="password">
          <mat-error *ngIf="userForm.controls['password'].hasError('required')">
            <strong>{{ 'MANDATORY' | translate }}</strong>
          </mat-error>
          <mat-error *ngIf="userForm.controls['password'].hasError('minlength')">
            <strong>{{ 'MINLENGTH' | translate: {min:8} }}</strong>
          </mat-error>
          <mat-error *ngIf="userForm.controls['password'].hasError('maxlength')">
            <strong>{{ 'MAXLENGTH' | translate }}</strong>
          </mat-error>
          <mat-error *ngIf="userForm.controls['password'].hasError('lowercase')">
            <strong>{{ 'SHARED.FORM_VALIDATORS.LOWERCASE' | translate }}</strong>
          </mat-error>
          <mat-error *ngIf="userForm.controls['password'].hasError('uppercase')">
            <strong>{{ 'SHARED.FORM_VALIDATORS.UPPERCASE' | translate }}</strong>
          </mat-error>
          <mat-error *ngIf="userForm.controls['password'].hasError('number')">
            <strong>{{ 'SHARED.FORM_VALIDATORS.HAS_NUMBER' | translate }}</strong>
          </mat-error>
          <mat-error *ngIf="userForm.controls['password'].hasError('specialCharacter')">
            <strong>{{ 'SHARED.FORM_VALIDATORS.HAS_SPECIAL_CHARS' | translate }}</strong>
          </mat-error>
        </mat-form-field>
      </mjx-input>

      <mjx-input [ngClass]="'col-md-4'" *ngIf="(selectedUserType === userTypes.Corporate || selectedUserType === userTypes.CorporateSupport)">
        <mat-label>{{ 'USERS.FORM.USER_DATA.CONFIRM_PASSWORD' | translate }}</mat-label>
        <mat-form-field floatLabel="never" class="errors-form-field">
          <input matInput type="password" formControlName="confirmPassword">

          <mat-error *ngIf="userForm.controls['confirmPassword'].hasError('required')">
            <strong>{{ 'MANDATORY' | translate }}</strong>
          </mat-error>

          <mat-error *ngIf="userForm.controls['confirmPassword'].hasError('maxlength')">
            <strong>{{ 'MAXLENGTH' | translate }}</strong>
          </mat-error>

          <mat-error *ngIf="userForm.hasError('notSame') || userForm.controls['confirmPassword'].hasError('notSame')">
            <strong>{{ 'USERS.PASSWORD_CONFIRMATION' | translate }}</strong>
          </mat-error>
        </mat-form-field>
      </mjx-input>
    </form>
  </div>
</ng-template>

<ng-template #editAccountDataRef>
  <div class="data-section-content">
    <form [formGroup]="userForm" class="row align-items-start">
      <mjx-input [ngClass]="isProfile ? 'col-md-2' : 'col-md-4'">
        <mat-label>{{ 'USERS.FORM.USER_DATA.USER_NAME' | translate }}</mat-label>
        <mat-form-field floatLabel="never" class="errors-form-field">
          <input matInput formControlName="username" readonly onfocus="this.removeAttribute('readonly');">

          <mat-error *ngIf="userForm.controls['username'].hasError('usernamePattern')">
            <strong>{{ 'SHARED.FORM_VALIDATORS.USERNAME_PATTERN' | translate }}</strong>
          </mat-error>
          <mat-error *ngIf="userForm.controls['username'].hasError('emptySpaces')">
            <strong>{{ 'SHARED.FORM_VALIDATORS.EMPTY_SPACES' | translate }}</strong>
          </mat-error>
          <mat-error *ngIf="userForm.controls['username'].hasError('required')">
            <strong>{{ 'MANDATORY' | translate }}</strong>
          </mat-error>
          <mat-error *ngIf="userForm.controls['username'].hasError('minLengthTrim')">
            <strong>{{ 'MINLENGTH' | translate: {min:5} }}</strong>
          </mat-error>
          <mat-error *ngIf="userForm.controls['username'].hasError('maxLengthTrim')">
            <strong>{{ 'MAXLENGTH' | translate }}</strong>
          </mat-error>
          <mat-error *ngIf="userForm.controls['username'].hasError('whiteSpaces')">
            <strong>{{ 'HAS_WHITE_SPACES' | translate }}</strong>
          </mat-error>
        </mat-form-field>
      </mjx-input>

      <mjx-input class="col-md-3" *ngIf="isProfile">
        <mat-label>{{ 'USERS.FORM.USER_DATA.CURRENT_PASSWORD' | translate }}</mat-label>
        <mat-form-field floatLabel="never">
          <input matInput type="password" formControlName="currentPassword" readonly onfocus="this.removeAttribute('readonly');">

          <mat-error *ngIf="userForm.controls['currentPassword'].hasError('required')">
            <strong>{{ 'MANDATORY' | translate }}</strong>
          </mat-error>
          <mat-error *ngIf="userForm.controls['currentPassword'].hasError('maxlength')">
            <strong>{{ 'MAXLENGTH' | translate }}</strong>
          </mat-error>
        </mat-form-field>
      </mjx-input>

      <mjx-input [ngClass]="isProfile ? 'col-md-3' : 'col-md-4'">
        <mat-label>{{ 'USERS.FORM.USER_DATA.PASSWORD' | translate }}</mat-label>
        <mat-form-field floatLabel="never" class="errors-form-field">
          <input matInput type="password" formControlName="password">
          <mat-error *ngIf="userForm.controls['password'].hasError('required')">
            <strong>{{ 'MANDATORY' | translate }}</strong>
          </mat-error>
          <mat-error *ngIf="userForm.controls['password'].hasError('minlength')">
            <strong>{{ 'MINLENGTH' | translate: {min:8} }}</strong>
          </mat-error>
          <mat-error *ngIf="userForm.controls['password'].hasError('maxlength')">
            <strong>{{ 'MAXLENGTH' | translate }}</strong>
          </mat-error>
          <mat-error *ngIf="userForm.controls['password'].hasError('lowercase')">
            <strong>{{ 'SHARED.FORM_VALIDATORS.LOWERCASE' | translate }}</strong>
          </mat-error>
          <mat-error *ngIf="userForm.controls['password'].hasError('uppercase')">
            <strong>{{ 'SHARED.FORM_VALIDATORS.UPPERCASE' | translate }}</strong>
          </mat-error>
          <mat-error *ngIf="userForm.controls['password'].hasError('number')">
            <strong>{{ 'SHARED.FORM_VALIDATORS.HAS_NUMBER' | translate }}</strong>
          </mat-error>
          <mat-error *ngIf="userForm.controls['password'].hasError('specialCharacter')">
            <strong>{{ 'SHARED.FORM_VALIDATORS.HAS_SPECIAL_CHARS' | translate }}</strong>
          </mat-error>
        </mat-form-field>
      </mjx-input>

      <mjx-input [ngClass]="isProfile ? 'col-md-3' : 'col-md-4'" *ngIf="isProfile">
        <mat-label>{{ 'USERS.FORM.USER_DATA.CONFIRM_PASSWORD' | translate }}</mat-label>
        <mat-form-field floatLabel="never" class="errors-form-field">
          <input matInput type="password" formControlName="confirmPassword">

          <mat-error *ngIf="userForm.controls['confirmPassword'].hasError('required')">
            <strong>{{ 'MANDATORY' | translate }}</strong>
          </mat-error>
          <mat-error *ngIf="userForm.controls['confirmPassword'].hasError('maxlength')">
            <strong>{{ 'MAXLENGTH' | translate }}</strong>
          </mat-error>
          <mat-error *ngIf="userForm.hasError('notSame') || userForm.controls['confirmPassword'].hasError('notSame')">
            <strong>{{ 'USERS.PASSWORD_CONFIRMATION' | translate }}</strong>
          </mat-error>
        </mat-form-field>
      </mjx-input>

      <div class="col-md-4" *ngIf="(this.isEdit && isEditAccountData && !isProfile)">
        <div [ngClass]="(isFetchingKey$ | async) ? 'loading-reset-key' : 'reset-button'" (click)="requestPasswordResetKey()">
          <mat-icon>{{(isFetchingKey$ | async) ? 'autorenew' : 'lock_reset'}}</mat-icon>
          <span>{{ 'USERS.FORM.RESET.REQUEST_RESET_BUTTON' | translate }}</span>
        </div>
      </div>
    </form>
  </div>
</ng-template>

<ng-template #userInputsTemplate>
  <div class="data-section-content">
    <form [formGroup]="userForm" class="row align-items-start" autocomplete="off">
      <mjx-input class="col-md-4">
        <mat-label>{{ 'USERS.FORM.USER_DATA.FULL_NAME' | translate }}</mat-label>
        <mat-form-field floatLabel="never" class="errors-form-field">
          <input matInput formControlName="fullName">
          <mat-error *ngIf="userForm.controls['fullName'].hasError('required')">
            <strong>{{ 'MANDATORY' | translate }}</strong>
          </mat-error>
          <mat-error *ngIf="userForm.controls['fullName'].hasError('minLengthTrim')">
            <strong>{{ 'MINLENGTH' | translate: {min:5} }}</strong>
          </mat-error>
          <mat-error *ngIf="userForm.controls['fullName'].hasError('maxLengthTrim')">
            <strong>{{ 'MAXLENGTH' | translate }}</strong>
          </mat-error>
          <mat-error *ngIf="userForm.controls['fullName'].hasError('whiteSpaces')">
            <strong>{{ 'HAS_WHITE_SPACES' | translate }}</strong>
          </mat-error>
        </mat-form-field>
      </mjx-input>

      <mjx-input class="col-md-4">
        <mat-label>{{ 'USERS.FORM.USER_DATA.EMAIL' | translate }}</mat-label>
        <mat-form-field floatLabel="never" class="errors-form-field">
          <input matInput formControlName="email">
          <mat-error *ngIf="userForm.controls['email'].hasError('required')">
            <strong>{{ 'MANDATORY' | translate }}</strong>
          </mat-error>
          <mat-error *ngIf="userForm.controls['email'].hasError('minLengthTrim')">
            <strong>{{ 'MINLENGTH' | translate: {min:5} }}</strong>
          </mat-error>
          <mat-error *ngIf="userForm.controls['email'].hasError('maxLengthTrim')">
            <strong>{{ 'MAXLENGTH' | translate }}</strong>
          </mat-error>
          <mat-error *ngIf="userForm.controls['email'].hasError('email')">
            <strong>{{ 'INVALID_EMAIL' | translate }}</strong>
          </mat-error>
        </mat-form-field>
      </mjx-input>
    </form>
  </div>
</ng-template>

<ng-template #userTypeTemplate>
  <div class="data-section-content">
    <form [formGroup]="userForm" class="row" autocomplete="off">
      <mjx-input class="col-md-4">
        <mat-label>{{ 'USERS.FORM.USER_DATA.USER_TYPE' | translate }}</mat-label>
        <mat-form-field>
          <mat-select formControlName="userType" class="style-input-form" (selectionChange)="emitUserType($event)">
            <mat-option [value]="user.user" *ngFor="let user of userTypeOptions">
              {{ user.translateKey | translate }}
            </mat-option>
          </mat-select>
          <mat-error *ngIf="userForm.controls['userType'].hasError('required')">
            <strong>{{ 'MANDATORY' | translate }}</strong>
          </mat-error>
        </mat-form-field>
      </mjx-input>
      <mjx-input *ngIf="canSelectPartner && !isEdit" class="col-md-4">
        <mat-label>{{ 'USERS.FORM.USER_DATA.SELECT_PARTNER' | translate }}</mat-label>
        <mat-form-field>
          <mat-select formControlName="refCustomer" class="style-input-form">
            <mat-option *ngIf="(loadingPartner$ | async)" class="loading-option">
              <mat-spinner diameter="16" mode="indeterminate" class="loading-spinner"></mat-spinner>
            </mat-option>
            <mat-option *ngFor="let partner of (partners$ | async)" [value]="partner.id">
              {{ partner?.companyName ?? partner?.managerFullName }}
            </mat-option>
          </mat-select>
          <mat-error *ngIf="userForm.controls['refCustomer'].hasError('required')">
            <strong>{{ 'MANDATORY' | translate }}</strong>
          </mat-error>
        </mat-form-field>
      </mjx-input>
    </form>
  </div>
</ng-template>

<ng-template #editUserInputsTemplate>
  <div class="data-section-content">
    <form [formGroup]="userForm" class="row align-items-start" autocomplete="off">
      <mjx-input class="col-md-6">
        <mat-label>{{ 'USERS.FORM.USER_DATA.FULL_NAME' | translate }}</mat-label>
        <mat-form-field class="errors-form-field">
          <input matInput formControlName="fullName">
          <mat-error *ngIf="userForm.controls['fullName'].hasError('required')">
            <strong>{{ 'MANDATORY' | translate }}</strong>
          </mat-error>
          <mat-error *ngIf="userForm.controls['fullName'].hasError('minLengthTrim')">
            <strong>{{ 'MINLENGTH' | translate: {min:5} }}</strong>
          </mat-error>
          <mat-error *ngIf="userForm.controls['fullName'].hasError('maxLengthTrim')">
            <strong>{{ 'MAXLENGTH' | translate }}</strong>
          </mat-error>
          <mat-error *ngIf="userForm.controls['fullName'].hasError('whiteSpaces')">
            <strong>{{ 'HAS_WHITE_SPACES' | translate }}</strong>
          </mat-error>
        </mat-form-field>
      </mjx-input>

      <mjx-input class="col-md-5">
        <mat-label>{{ 'USERS.FORM.USER_DATA.EMAIL' | translate }}</mat-label>
        <mat-form-field class="errors-form-field">
          <input matInput formControlName="email">
          <mat-error *ngIf="userForm.controls['email'].hasError('required')">
            <strong>{{ 'MANDATORY' | translate }}</strong>
          </mat-error>
          <mat-error *ngIf="userForm.controls['email'].hasError('minLengthTrim')">
            <strong>{{ 'MINLENGTH' | translate: {min:5} }}</strong>
          </mat-error>
          <mat-error *ngIf="userForm.controls['email'].hasError('maxLengthTrim')">
            <strong>{{ 'MAXLENGTH' | translate }}</strong>
          </mat-error>
        </mat-form-field>
      </mjx-input>

      <mjx-input [ngClass]="isProfile ? 'col-md-3' : 'col-md-4'"
                 *ngIf="isEdit">
        <mat-label>{{ 'USERS.FORM.USER_DATA.PASSWORD' | translate }}</mat-label>
        <mat-form-field floatLabel="never" class="errors-form-field">
          <input matInput type="password" formControlName="password">
          <mat-error *ngIf="userForm.controls['password'].hasError('required')">
            <strong>{{ 'MANDATORY' | translate }}</strong>
          </mat-error>
          <mat-error *ngIf="userForm.controls['password'].hasError('minlength')">
            <strong>{{ 'MINLENGTH' | translate: {min:8} }}</strong>
          </mat-error>
          <mat-error *ngIf="userForm.controls['password'].hasError('maxlength')">
            <strong>{{ 'MAXLENGTH' | translate }}</strong>
          </mat-error>
          <mat-error *ngIf="userForm.controls['password'].hasError('lowercase')">
            <strong>{{ 'SHARED.FORM_VALIDATORS.LOWERCASE' | translate }}</strong>
          </mat-error>
          <mat-error *ngIf="userForm.controls['password'].hasError('uppercase')">
            <strong>{{ 'SHARED.FORM_VALIDATORS.UPPERCASE' | translate }}</strong>
          </mat-error>
          <mat-error *ngIf="userForm.controls['password'].hasError('number')">
            <strong>{{ 'SHARED.FORM_VALIDATORS.HAS_NUMBER' | translate }}</strong>
          </mat-error>
          <mat-error *ngIf="userForm.controls['password'].hasError('specialCharacter')">
            <strong>{{ 'SHARED.FORM_VALIDATORS.HAS_SPECIAL_CHARS' | translate }}</strong>
          </mat-error>
        </mat-form-field>
      </mjx-input>

      <mjx-input [ngClass]="isProfile ? 'col-md-3' : 'col-md-4'"
                 *ngIf="isEdit && !isProfile">
        <mat-label>{{ 'USERS.FORM.USER_DATA.CONFIRM_PASSWORD' | translate }}</mat-label>
        <mat-form-field floatLabel="never" class="errors-form-field">
          <input matInput type="password" formControlName="confirmPassword">

          <mat-error *ngIf="userForm.controls['confirmPassword'].hasError('required')">
            <strong>{{ 'MANDATORY' | translate }}</strong>
          </mat-error>
          <mat-error *ngIf="userForm.controls['confirmPassword'].hasError('maxlength')">
            <strong>{{ 'MAXLENGTH' | translate }}</strong>
          </mat-error>
          <mat-error *ngIf="userForm.hasError('notSame') || userForm.controls['confirmPassword'].hasError('notSame')">
            <strong>{{ 'USERS.PASSWORD_CONFIRMATION' | translate }}</strong>
          </mat-error>
        </mat-form-field>
      </mjx-input>
    </form>
  </div>
</ng-template>

<ng-template #saveActions>
  <div class="actions">
    <ng-container *ngIf="!isEdit; else editActions">
      <button mat-button class="custom-button custom-secondary-button" (click)="navigateBack()">
        <span>{{ 'USERS.CANCEL_BTN' | translate }}</span>
      </button>

      <button mat-button class="custom-button" [disabled]="(inputDisabled || (isSaving$ | async))"
              (click)="doSave()">
        <span *ngIf="!(isSaving$ | async); else loadingSpinner">
          {{ 'USERS.SAVE_BTN' | translate }}
        </span>
      </button>
    </ng-container>

    <ng-template #editActions>
      <div *ngIf="(!isEditUserData && !isEditAccountData); else saveUserInfo" class="actions">
        <button mat-button class="custom-button custom-secondary-button" (click)="navigateBack()">
          <span *ngIf="!(isSaving$ | async) || !isProfile; else loadingSpinner">
            {{ 'USERS.CANCEL_BTN' | translate }}
          </span>
        </button>
      </div>

      <ng-template #saveUserInfo>
        <button mat-button class="custom-button custom-secondary-button" (click)="disableEdition()">
          <span>
            {{ 'SHARED.CANCEL_BTN' | translate }}
          </span>
        </button>

        <button *ngIf="!isEditAccountData && isEditUserData" mat-button class="custom-button edit-save-btn" [disabled]="(inputDisabled || (isSaving$ | async))" (click)="doSave()">
          <span *ngIf="!(isSaving$ | async); else loadingSpinner">
            {{ 'USERS.SAVE_EDIT_USER' | translate }}
          </span>
        </button>

        <button *ngIf="isEditAccountData && !isEditUserData && isProfile" mat-button class="custom-button edit-save-btn" [disabled]="(inputDisabled || (isSaving$ | async))" (click)="doSave()">
          <span *ngIf="!(isSaving$ | async); else loadingSpinner">
            {{ 'USERS.SAVE_EDIT_ACCOUNT' | translate }}
          </span>
        </button>
      </ng-template>
    </ng-template>
  </div>
</ng-template>

<ng-template #loadingDataSpinner>
  <mat-spinner diameter="60" mode="indeterminate" class="custom-spinner"></mat-spinner>
</ng-template>

<ng-template #loadingSpinner>
  <mat-spinner diameter="16" mode="indeterminate" class="custom-spinner"></mat-spinner>
</ng-template>
