import {environment} from "../../../environments/environment";

export class CompanyUtils {

  static get applicationName() {
    return environment.applicationHostName;
  }

  static get documentationUrl() {
    return environment.documentationUrl;
  }

  static get companyName() {
    return environment.applicationTitle;
  }

  static companyLogo(imageSize: 'small' | 'medium' | 'large' | 'extra-large', position: 'horizontal' | 'vertical') {
    return `/assets/images/logos/logo-${imageSize}-${position}.png`
  }

}
