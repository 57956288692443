import { Pipe, PipeTransform } from '@angular/core';
import { DocumentsEnum } from 'src/app/shared/modules/file-upload/enums/documents.enum';
import {licensesOptions} from "../utils/licenses-options";

@Pipe({
  name: 'translateDocumentType'
})
export class TranslateDocumentTypePipe implements PipeTransform {

  transform(docType: string): string {
    const docEnum: DocumentsEnum = DocumentsEnum[docType];

    return licensesOptions.find((el) => el.value === docEnum)?.translateKey ?? '';
  }

}
