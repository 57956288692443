import {Component, OnInit} from '@angular/core';
import {Observable} from "rxjs";
import {AccountModel} from "../../../../../core/models/account.model";
import {Select, Store} from "@ngxs/store";
import {AccountsState} from "../../../../../core/state/accounts/accounts.state";
import {GetAccountsData, SetCurrentAccount} from "../../../../../core/state/accounts/accounts.actions";

@Component({
  selector: 'mjx-select-account',
  templateUrl: './select-account.component.html',
  styleUrls: ['./select-account.component.scss']
})
export class SelectAccountComponent implements OnInit {
  @Select(AccountsState.getAccounts)
  accounts$: Observable<AccountModel[]>

  @Select(AccountsState.getCurrentAccount)
  currentAccount$: Observable<AccountModel>

  constructor(
    private store: Store
  ) { }

  ngOnInit(): void {
    this.store.dispatch(new GetAccountsData());
  }

  setCurrentAccount(account: AccountModel) {
    this.store.dispatch(new SetCurrentAccount(account));
  }

  compareAccounts(id1: string, id2: string): boolean {
    return id1 === id2;
  }
}
