import {Component, EventEmitter, Input, Output, TemplateRef} from '@angular/core';
import {UntypedFormControl} from "@angular/forms";
import {Observable, startWith, tap} from "rxjs";
import {map} from "rxjs/operators";
import {MatAutocompleteSelectedEvent} from "@angular/material/autocomplete";

@Component({
  selector: 'mjx-select',
  templateUrl: './mjx-select.component.html',
  styleUrls: ['./mjx-select.component.scss']
})
export class MjxSelectComponent<T> {
  @Input('initialValue') set value(v: T) {
    this.selectControl.setValue(v);
  }

  @Input() identifier: string;
  @Input() name: string;
  @Input() placeholder: string;
  @Input() filterProperty: string;
  @Input() loadingOptions: boolean;
  @Input() emptyOptions: boolean;
  @Input() emptyLabel: string;
  @Input() customOptionsTmpl: TemplateRef<any>;
  @Input() customDisplayTextFn: Function;
  @Input() disabled: boolean;
  @Input() readonly : boolean;
  @Input() skipSort: boolean;
  @Input() filterFn: (value: string, itens: any[], compareByProperty: string) => T[];
  @Input('options') set _options(options: T[]) {
    this.options = options;
    this.listenInput();
  }

  @Output() inputChanges: EventEmitter<string>;
  @Output() selectChanges: EventEmitter<MatAutocompleteSelectedEvent>;
  @Input() rounded = false;

  clearSelect(event: boolean) {
    if (event) {
      this.clearInput();
    }
   }

  filteredOptions$: Observable<T[]>;
  selectControl: UntypedFormControl;
  options: T[];
  private skipEmit = true;

  constructor() {
    this.setup();
  }

  displayFnWrapper() {
    return (value: T) => (this.customDisplayTextFn) ? this.customDisplayTextFn(value) : this.displayTextFn(value);
  }

  showAllOptions() {
    if (this.selectControl.value) {
      this.skipEmit = true;
      this.selectControl.setValue('', { emitModelToViewChange: false });
    }
  }

  clearInput() {
    this.selectControl.setValue('');
  }

  private displayTextFn(value: T) {
    if (value) {
      return value[this.filterProperty];
    }
  }

  private listenInput() {
    this.filteredOptions$ = this.selectControl
      .valueChanges
      .pipe(
        startWith(''),
        tap(text  => {
          if (!this.skipEmit) {
            this.inputChanges.emit(text)
          }
        }),
        map(text => {
          const data = (this.options) ? this.sortData([...this.options]) : [];

          if (this.filterFn && !this.skipEmit) {
            return this.filterFn(text, data, this.filterProperty)
          }

          this.skipEmit = false;
          return data;
        }),
      );
  }

  sortData(data: T[]): T[] {
    if (!data) return [];

    if (this.skipSort) {
      return data;
    }

    return data.sort((a, b) => {
      return this.compare(a[this.filterProperty], b[this.filterProperty], true);
    });
  }

  private compare(a: string | number, b: string | number, isAsc: boolean): number {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }

  private setup() {
    this.selectControl = new UntypedFormControl(null);
    this.inputChanges = new EventEmitter();
    this.selectChanges = new EventEmitter();
  }
}
