import { Injectable } from '@angular/core';
import {Buffer} from "buffer";
import {User} from "../models/user.model";

@Injectable({
  providedIn: 'root'
})
export class JWTokenService {

  constructor() { }

  decodeToken(token: string): User {
    if (!token) return null;

    const encodingType = 'base64';
    const TOKEN_DATA = 1;
    const base64Payload = token.split('.')[TOKEN_DATA];
    const payload = Buffer.from(base64Payload, encodingType);
    return JSON.parse(payload.toString());
  }
}
