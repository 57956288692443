import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { HttpClient } from '@angular/common/http';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Observable, map, startWith } from 'rxjs';

@Component({
  selector: 'mjx-versions-dialog',
  templateUrl: './versions-dialog.component.html',
  styleUrls: ['./versions-dialog.component.scss']
})
export class VersionsDialogComponent implements OnInit, OnDestroy {
  versions: any = {}
  versionShowed: any = {}
  hasVersionToShow: boolean = false
  latestDate = null
  isSmallScreen$: Observable<boolean>

  constructor(
    private httpClient: HttpClient,
    public breakpointObserver: BreakpointObserver
  ) {
    this.isSmallScreen$ = this.breakpointObserver.observe([Breakpoints.Small, Breakpoints.XSmall]).pipe(
      map(result => result.matches),
      startWith(false)
    )
  }

  ngOnInit(): void {
    this.httpClient.get('assets/utils/versions.json').subscribe(data => {
      this.versions = data;
      this.versions.versions.forEach(version => {
        const versionDate = new Date(version.date)
        if(!this.latestDate ||versionDate > this.latestDate){
          this.latestDate = versionDate;
          this.versionShowed = version
          this.hasVersionToShow = true
        }
      })
    });
  }

  ngOnDestroy(): void {
    this.versionShowed = {}
    this.versions = {}
    this.hasVersionToShow = false;
  }

  selectedVersion(version: any) {
    const contentCard: HTMLElement = document.querySelector('.content-card');
    contentCard?.scrollTo(0, 0);
    this.hasVersionToShow = true
    this.versionShowed = version
  }

}
