<mat-stepper class="custom-stepper" #matStepper linear="true">
  <ng-template matStepperIcon="edit" let-index="index">
    <span>{{index + 1}}</span>
  </ng-template>
  <mat-step [stepControl]="step1">
    <ng-template matStepLabel>{{ 'INTEGRATION.SECURITY.G_AUTH.STEP1.TITLE' | translate }}</ng-template>
    <div class="stepper-content">
      <span class="current-step">{{ 'INTEGRATION.SECURITY.G_AUTH.STEP1.STEP' | translate }}</span>
      <span class="step-message">{{ 'INTEGRATION.SECURITY.G_AUTH.STEP1.DOWNLOAD_INFO' | translate }}</span>
      <div class="content download-content">
        <img src="assets/images/in-google-play.png" (click)="openGAuthDownloadAndroid()">
        <img src="assets/images/in-app-store.png" (click)="openGAuthDownloadIOS()">
      </div>
    </div>
    <ng-container [ngTemplateOutlet]="firstStep"></ng-container>
  </mat-step>
  <mat-step [stepControl]="step2">
    <ng-template matStepLabel>{{ 'INTEGRATION.SECURITY.G_AUTH.STEP2.TITLE' | translate }}</ng-template>
    <div class="stepper-content">
      <span class="current-step">{{ 'INTEGRATION.SECURITY.G_AUTH.STEP2.STEP' | translate }}</span>
      <span class="step-message">{{ 'INTEGRATION.SECURITY.G_AUTH.STEP2.QRCODE_INFO' | translate }}</span>
      <div class="content qr-code-content">
        <img class="qr-code" [src]="setupQrCode$ | async">
        <div class="advise">
          <span>{{ 'INTEGRATION.SECURITY.G_AUTH.STEP2.ADVISE1' | translate }}</span>
          <span>{{ 'INTEGRATION.SECURITY.G_AUTH.STEP2.ADVISE2' | translate }}</span>
          <span class="auth-code">{{setupKey$ | async}}</span>
        </div>
      </div>
    </div>
    <ng-container [ngTemplateOutlet]="secondStep"></ng-container>
  </mat-step>
  <mat-step [stepControl]="step3">
    <ng-template matStepLabel>{{ 'INTEGRATION.SECURITY.G_AUTH.STEP3.TITLE' | translate }}</ng-template>
    <div class="stepper-content">
      <span class="current-step">{{ 'INTEGRATION.SECURITY.G_AUTH.STEP3.STEP' | translate }}</span>
      <span class="step-message">{{ 'INTEGRATION.SECURITY.G_AUTH.STEP3.KEY_INFO' | translate }}</span>
      <div class="content qr-code-content">
        <img class="qr-code" src="assets/images/mock-advise.png">
        <div class="advise">
          <span>{{ 'INTEGRATION.SECURITY.G_AUTH.STEP3.ADVISE1' | translate }}</span>
          <span>{{ 'INTEGRATION.SECURITY.G_AUTH.STEP3.ADVISE2' | translate }}</span>
          <span>{{ 'INTEGRATION.SECURITY.G_AUTH.STEP3.ADVISE3' | translate }}</span>
          <span class="auth-code">{{setupKey$ | async}}</span>
        </div>
      </div>
    </div>
    <ng-container [ngTemplateOutlet]="backAndNext"></ng-container>
  </mat-step>
  <mat-step [stepControl]="step4">
    <ng-template matStepLabel>{{ 'INTEGRATION.SECURITY.G_AUTH.STEP4.TITLE' | translate }}</ng-template>
    <div class="stepper-content">
      <span class="current-step">{{ 'INTEGRATION.SECURITY.G_AUTH.STEP4.STEP' | translate }}</span>
      <span class="step-message">{{ 'INTEGRATION.SECURITY.G_AUTH.STEP4.ACTIVATION_INFO' | translate }}</span>
      <div class="content">
        <mjx-input>
          <mat-label>{{ 'INTEGRATION.SECURITY.G_AUTH.STEP4.INPUT_TITLE' | translate }}</mat-label>
          <mat-form-field class="custom-input">
            <input matInput mask="000000" [formControl]="setupCode">
          </mat-form-field>
        </mjx-input>
      </div>
    </div>
    <ng-container [ngTemplateOutlet]="backAndFinish"></ng-container>
  </mat-step>

  <ng-template #firstStep>
    <div class="stepper-options mt-4">
      <button mat-button class="custom-button" (click)="cancel.emit(true)">
        {{ 'INTEGRATION.CANCEL_BTN' | translate }}
      </button>
      <button mat-button class="green-button" (click)="goToQrcode()" [disabled]="(isLoadingStep$ | async)">
        <span>{{ 'INTEGRATION.NEXT_BTN' | translate }}</span>
        <mat-icon>chevron_right</mat-icon>
      </button>
    </div>
  </ng-template>

  <ng-template #secondStep>
    <div class="stepper-options mt-4">
      <button mat-button matStepperPrevious class="custom-button">
        <mat-icon>chevron_left</mat-icon>
        <span>{{ 'INTEGRATION.BACK_BTN' | translate }}</span>
      </button>
      <button mat-button matStepperNext class="green-button">
        <span>{{ 'INTEGRATION.NEXT_BTN' | translate }}</span>
        <mat-icon>chevron_right</mat-icon>
      </button>
    </div>
  </ng-template>

  <ng-template #backAndNext>
    <div class="stepper-options mt-4">
      <button mat-button matStepperPrevious class="custom-button">
        <mat-icon>chevron_left</mat-icon>
        <span>{{ 'INTEGRATION.BACK_BTN' | translate }}</span>
      </button>
      <button mat-button matStepperNext class="green-button">
        <span>{{ 'INTEGRATION.NEXT_BTN' | translate }}</span>
        <mat-icon>chevron_right</mat-icon>
      </button>
    </div>
  </ng-template>

  <ng-template #backAndFinish>
    <div class="stepper-options mt-4">
      <button mat-button matStepperPrevious class="custom-button">
        <mat-icon>chevron_left</mat-icon>
        <span>{{ 'INTEGRATION.BACK_BTN' | translate }}</span>
      </button>
      <button mat-button class="green-button" (click)="confirmSetup()" [disabled]="(isLoadingStep$ | async) || setupCode.invalid">
        <span>{{ 'INTEGRATION.FINISH_BTN' | translate }}</span>
        <mat-icon>check</mat-icon>
      </button>
    </div>
  </ng-template>
</mat-stepper>
