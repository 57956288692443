import {Component, EventEmitter, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import {Actions, ActionType, ofActionSuccessful, Select, Store} from "@ngxs/store";
import {Observable, Subscription, take, tap} from "rxjs";
import {MatStepper} from "@angular/material/stepper";
import {UntypedFormControl, Validators} from "@angular/forms";
import {AccountSecurityState} from "../../states/account-security.state";
import {ConfirmAppSetup, GetAppSetupKey} from "../../states/app-security.actions";

@Component({
  selector: 'enable-auth',
  templateUrl: './enable-auth.component.html',
  styleUrls: ['./enable-auth.component.scss']
})
export class EnableAuthComponent implements OnInit, OnDestroy {
  @Output() cancel = new EventEmitter<boolean>();
  @Output() finish = new EventEmitter<boolean>();

  @ViewChild('matStepper') matStepper: MatStepper;

  @Select(AccountSecurityState.isLoadingStep)
  isLoadingStep$: Observable<boolean>;

  @Select(AccountSecurityState.appSetupKey)
  setupKey$: Observable<string>;
  @Select(AccountSecurityState.appSetupQrCode)
  setupQrCode$: Observable<string>;

  private pageSubscriptions: Map<number, Subscription> = new Map();
  setupCode = new UntypedFormControl(null, [Validators.required, Validators.minLength(6)]);

  step1 = new UntypedFormControl(null, Validators.required);
  step2 = new UntypedFormControl(null, Validators.required);
  step3 = new UntypedFormControl(null, Validators.required);
  step4 = new UntypedFormControl(null, Validators.required);

  constructor(
    private store: Store,
    private actions$: Actions
  ) {
  }

  ngOnInit(): void {
    this.listenAppSecurityActions(
      1,
      GetAppSetupKey,
      () => {
        this.matStepper
          .steps.forEach(step => step.stepControl.setValue(true));
        this.matStepper.next()
      });
    this.listenAppSecurityActions(
      2,
      ConfirmAppSetup,
      () => { this.finish.emit(true) });
  }

  ngOnDestroy() {
    this.unsubscribeAll();
  }

  openGAuthDownloadIOS() {
    window.open('https://apps.apple.com/us/app/google-authenticator/id388497605', '_blank');
  }

  openGAuthDownloadAndroid() {
    window.open('https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2&hl=pt_BR&gl=US', '_blank');
  }

  goToQrcode() {
    const key = this.store.selectSnapshot(AccountSecurityState.appSetupKey);

    if (key) {
      this.matStepper.next();
    } else {
      this.store.dispatch(new GetAppSetupKey());
    }
  }

  confirmSetup() {
    this.store.dispatch(new ConfirmAppSetup(this.setupCode.value));
  }

  private listenAppSecurityActions(id: number, action: ActionType, callback: Function) {
    const subs = this.actions$
      .pipe(
        ofActionSuccessful(action),
        take(1),
        tap(() => callback())
      ).subscribe();

    this.pageSubscriptions.set(id, subs);
  }

  private unsubscribeAll() {
    this.pageSubscriptions.forEach((subs) => {
      if (!subs.closed) {
        subs.unsubscribe();
      }
    });
  }
}
