import {Directive, EventEmitter, HostListener, Output} from '@angular/core';

@Directive({
  selector: '[mjxAutoClearSelect]'
})
export class AutoClearSelectDirective {
  @Output('autoclear') click: EventEmitter<boolean> = new EventEmitter<boolean>();
  private focusout = false;
  private mouseleave = false;
  private mouseout = false;

  @HostListener("mouseleave", ["$event"])
  public onMouseLeave(): void {
    this.mouseleave = true;
  }

  @HostListener("mouseout", ["$event"])
  public onMouseOut(): void {
    this.mouseleave = false;
  }

  @HostListener("focusout", ["$event"])
  public onFocusOut(): void {
    this.focusout = true;
  }

  @HostListener("click", ["$event"])
  public onClick(): void {
    const canClear = (this.focusout && this.mouseleave);

    this.click.emit(canClear);

    this.focusout = false;
  }
}
