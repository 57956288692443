import {RegexUtils} from "./regex.utils";
import {PixEnum} from "../enums/pix.enum";
import {cpfValidation} from "./cnpj-validator";

export function verifyPixKey(key: string): PixEnum {
  const trimmedKey = key?.trim();

  let  pixKeyType: PixEnum;
  if (RegexUtils.PIX_CPF.test(trimmedKey)) {
    pixKeyType = PixEnum.CPF;
  } else if (RegexUtils.PIX_CNPJ.test(trimmedKey)) {
    pixKeyType = PixEnum.CNPJ;
  } else if (RegexUtils.PIX_PHONE.test(trimmedKey)) {
    pixKeyType = PixEnum.PHONE;
  } else if (RegexUtils.PIX_EMAIL.test(trimmedKey)) {
    pixKeyType = PixEnum.EMAIL;
  } else if (RegexUtils.PIX_EVP.test(trimmedKey)) {
    pixKeyType = PixEnum.EVP;
  } else {

  }

  return pixKeyType;
}

export function validatePixKey(key: string): boolean {
  const trimmedKey = key?.trim();

  let isValid = false;
  if (RegexUtils.PIX_CPF.test(trimmedKey)) {
    isValid = cpfValidation(trimmedKey);
  } else if (RegexUtils.PIX_PHONE.test(trimmedKey)) {
    isValid = brazilianPhoneValidation(trimmedKey);
  } else if (RegexUtils.PIX_EMAIL.test(trimmedKey)) {
    isValid = true;
  } else if (RegexUtils.PIX_EVP.test(trimmedKey)) {
    isValid = true;
  }

  return isValid;
}

export function brazilianPhoneValidation(phoneNumber: string): boolean {
  let isValid = true;

  if (!phoneNumber.startsWith("+55")) {
    isValid = false;
  }

  const cleanPhoneNumber = phoneNumber.replace(/\D/g,'')


  if (cleanPhoneNumber.length !== 13) {
    isValid = false;
  }

  const ddd = cleanPhoneNumber.substring(2, 4);

  const validDDDs = [
    '11', '12', '13', '14', '15', '16', '17', '18', '19', '21', '22', '24', '27', '28',
    '31', '32', '33', '34', '35', '37', '38', '41', '42', '43', '44', '45', '46', '47',
    '48', '49', '51', '53', '54', '55', '61', '62', '63', '64', '65', '66', '67', '68',
    '69', '71', '73', '74', '75', '77', '79', '81', '82', '83', '84', '85', '86', '87',
    '88', '89', '91', '92', '93', '94', '95', '96', '97', '98', '99'
  ];

  if (!validDDDs.includes(ddd)) {
    isValid = false;
  }

  return isValid;
}

