import {Component} from '@angular/core';
import {isPartner} from "../../../../shared/utils/get-context";

@Component({
  selector: 'users-data-form',
  templateUrl: './users-data-form.component.html',
  styleUrls: ['./users-data-form.component.scss']
})
export class UsersDataFormComponent {
  isPartner = isPartner.valueOf();
}
