import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {QuickInfoComponent} from "./quick-info.component";
import {MaterialElementsModule} from "../../modules/material-elements/material-elements.module";
import {TranslateModule} from "@ngx-translate/core";



@NgModule({
  declarations: [
    QuickInfoComponent
  ],
  exports: [
    QuickInfoComponent
  ],
  imports: [
    CommonModule,
    MaterialElementsModule,
    TranslateModule
  ]
})
export class QuickInfoModule { }
