import { Pipe, PipeTransform } from '@angular/core';
import {Store} from "@ngxs/store";
import {PartnersState} from "../../modules/partners/state/partners.state";
import {PartnerModel} from "../../modules/partners/models/partner.model";

@Pipe({
  name: 'partnerNameById'
})
export class PartnerNameByIdPipe implements PipeTransform {
  constructor(private store: Store) { }
  transform(id: any): unknown {
    const partnersMap = {};
    const partners = this.store.selectSnapshot(PartnersState.getPartnersList);

    partners.forEach(part => {
      partnersMap[part.id] = part;
    });

    const selectedPartner: PartnerModel = partnersMap[id];
    return (selectedPartner) ? selectedPartner.companyName : id;
  }
}
