import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'hiddenCpf'
})
export class HiddenCpfPipe implements PipeTransform {

  transform(cpf: string): string {
    if (!cpf) return cpf;

    if (cpf.length > 11) {
      return  cpf;
    }

    let masked = cpf.replace(/\D/g, '');
    return masked.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '***.$2.$3-**');
  }

}
