import { Pipe, PipeTransform } from '@angular/core';
import {Store} from "@ngxs/store";
import {PartnersState} from "../../modules/partners/state/partners.state";
import {AccountModel} from "../../core/models/account.model";

@Pipe({
  name: 'accountNameById'
})
export class AccountNameByIdPipe implements PipeTransform {

  constructor(private store: Store) { }
  transform(id: any, type: 'id' | 'uid'): unknown {
    const mapAccounts = {};
    const accounts = this.store.selectSnapshot(PartnersState.getPartnerAccounts);

    accounts.forEach(acc => {
      mapAccounts[acc[type]] = acc;
    });

    const selectedAccount: AccountModel = mapAccounts[id];
    return (selectedAccount) ? `${selectedAccount.accountNumber} - ${selectedAccount.accountName}` : id;
  }
}
