import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MjxBreadcrumbComponent } from './mjx-breadcrumb.component';
import {TranslateModule} from "@ngx-translate/core";
import {RouterModule} from "@angular/router";



@NgModule({
  declarations: [
    MjxBreadcrumbComponent
  ],
  exports: [
    MjxBreadcrumbComponent
  ],
  imports: [
    CommonModule,
    TranslateModule,
    RouterModule
  ]
})
export class MjxBreadcrumbModule { }
