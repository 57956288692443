export enum PermissionEnum {
  View = "VIEW",
  List = "LIST",
  Filter = "FILTER",
  Detail = "DETAIL",
  Summary = "SUMMARY",
  Notify = "NOTIFY",
  UploadComprov = "UPLOAD_COMPROV",
  Review = "REVIEW",
  Pay = "PAY",
  ListPartner = "LIST_PARTNER",
  ListCorporate = "LIST_CORPORATE",
  CreatePartner = "CREATE_PARTNER",
  CreateCorporate = "CREATE_CORPORATE",
  Edit = "EDIT",
  Remove = "REMOVE",
  ResetPass = "RESET_PASS",
  Create = "CREATE",
  Revoke = "REVOKE",
  UploadDoc = "UPLOAD_DOC",
  UploadInvoice = "UPLOAD_INVOICE",
  Delete = "DELETE",
  UploadRegistDoc = "UPLOAD_REGIST_DOC",
}