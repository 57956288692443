import {Injectable, NgZone} from "@angular/core";
import {Action, Selector, State, StateContext} from "@ngxs/store";
import {ChangeEnablingMfa, ClearSession, EnableMfa, GetCurrentUser, SetLoggedUser, SetToken} from "./session.actions";
import {SessionService} from "../../services/session.service";
import {CurrentUser} from "../../models/user.model";
import {finalize, tap} from "rxjs";
import { AccessRolesEnum } from 'src/app/shared/enums/access-roles.enum';
import { RoutesEnum } from 'src/app/shared/enums/routes.enum';
import { Router } from '@angular/router';
import {RefreshTokenService} from "../../services/refresh-token.service";
import {isPartner} from "../../../shared/utils/get-context";

export class SessionStateModel {
  token: string;
  currentUser: CurrentUser;
  loadingUser: boolean;
  enablingMfa: boolean;
  username: string;
}

@State<SessionStateModel>({
  name: 'session',
  defaults: {
    currentUser: null,
    token: null,
    loadingUser: false,
    enablingMfa: false,
    username: null
  }
})
@Injectable()
export class SessionState {
  constructor(
    private sessionService: SessionService,
    private ngZone: NgZone,
    private router: Router,
    private refreshTokenService: RefreshTokenService
  ) {}

  @Selector()
  static getCurrentUser(state: Partial<SessionStateModel>) {
    return state.currentUser;
  }

  @Selector()
  static getUsername(state: Partial<SessionStateModel>) {
    return state.username;
  }

  @Selector()
  static getToken(state: Partial<SessionStateModel>) {
    return state.token;
  }

  @Selector()
  static getLoadingUserState(state: Partial<SessionStateModel>) {
    return state.loadingUser;
  }

  @Selector()
  static getCurrentUserPermissions(state: Partial<SessionStateModel>) {
    return state.currentUser?.permissions;
  }

  @Selector()
  static enablingMfa(state: Partial<SessionStateModel>) {
    return state.enablingMfa;
  }

  @Action(GetCurrentUser)
  getCurrentUser(
    stateCtx: StateContext<SessionStateModel>
  ) {
    stateCtx.patchState({
      loadingUser: true
    });

    const currentUser = stateCtx.getState().currentUser;

    return this.sessionService.getCurrentUser()
      .pipe(
        tap((res) => {
          if (!isPartner) {
            this.refreshTokenService.startListen();
          }

          const tkUser = this.sessionService.getUser();
          if (res.data) {
            stateCtx.patchState({
              currentUser: { ...res.data, permissions: this.sessionService.currentUseRoles(), cid: tkUser?.cid }
            });

            if (!currentUser) {
              const isPartnerMember = this.sessionService.userHasRole(AccessRolesEnum.PartnerMember);
              const isFinanceMember = this.sessionService.userHasRole(AccessRolesEnum.PartnerMemberFin);

              let homeRoute = RoutesEnum.Home;

              if (isPartnerMember) {
                homeRoute = RoutesEnum.Sales;
              } else if (isFinanceMember) {
                homeRoute = RoutesEnum.Receivers;
              }

              this.ngZone.run(() => this.router.navigateByUrl(`/${homeRoute}`))
            }
          }
        }),
        finalize(() => {
          stateCtx.patchState({
            loadingUser: false
          })
        })
      );
  }

  @Action(SetLoggedUser)
  setLoggedUser(
    stateCtx: StateContext<SessionStateModel>,
    actions: SetLoggedUser
  ) {
    stateCtx.patchState({
      token: actions.token,
      currentUser: null
    });
  }

  @Action(SetToken)
  setToken(
    stateCtx: StateContext<SessionStateModel>,
    actions: SetToken
  ) {
    stateCtx.patchState({
      token: actions.token
    });
  }

  @Action(ClearSession)
  clearSession(
    stateCtx: StateContext<SessionStateModel>
  ) {
    this.refreshTokenService.stopListen();
    stateCtx.setState({
      token: null,
      currentUser: null,
      loadingUser: false,
      enablingMfa: false,
      username: null
    });
  }

  @Action(EnableMfa)
  enableMfa(
    stateCtx: StateContext<SessionStateModel>,
    actions: EnableMfa
  ) {
    stateCtx.patchState({ enablingMfa: actions.enable, username: actions.username })
  }

  @Action(ChangeEnablingMfa)
  changeEnablingMfa(
    stateCtx: StateContext<SessionStateModel>,
    actions: ChangeEnablingMfa
  ) {
    stateCtx.patchState({ enablingMfa: actions.state })
  }
}
