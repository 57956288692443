import { Component, OnInit } from '@angular/core';
import {Observable, of} from "rxjs";
import {MjxTableEventsModel} from "../../../../../shared/modules/mjx-table/models/mjx-table-events.model";
import {MjxTableColumnDefinition} from "../../../../../shared/modules/mjx-table/models/mjx-table.model";
import {MjxTableDataSource} from "../../../../../shared/modules/mjx-table/mjx-table-datasource";
import {ProfilesPermissionsTableUtilsService} from "../../../utils/profiles-permissions-table.utils";

@Component({
  selector: 'mjx-list-profiles-permissions',
  templateUrl: './list-profiles-permissions.component.html',
  styleUrls: ['./list-profiles-permissions.component.scss']
})
export class ListProfilesPermissionsComponent implements OnInit {
  isLoading$: Observable<boolean>;
  isEmpty$: Observable<boolean> = of(true);
  dataSource$: Observable<MjxTableDataSource<any>>;
  tableColumns: string[];
  tableColumnsDef: MjxTableColumnDefinition[]

  constructor(
    private profilePermissionsTableUtils: ProfilesPermissionsTableUtilsService
  ) {
    this.tableColumns = this.profilePermissionsTableUtils.getColumns();
    this.tableColumnsDef = this.profilePermissionsTableUtils.getColumnsDef();
  }

  ngOnInit(): void {
  }

  listenTableEvents(event: MjxTableEventsModel) {

  }
}
