import {HttpClient} from "@angular/common/http";
import {IModuleTranslationOptions, ModuleTranslateLoader} from "@larscom/ngx-translate-module-loader";
import {isPartner} from "../../shared/utils/get-context";

export function CustomTranslateLoaderFactory(http: HttpClient): ModuleTranslateLoader {
  const baseTranslateUrl = '../../../assets/i18n';

  const partnersOptions: IModuleTranslationOptions = {
    modules:  [
      { baseTranslateUrl },
      { baseTranslateUrl, moduleName: 'login' },
      { baseTranslateUrl, moduleName: 'info' },
      { baseTranslateUrl, moduleName: 'shared' },
      { baseTranslateUrl, moduleName: 'errors' },
      { baseTranslateUrl, moduleName: 'reset' },
    ]
  }

  const mainOptions: IModuleTranslationOptions = {
    modules: [
      { baseTranslateUrl },
      { baseTranslateUrl, moduleName: 'login' },
      { baseTranslateUrl, moduleName: 'info' },
      { baseTranslateUrl, moduleName: 'shared' },
      { baseTranslateUrl, moduleName: 'errors' },
      { baseTranslateUrl, moduleName: 'integration' },
    ]
  }

  const options = (isPartner) ? partnersOptions : mainOptions;

  return new ModuleTranslateLoader(http, options);
}
