import {Injectable} from "@angular/core";
import {Action, State, StateContext} from "@ngxs/store";
import {SnackbarService} from "../../services/snackbar.service";
import {ThreatError, ThreatMultipleErrors} from "./errors.actions";
import {errorsReasonMap} from "../../utils/errors-reason-map";

@State<any>({
  name: 'errors'
})
@Injectable()
export class ErrorsState {
  constructor(
    private snackbar: SnackbarService
  ) { }

  @Action(ThreatError)
  threatError(stateCtx: StateContext<any>, actions: ThreatError) {
    const reason = errorsReasonMap[actions.reason] ?? actions.reason;

    this.snackbar.error((!!reason) ? reason : 'ERRORS.UNEXPECTED_ERROR');
  }

  @Action(ThreatMultipleErrors)
  threatMultipleErrors(stateCtx: StateContext<any>, actions: ThreatMultipleErrors) {
    const reasons: string[] = [];

    actions.items.forEach(item => {
      reasons.push(`* ${item.message}`);
    })

    const composedErrors = reasons.join('\n');

    this.snackbar.error(composedErrors);
  }
}
