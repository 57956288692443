<div>
  <mjx-filter [filterName]="filterName" [fieldsToDisplay]="fieldsToDisplay" [disableRule]="disableRule"
              [formGroup]="filterForm" (filter)="doFilter()" (clear)="clearFilter()">

    <ng-template mjxFilterField fieldName="partners" *hasPermission="{ roles: rolesEnum.CORPORATES_ALL }">
      <ng-container [ngTemplateOutlet]="partnersRef"></ng-container>
    </ng-template>

    <ng-template mjxFilterField fieldName="username">
      <ng-container [ngTemplateOutlet]="usernameRef"></ng-container>
    </ng-template>

    <ng-template mjxFilterField fieldName="fullName">
      <ng-container [ngTemplateOutlet]="fullNameRef"></ng-container>
    </ng-template>

    <ng-template mjxFilterField fieldName="email">
      <ng-container [ngTemplateOutlet]="emailRef"></ng-container>
    </ng-template>

    <ng-template mjxFilterField fieldName="userTypes" *hasPermission="{ roles: rolesEnum.CORPORATES_ALL }">
      <ng-container [ngTemplateOutlet]="userTypesRef"></ng-container>
    </ng-template>
  </mjx-filter>
</div>

<ng-template #fullNameRef>
  <form [formGroup]="filterForm" class="filter-field col-12 col-sm-4 col-md-3 col-lg-2">
    <mjx-input [rounded]="true">
      <mat-form-field floatLabel="never">
        <input matInput type="text" formControlName="name" autocomplete="off"
               [placeholder]="'USERS.TABLE.FULL_NAME' | translate">
      </mat-form-field>
    </mjx-input>
  </form>
</ng-template>

<ng-template #emailRef>
  <form [formGroup]="filterForm" class="filter-field col-12 col-sm-4 col-md-3 col-lg-2">
    <mjx-input [rounded]="true">
      <mat-form-field floatLabel="never">
        <input matInput type="text" formControlName="email" autocomplete="off"
               [placeholder]="'USERS.TABLE.EMAIL' | translate">
      </mat-form-field>
    </mjx-input>
  </form>
</ng-template>

<ng-template #usernameRef>
  <form [formGroup]="filterForm" class="filter-field col-12 col-sm-4 col-md-3 col-lg-2">
    <mjx-input [rounded]="true">
      <mat-form-field floatLabel="never">
        <input matInput type="text" formControlName="username" autocomplete="off"
               [placeholder]="'USERS.TABLE.USERNAME' | translate">
      </mat-form-field>
    </mjx-input>
  </form>
</ng-template>

<ng-template #userTypesRef>
  <form [formGroup]="filterForm" class="filter-field col-12 col-sm-4 col-md-3 col-lg-2">
    <mjx-input [rounded]="true">
      <mat-form-field floatLabel="never">
        <mat-select formControlName="userType" [placeholder]="'USERS.FILTER.ALL_TYPES' | translate">
          <mat-option [value]="null">{{'USERS.FILTER.ALL' | translate}}</mat-option>
          <mat-option [value]="user.user" *ngFor="let user of userTypeOptions">
            {{ user.translateKey | translate }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </mjx-input>
  </form>
</ng-template>

<ng-template #partnersRef>
  <mjx-select [rounded]="true"
    *ngIf="!this.isPartner"
    class="filter-field col-12 col-sm-4 col-md-3 col-lg-2"
    placeholder="USERS.FILTER.ALL_PARTNERS"
    filterProperty="companyName"
    [loadingOptions]="(loadingPartner$ | async)"
    [options]="(partners$ | async)"
    [filterFn]="filterPartners"
    (inputChanges)="listenPartnerInput($event)"
    (selectChanges)="setPartner($event)"
  ></mjx-select>
</ng-template>
