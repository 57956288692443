import { AES, enc } from 'crypto-js';
import {environment} from "../../../environments/environment";

export function encrypt(obj: any): string {
  return AES.encrypt(JSON.stringify(obj), environment.version).toString();
}

export function decrypt(ciphertext) {
  if (ciphertext) {
    const bytes = AES?.decrypt(ciphertext, environment.version);
    return JSON.parse(bytes.toString(enc.Utf8));
  }
}
