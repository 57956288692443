export abstract class VersionedStorage {
  readonly version: string;
  readonly storageKey: string;

  protected verifyStorageVersion() {
    const storage = JSON.parse(localStorage.getItem(this.storageKey)) || {};
    if (storage?.version && storage?.version !== this.version) {
      localStorage.setItem(this.storageKey, JSON.stringify({ version: this.version }));
    } else if (!storage?.version) {
      storage['version'] = this.version;
      localStorage.setItem(this.storageKey, JSON.stringify(storage));
    }
  }
}
