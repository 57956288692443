import { Pipe, PipeTransform } from '@angular/core';
import {MjxTableDataSource} from "../modules/mjx-table/mjx-table-datasource";

@Pipe({
  name: 'datasource'
})
export class DatasourcePipe implements PipeTransform {

  transform(datasource: MjxTableDataSource<any>): MjxTableDataSource<any> {
    return new MjxTableDataSource<any>(datasource?.data, datasource?.total, datasource?.getSortedData ?? null);
  }

}
