import { Pipe, PipeTransform } from '@angular/core';
import {CurrencyPipe} from "@angular/common";
import {defaultCurrency} from "../utils/language-utils";

@Pipe({
  name: 'mjxCurrency'
})
export class MjxCurrencyPipe implements PipeTransform {
  constructor(
    private currencyPipe: CurrencyPipe
  ) { }

  transform(value: any, showSymbol = true): string {
    value = value ?? 0;
    const transformed = this.currencyPipe.transform(value, defaultCurrency(), 'symbol', '1.2-2');

    const withoutSpaceRegex = new RegExp(/(R+\$)+[0-9]/);
    if (withoutSpaceRegex.test(transformed)) {
      let replaced = transformed;

      if (showSymbol) {
        replaced = transformed.replace('R$', 'R$\u00A0');
      } else {
        replaced = transformed.replace('R$', '');
      }

      return replaced;
    }

    if (showSymbol) {
      return transformed
    } else {
      return transformed.replace('R$\u00A0', '');
    }
  }

}
