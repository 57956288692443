import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Store } from '@ngxs/store';
import { Subscription } from 'rxjs';
import { SyncTransaction } from '../../states/transactions/transactions.actions';

@Component({
  selector: 'mjx-sync-confirm-dialog',
  templateUrl: './sync-confirm-dialog.component.html',
  styleUrls: ['./sync-confirm-dialog.component.scss']
})
export class SyncConfirmDialogComponent implements OnInit {
  cancelBatchSync = false;
  syncSubscriptions: Subscription[] = [];
  syncData = []
  resolvedCount = 0;
  allSyncsCompleted = false;
  batchSyncs = false;

  constructor(
    private store: Store,
    @Inject(MAT_DIALOG_DATA) public details: any
  ) { }

  ngOnInit() {
    this.syncData = this.details.data
  }

  async confirmBatchSyncs() {
    this.details.function.clear()
    this.batchSyncs = true;
    for (const transaction of  this.syncData) {
      if (!this.cancelBatchSync) {
        try {
          this.resolvedCount++
          await this.syncTransaction(transaction.id);
        } catch (error) {
          console.error(`Error invoking webhook for transaction ${transaction.id}: ${error}`);
        }
      } else {
        break;
      }
    }
    if(this.resolvedCount === this.syncData.length){
      this.allSyncsCompleted = true;
    }
  }

  private syncTransaction(transactionId: any): Promise<any> {
    return new Promise((resolve, reject) => {
      const subscription = this.store.dispatch(new SyncTransaction(transactionId))
        .subscribe({
          next: (transaction) => {
            subscription.unsubscribe();
            resolve(transaction);
          },
          error: (error) => {
            subscription.unsubscribe();
            reject(error);
          }
        });
    });
  }

  cancelBatchSyncFunction() {
    this.cancelBatchSync = true;
    this.batchSyncs = false
    this.syncSubscriptions.forEach(sub => sub.unsubscribe());
  }
}
