import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MjxFilterComponent } from './mjx-filter.component';
import { MjxFilterFieldDirective } from './directives/mjx-filter-field.directive';
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {TranslateModule} from "@ngx-translate/core";
import {MaterialElementsModule} from "../material-elements/material-elements.module";

@NgModule({
  declarations: [
    MjxFilterComponent,
    MjxFilterFieldDirective
  ],
  exports: [
    MjxFilterComponent,
    MjxFilterFieldDirective
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    TranslateModule,
    MaterialElementsModule,
    FormsModule
  ]
})
export class MjxFilterModule { }
