import { Credentials } from '../../models/credentials.model';
import {MFA} from "../../models/user.model";

export class Authenticate {
  static readonly type = '[Auth] Authenticate';
  constructor(public payload: Credentials) { }
}

export class AuthenticateWithMFA {
  static readonly type = '[Auth] Authenticate With MFA';
  constructor(public mfaCodes: MFA) { }
}

export class Logout {
  static readonly type = '[Auth] Logout';
}

export class CheckSession {
  static readonly type = '[Auth] Check Session'
}

export class VerifyTokenValidity {
  static readonly type = '[Auth] Verify Token Validity';
}

export class ClearMFA {
  static readonly type = '[Auth] Clear MFA';
}

export class SetMFA {
  static readonly type = '[Auth] Set MFA';
  constructor(public mfa: MFA) { }
}

export class RefreshToken {
  static readonly type = '[Auth] Refresh Token';
  constructor(public token: string) { }
}
