export enum DocumentsEnum {
  CertificateOfCompanyIncorporation = 'CertificateOfCompanyIncorporation',
  CertificateOfGoodStanding = 'CertificateOfGoodStanding',
  CertificateOfIncumbency = 'CertificateOfIncumbency',
  CorporateStructureChart = 'CorporateStructureChart',
  LatestCompanyAssociationAddendum = 'LatestCompanyAssociationAddendum',
  CopyOfBankAccount = 'CopyOfBankAccount',
  OfficialAuthorizationOrLicense = 'OfficialAuthorizationOrLicense',
  StocksRegistration = 'StocksRegistration',
  FinancialStatement = 'FinancialStatement',
  GameLicense = 'GameLicense',
  BankingChannel = 'BankingChannel',
  PassportOfPersonInCharge = 'PassportOfPersonInCharge',
  SignedDueDiligence = 'SignedDueDiligence',
  PSPContract = 'PSPContract',
  SocialContract = 'SocialContract',
  Invoice = 'Invoice',
  Custom = 'Custom'
}
