import {Component, Input, TemplateRef} from '@angular/core';

@Component({
  selector: 'mjx-section',
  templateUrl: './mjx-section.component.html',
  styleUrls: ['./mjx-section.component.scss']
})
export class MjxSectionComponent {
  @Input() name: string;
  @Input() content: TemplateRef<any>

  get sectionContentClass(): any {
    return { 'section-content': !!(this.name) };
  }

}
