<div class="line-container" #lineContainer [ngStyle]="linePosition">
  <div class="tabs-line" [ngStyle]="{ 'width': lineWidth }"></div>
  <ng-container *ngIf="customOption" [ngTemplateOutlet]="customOption"></ng-container>
</div>
<div class="option-container">
  <ng-container *ngIf="customOption" [ngTemplateOutlet]="customOption"></ng-container>
</div>
<nav class="tabs-container">
  <mat-tab-group (tabPaginationChange)="threatPagination($event)"
    class="tabs-group"(selectedTabChange)="changeTab($event)" mjxMatTabHeaderClick [currentTabIndex]="idx">
    <mat-tab *ngFor="let tab of tabs" [disabled]="tab.disabled">
      <ng-template mat-tab-label>
        <mat-icon class="tab-icon">{{tab.icon}}</mat-icon>
        {{ tab.title | translate }}
      </ng-template>
        <ng-container [ngTemplateOutlet]="tab.template">
        </ng-container>
    </mat-tab>
  </mat-tab-group>
</nav>
