import {Injectable} from "@angular/core";
import {Action, NgxsOnInit, Selector, State, StateContext} from "@ngxs/store";
import {AccountsStateModel, defaultAccountState} from "./models/accounts-state.model";
import {
  ClearLinkedAccounts,
  GetAccountMetrics,
  GetAccountOperationalBalance,
  GetAccountOperationalSummary,
  GetAccountsData,
  GetCorporateAccountOperationalSummary,
  GetLinkedAccounts,
  SetAccountsForSelection,
  SetCurrentAccount
} from "./accounts.actions";
import {catchError, finalize, tap, throwError} from "rxjs";
import {CommonResponse} from "../../../shared/models/response.model";
import {AccountModel, OperationMetric, OperationSummary} from "../../models/account.model";
import {AccountsFilterModel} from "./models/accounts-filter.model";
import { MjxTableDataSource } from 'src/app/shared/modules/mjx-table/mjx-table-datasource';
import { SnackbarService } from 'src/app/shared/services/snackbar.service';
import {PartnersApiService} from "../../../shared/services/partners/partners-api.service";
import {CorporateApiService} from "../../../shared/services/corporate/corporate-api.service";
import {AccountsService} from "../../services/accounts/accounts.service";
import {orderPartnersAccountsByImplicitID} from "../../../shared/utils/order-partner-accounts";
import * as mockData from 'mocks/mocks/home/home.js'
import moment from "moment";

@State<AccountsStateModel>({
  name: 'accounts',
  defaults: defaultAccountState
})
@Injectable()
export class AccountsState implements NgxsOnInit {
  @Selector()
  static getLoadingState(state: Partial<AccountsStateModel>): boolean {
    return state.isLoading;
  }
  @Selector()
  static getCurrentAccount(state: Partial<AccountsStateModel>): AccountModel {
    return state?.currentAccount;
  }
  @Selector()
  static getAccountUserName(state: Partial<AccountsStateModel>): string {
    const currentAccount = state.currentAccount
    return state.userName ?? `${currentAccount.accountName} - ${state.currentAccount.accountNumber}`;
  }
  @Selector()
  static getAccountUserType(state: Partial<AccountsStateModel>): string {
    return state?.userType;
  }

  @Selector()
  static getAccounts(state: Partial<AccountsStateModel>): AccountModel[] {
    return state?.accounts;
  }

  @Selector()
  static getMetrics(state: Partial<AccountsStateModel>): OperationMetric {
    return state?.metrics;
  }


  @Selector()
  static getFilteredAccounts(state: Partial<AccountsStateModel>): AccountModel[] {
    return state?.filteredAccounts;
  }

  @Selector()
  static isEmpty(state: Partial<AccountsStateModel>): boolean {
    return state?.isEmpty;
  }

  @Selector()
  static accountSummary(state: Partial<AccountsStateModel>): string {
    return state?.summary;
  }

  @Selector()
  static isLoadingSummary(state: Partial<AccountsStateModel>): boolean {
    return state?.isLoadingSummary;
  }

  @Selector()
  static getLinkedAccountsDataSource(state: Partial<AccountsStateModel>): MjxTableDataSource<AccountModel> {
    return state?.linkedAccounts;
  }

  @Selector()
  static isLoadingLinkedAccounts(state: Partial<AccountsStateModel>): boolean {
    return state?.isLoadingLinkedAccounts;
  }

  @Selector()
  static accountDebits(state: Partial<AccountsStateModel>): OperationSummary {
    return state?.debits;
  }

  @Selector()
  static accountDebitsTable(state: Partial<AccountsStateModel>) {
    return state?.debitsTable;
  }

  @Selector()
  static accountCredits(state: Partial<AccountsStateModel>): OperationSummary {
    return state?.credits;
  }

  @Selector()
  static accountCreditsTable(state: Partial<AccountsStateModel>) {
    return state?.creditsTable;
  }

  @Selector()
  static creditSum(state: Partial<AccountsStateModel>): string {
    return state?.creditSum;
  }

  @Selector()
  static debitSum(state: Partial<AccountsStateModel>): string {
    return state?.debitSum;
  }

  @Selector()
  static isLoadingTransactionsSummary(state: Partial<AccountsStateModel>): boolean {
    return state?.isLoadingTransactionsSummary;
  }

  @Selector()
  static totalCountSummary(state: Partial<AccountsStateModel>): string {
    return state?.totalCount;
  }

  @Selector()
  static chartAccountOptions(state: Partial<AccountsStateModel>): string {
    return state?.chartAccountOptions;
  }

  @Selector()
  static creditCountSummary(state: Partial<AccountsStateModel>): string {
    return state?.creditCount;
  }

  @Selector()
  static debitCountSummary(state: Partial<AccountsStateModel>): string {
    return state?.debitCount;
  }

  @Selector()
  static convertedCountSummary(state: Partial<AccountsStateModel>): string {
    return state?.convertedCount;
  }

  @Selector()
  static executedCountSummary(state: Partial<AccountsStateModel>): string {
    return state?.executedCount;
  }

  @Selector()
  static isLoadingBalance(state: Partial<AccountsStateModel>): boolean {
    return state?.isLoadingBalance;
  }

  constructor(
    private accountsService: AccountsService,
    private snackbar: SnackbarService,
    private partApiService: PartnersApiService,
    private corpApiService: CorporateApiService
  ) {}

  ngxsOnInit(ctx?: StateContext<any>): any {
    ctx.setState(defaultAccountState);
  }

  @Action(SetCurrentAccount)
  setCurrentAccount(
    { patchState}: StateContext<AccountsStateModel>,
    actions: SetCurrentAccount
  ) {
    patchState({
      currentAccount: actions.account,
      userName: actions.account?.managerFullName,
      userType: actions.account?.customerType
    });
  }

  @Action(GetAccountsData)
  getAccountsData(
    { patchState, dispatch, getState }: StateContext<AccountsStateModel>,
    actions: GetAccountsData) {
    const currentAccount = getState()?.currentAccount;

    patchState({
      isLoading: true,
      isEmpty: false,
      currentAccount: null
    });
    let whereObj: Partial<AccountsFilterModel> = {};

    if (actions.partnerId) {
      whereObj['refCustomer'] = actions.partnerId;
    }

    return this.accountsService
      .getAccounts(whereObj)
      .pipe(
        tap((res: CommonResponse<AccountModel>) => {
          let accounts: AccountModel[];

          const data: any = res.data;

          if (data?.items) {
            accounts = data.items;
          } else {
            accounts = data;
          }

          const firstAccount = accounts[0];
          const matchList = (accounts.filter(item => (item?.id === (currentAccount?.id)) || item?.uid === (currentAccount?.uid)))

          if (!currentAccount || matchList.length === 0) {
            dispatch(new SetCurrentAccount(firstAccount));
          } else {
            dispatch(new SetCurrentAccount(currentAccount));
          }

          patchState({
            filteredAccounts: orderPartnersAccountsByImplicitID(accounts),
            accounts: orderPartnersAccountsByImplicitID(accounts),
            isEmpty: accounts.length === 0
          })
        }),
        catchError((err) => {
          patchState({
            filteredAccounts: [],
            accounts: []
          });

          return throwError(err);
        }),
        finalize(() => patchState({ isLoading: false }))
      )
  }

  @Action(SetAccountsForSelection)
  setAccountsForSelection(state: StateContext<AccountsStateModel>) {
      let accountsMap = {};
      const linkedAccounts = state.getState()?.linkedAccounts?.data;

      if (linkedAccounts) {
        state.patchState({
          isLoading: true
        });

        for (let linAcc of linkedAccounts) {
          accountsMap[linAcc.uid] = true;
        }

        const accounts = state.getState()?.accounts;

        state.patchState({
          filteredAccounts: accounts.filter((acc) => !accountsMap[acc.uid]),
          isLoading: false
        })
      }
  }

  @Action(ClearLinkedAccounts)
  clearLinkedAccounts(state: StateContext<AccountsStateModel>) {
    state.patchState({
      isLoadingLinkedAccounts: false,
      linkedAccounts: new MjxTableDataSource([], 0)
    });
  }

  @Action(GetLinkedAccounts)
  getLinkedAccounts(
    state: StateContext<AccountsStateModel>,
    actions: GetLinkedAccounts) {

    state.patchState({
      isLoadingLinkedAccounts: true,
      linkedAccounts: new MjxTableDataSource([], 0)
    });

    return this.accountsService
      .getLinkedAccounts(actions.refUser)
      .pipe(
        tap((res: CommonResponse<AccountModel[]>) => {
          state.patchState({
            linkedAccounts: new MjxTableDataSource(res.data, res.data.length)
          });
        }),
        catchError((err) => {
          this.snackbar.error('USERS.FAIL_MSG_ACCOUNTS_LIST');

          return throwError(err);
        }),
        finalize(() => state.patchState({ isLoadingLinkedAccounts: false }))
      )
  }

  @Action(GetAccountOperationalSummary)
  getAccountOperationalSummary(
    state: StateContext<AccountsStateModel>,
    actions: GetAccountOperationalSummary
  ) {
    state.patchState({
      isLoadingSummary: true,
      summary: defaultAccountState.summary
    });

    if (!actions.filter?.accountId && actions.accountId) {
      actions.filter.accountId = actions.accountId;
    }

    return this.partApiService.getOperationalSummary(actions.filter)
      .pipe(
        tap(res => {
          this.totalOperations(res.data.credits)
          this.totalOperations(res.data.debits)
          state.patchState({
            metrics: res.data?.metrics ?? this.defaultOperationMetric(),
            debits: res.data.debits ?? this.defaultOperationSummary(),
            credits: res.data.credits ?? this.defaultOperationSummary(),
            creditsTable: new MjxTableDataSource(res.data.credits.items, res.data.credits.items.length),
            debitsTable: new MjxTableDataSource(res.data.debits.items, res.data.debits.items.length)
          });
        }),
        catchError((err) => {
          state.patchState({
            metrics: this.defaultOperationMetric(),
            debits: this.defaultOperationSummary(),
            credits: this.defaultOperationSummary()
          })
          return throwError(err);
        }),
        finalize(() => {
          state.patchState({
            isLoadingSummary: false
          });
        })
      );
  }

  @Action(GetCorporateAccountOperationalSummary)
  getCorporateAccountOperationalSummary(
    state: StateContext<AccountsStateModel>,
    actions: GetCorporateAccountOperationalSummary
  ) {
    state.patchState({
      isLoadingSummary: true,
      summary: defaultAccountState.summary
    });

    if (!actions.filter?.accountId && actions.accountId) {
      actions.filter.accountId = actions.accountId;
    }

    return this.corpApiService.getOperationalSummary(actions.filter)
      .pipe(
        tap(res => {
          this.totalOperations(res.data.credits)
          this.totalOperations(res.data.debits)
          state.patchState({
            metrics: res.data?.metrics ?? this.defaultOperationMetric(),
            debits: res.data.debits ?? this.defaultOperationSummary(),
            credits: res.data.credits ?? this.defaultOperationSummary(),
            creditsTable: new MjxTableDataSource(res.data.credits.items, res.data.credits.items.length),
            debitsTable: new MjxTableDataSource(res.data.debits.items, res.data.debits.items.length)
          });
        }),
        catchError((err) => {
          state.patchState({
            metrics: this.defaultOperationMetric(),
            debits: this.defaultOperationSummary(),
            credits: this.defaultOperationSummary()
          })
          return throwError(err);
        }),
        finalize(() => {
          state.patchState({
            isLoadingSummary: false
          });
        })
      );
  }

  @Action(GetAccountOperationalBalance)
  getAccountOperationalBalance(
    state: StateContext<AccountsStateModel>,
    actions: GetAccountOperationalBalance
  ) {
    state.patchState({
      isLoadingBalance: true,
      summary: defaultAccountState.summary
    });

    return this.accountsService.getTransactionsBalance(actions.accountId)
      .pipe(
        tap(res => {
          if (res.data?.items?.length > 0) {
            const balance = res.data.items[0];

            state.patchState({
              summary: balance.accountActualBalance,
            });
          }
        }),
        catchError((err) => {
          state.patchState({
            summary: defaultAccountState.summary,
          })
          return throwError(err);
        }),
        finalize(() => {
          state.patchState({
            isLoadingBalance: false
          });
        })
      );
  }

  @Action(GetAccountMetrics)
  getAccountMetrics(
    state: StateContext<any>,
    actions: GetAccountMetrics
    ) {
      state.patchState({
        isLoadingBalance: true,
        chartAccountOptions: defaultAccountState.chartAccountOptions
      });

      return this.accountsService.getAccountMetrics(actions.filter)
        .pipe(
          tap(res => {
            const newOptions = this.chartSummary(res.data.items, actions.filter.period)
            state.patchState({
              chartAccountOptions: newOptions
            });
          }),
          catchError((err) => {
            return throwError(err);
          }),
          finalize(() => {
            state.patchState({
              isLoadingBalance: false
            });
          })
        );
    }


  private defaultOperationSummary(): OperationSummary {
    const defaultNumber = '0';
    return {
      grossValue: defaultNumber,
      netValue: defaultNumber,
      chargesValue: defaultNumber,
      items: []
    }
  }

  private defaultOperationMetric(): OperationMetric {
    const defaultNumber = '0';
    return {
      processedCount: 0,
      processedValue: defaultNumber,
      actualBalance: defaultNumber,
      chargesValue: defaultNumber,
      profitValue: defaultNumber
    }
  }

  private chartSummary(metrics: any, period: string) {
    return metrics.map(metric => {
      const momentDate = moment.utc(metric.key);
      const momentHour = moment(metric.key).format('HH:mm')
      return {
        ...metric,
        key: period === 'hour' ? momentHour : momentDate.format('DD/MM/YYYY')
      }
    })
  }

  private totalOperations(transaction: any) {
    let countTotal = 0
    transaction.items.forEach(item => {
      return countTotal = Number(item.count) + countTotal
    })
    let totalObject = {
      category: 'Total',
      amount: transaction.grossValue,
      count: countTotal,
      charges: transaction.chargesValue,
      net: transaction.netValue,
      avg: ""
    }

    transaction.items.push(totalObject)

    return  transaction.items.sort((a, b) => {
      if (a.category === 'PaymentTransaction') {
        return -1;
      }
      if (b.category === 'PaymentTransaction') {
        return 1;
      }
      return 0;
    });
  }
}
