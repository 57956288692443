import {Component, EventEmitter, OnInit, Output, QueryList, ViewChildren} from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { isPartner } from 'src/app/shared/utils/get-context';
import { filterUserTypeOptions } from 'src/app/shared/utils/user-type-options';
import { UserFilterModel } from '../../models/user-filter.model';
import {MjxBaseFilter} from "../../../../shared/modules/mjx-filter/models/mjx-base-filter.model";
import {Select} from "@ngxs/store";
import {PartnersState} from "../../../partners/state/partners.state";
import {Observable} from "rxjs";
import {PartnerModel} from "../../../partners/models/partner.model";
import {MatAutocompleteSelectedEvent} from "@angular/material/autocomplete";
import {MjxSelectComponent} from "../../../../shared/modules/mjx-select/components/mjx-select/mjx-select.component";
import {Roles} from "../../../../shared/utils/roles-list";

@Component({
  selector: 'filter-users',
  templateUrl: './filter-users.component.html',
  styleUrls: ['./filter-users.component.scss']
})
export class FilterUsersComponent extends MjxBaseFilter implements OnInit {
  @Output() filterEvent = new EventEmitter<UserFilterModel>();

  @Select(PartnersState.getPartnersList)
  partners$: Observable<PartnerModel[]>;

  @Select(PartnersState.getPartnersListLoading)
  loadingPartner$: Observable<boolean>;

  @ViewChildren(MjxSelectComponent) mjxSelects: QueryList<MjxSelectComponent<any>>;

  filterForm: UntypedFormGroup;
  userTypeOptions = filterUserTypeOptions;
  isPartner = isPartner;
  rolesEnum = Roles;

  constructor(
    private fb: UntypedFormBuilder,
  ) {
    super();
    this.createFilterForm();
  }

  ngOnInit(): void {
  }

  setPartner(event: MatAutocompleteSelectedEvent) {
    const partner = event.option?.value?.id;
    this.filterForm.get('refCustomer').setValue(partner);

  }

  filterPartners(value: string, itens: any[], compareByProperty: string) {

    if ((value as any) instanceof Object) {
      value = (value as any)[compareByProperty] as string;
    }

    const toLowerCase = (value: string) => value.toLowerCase();

    return itens.filter(partner => `${toLowerCase(partner[compareByProperty])}`.includes(toLowerCase(value)))
  }

  listenPartnerInput(text: string) {
    if (!text) {
      this.filterForm.get('refCustomer').setValue(null);
    }
  }

  createFilterForm() {
    this.filterForm = this.fb.group({
      email: [null],
      name: [null],
      username: [null],
      userType: [null],
      refCustomer: [null]
    });
  }

  doFilter() {
    const formValue = this.filterForm.value;

    if (formValue) {
      this.filterEvent.emit(this.sanitizeForm(formValue));
    }
  }

  clearFilter() {
    this.mjxSelects?.forEach(select => select.clearInput());
    this.createFilterForm();
  }

  private sanitizeForm(form) {
    for (let key in form) {
      if (form[key] && key !== 'userType' && key !== 'refCustomer') {
        form[key] = form[key].trim();
      }
    }

    return form;
  }
}
