import { Pipe, PipeTransform } from '@angular/core';
import {PolicyTypes} from "../../modules/configuration/enums/policy/policy-types.enum";
// translatePolicyType
@Pipe({
  name: 'policyTypeTranslateKey'
})
export class TranslatePolicyTypePipe implements PipeTransform {

  transform(type: PolicyTypes): string {
    let translateKey: string = type;

    switch (type) {
      case PolicyTypes.PartnerTransactionCashInCashOutTax:
      case PolicyTypes.TransactionCashInCashOutTax:
        translateKey = 'SHARED.ENUM.POLICY_TYPES.CASHIN_CASHOUT';
        break;
      case PolicyTypes.PartnerTransactionCashOutTaxNumberCheck:
      case PolicyTypes.TransactionCashOutTaxNumberCheck:
        translateKey = 'SHARED.ENUM.POLICY_TYPES.CASHOUT';
        break;
      case PolicyTypes.PartnerTransactionCashInTaxNumberCheck:
      case PolicyTypes.TransactionCashInTaxNumberCheck:
        translateKey = 'SHARED.ENUM.POLICY_TYPES.CASHIN';
        break;
    }

    return translateKey;
  }
}
