import {StorageKeysEnum} from "../enums/storage-keys.enum";
import {TranslationModulesEnum} from "../enums/translation-modules.enum";
import {Languages} from "../enums/languages.enum";
import ptBr from "@angular/common/locales/pt";
import en from "@angular/common/locales/en";
import es from "@angular/common/locales/es";

export function currentLang() {
  return localStorage.getItem(StorageKeysEnum.Language);
}

export function currentLangLocaleID() {
  const currentLang = localStorage.getItem(StorageKeysEnum.Language);
  const localesIDMap = { [Languages.US]: 'en', [Languages.BR]: 'pt', [Languages.ES]: 'es' };

  return localesIDMap[currentLang];
}

export function currentLangLocaleData() {
  let currentLang = localStorage?.getItem(StorageKeysEnum.Language);
  
  if(!currentLang) {
    localStorage.setItem(StorageKeysEnum.Language, Languages.BR);
    currentLang = localStorage.getItem(StorageKeysEnum.Language);
  }
  
  const localesMap = { [Languages.US]: en, [Languages.BR]: ptBr, [Languages.ES]: es };

  return localesMap[currentLang];
}

export function currentMapLanguage() {
  const currentLang = localStorage.getItem(StorageKeysEnum.Language);
  const localesMap = { [Languages.US]: 'en-US', [Languages.BR]: 'pt-BR', [Languages.ES]: 'es-419' };

  return localesMap[currentLang];
}

export function currentLangBrazilName() {
  const currentLang = localStorage.getItem(StorageKeysEnum.Language);
  const localesMap = { [Languages.US]: 'Brazil', [Languages.BR]: 'Brasil', [Languages.ES]: 'Brasil' };

  return localesMap[currentLang];
}

export function currentLangTranslateCountryProperty() {
  const currentLang = localStorage.getItem(StorageKeysEnum.Language);
  const localesMap = { [Languages.US]: 'nome_pais_int', [Languages.BR]: 'nome_pais', [Languages.ES]: 'nome_pais_es' };

  return localesMap[currentLang];
}

export function translationByModuleFactory(module: TranslationModulesEnum, translations: Object) {
  return { [module]: translations }
}

export function defaultCurrency() {
  return 'BRL';
}
