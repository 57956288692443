import {CustomSelectOptionModel} from "../../../shared/models/custom-select-option.model";

export enum TransactionStateEnum {
  Created = 'Created',
  Done = 'Done',
  Registered = 'Registered',
  Cancelled = 'Cancelled',
  Canceled = 'Canceled',
  Error = 'Error',
  Completed = 'Completed',
  Refund = 'Refund',
  Reversed = 'Reversed',
  Pending = 'Pending',
  Rejected = 'Rejected',
  Approved = 'Approved',
  Executed = 'Executed',
}


export const executionStationOptions: CustomSelectOptionModel<TransactionStateEnum>[] = [
  { value: null, translateKey: 'SHARED.FILTER.ALL' },
  { value: TransactionStateEnum.Created, translateKey: 'SHARED.ENUM.TRANSACTION_STATUS.CREATED' },
  { value: TransactionStateEnum.Completed, translateKey: 'SHARED.ENUM.TRANSACTION_STATUS.COMPLETED' },
  { value: TransactionStateEnum.Cancelled, translateKey: 'SHARED.ENUM.TRANSACTION_STATUS.CANCELLED' },
  { value: TransactionStateEnum.Registered, translateKey: 'SHARED.ENUM.TRANSACTION_STATUS.REGISTERED' },
  { value: TransactionStateEnum.Error, translateKey: 'SHARED.ENUM.TRANSACTION_STATUS.ERROR' },
];
