import {Component} from '@angular/core';
import {Subscription} from "rxjs";
import {Event, NavigationEnd, Router} from "@angular/router";
import {isPartner} from "../../shared/utils/get-context";
import {filter} from "rxjs/operators";
import {RoutesEnum} from "../../shared/enums/routes.enum";

@Component({
  selector: 'mjx-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss']
})
export class UsersComponent {
  routerSub: Subscription;
  showNewUser: boolean;

  constructor(
    private router: Router
  ) {
    this.listenRoute();
  }

  get isPartnerContext(): boolean {
    return isPartner;
  }

  ngOnDestroy() {
    if (this.routerSub && !this.routerSub.closed) {
      this.routerSub.unsubscribe();
    }
  }

  private listenRoute() {
    this.routerSub = this.router.events
      .pipe(
        filter((event: Event) => event instanceof NavigationEnd)
      )
      .subscribe((res: NavigationEnd) => {
          this.showNewUser = res.url === `/${RoutesEnum.Users}`;
        }
      );
  }
}
