<div class="title-container" mat-dialog-title>
  <span>
    {{'USERS.FORM.RESET.MODAL_TITLE' | translate}}
  </span>

  <button *ngIf="!isNewUser"  class="close-modal-btn" mat-icon-button [mat-dialog-close]="userDetails" cdkFocusInitial>
    <mat-icon>close</mat-icon>
  </button>

</div>

<mat-divider class="dialog-divider"></mat-divider>

<div mat-dialog-content class="dat a-container content">
  <p>{{'USERS.FORM.RESET.MODAL_TEXT' | translate}}</p>

  <div class="link-container row">
    <div class="col-md-8">
      <a [href]="data.link" [ngClass]="this.copied && 'visited'" target="_blank" >
        {{truncateLink(data.link)}}
      </a>
    </div>

    <div class="col-md-4">
      <button mat-button class="green-button" (click)="copy()">
        <mat-icon>{{this.copied ? 'check' : 'content_copy'}}</mat-icon>
        {{ (this.copied ? 'USERS.FORM.RESET.COPIED_BUTTON'  : 'USERS.FORM.RESET.COPY_BUTTON') | translate}}
      </button>
    </div>
  </div>
</div>

<mat-divider class="dialog-divider"></mat-divider>

<div mat-dialog-actions class="actions">
  <button class="custom-secondary-button" mat-button cdkFocusInitial (click)="this.goEdit()">
    {{ 'SHARED.CLOSE_BTN' | translate }}
  </button>
</div>
