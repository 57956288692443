import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LayoutModule } from '@angular/cdk/layout';
import {MjxNavigatorComponent} from "./mjx-navigator.component";

import {MjxButtonModule} from "../mjx-button/mjx-button.module";
import {TranslateModule} from "@ngx-translate/core";
import {MaterialElementsModule} from "../material-elements/material-elements.module";
import {RouterModule} from "@angular/router";
import {NgxsModule} from "@ngxs/store";
import {SessionState} from "../../../core/state/session/session.state";
import {ChangeAccountModule} from "../../components/change-account/change-account.module";
import {AccountsState} from "../../../core/state/accounts/accounts.state";
import { UsersModule } from 'src/app/modules/users/users.module';
import {SharedDirectivesModule} from "../../directives/shared-directives.module";

@NgModule({
  declarations: [MjxNavigatorComponent],
  exports: [MjxNavigatorComponent],
    imports: [
        CommonModule,
        LayoutModule,
        MaterialElementsModule,
        MjxButtonModule,
        RouterModule,
        ChangeAccountModule,
        UsersModule,
        NgxsModule.forFeature([SessionState, AccountsState]),
        TranslateModule.forChild({extend: true}),
        SharedDirectivesModule
    ]
})
export class MjxNavigatorModule { }
