import {RequestDataFilterModel} from "../../../shared/models/request-filter.model";
import {UserFormModel, UserModel} from "../models/user.model";

export class GetUsers {
  static readonly type = '[Users] Get Users';
  constructor(public filter: RequestDataFilterModel<UserModel>) {}
}
export class GetUserDetails {
  static readonly type = '[Users] Get User Details';
  constructor(public userId: string, public profile: boolean = false) {}
}

export class DeleteUser {
  static readonly type = '[Users] Delete User';
  constructor(public userId: string, public filter: RequestDataFilterModel<UserModel>) {}
}

export class CreateUser {
  static readonly type = '[Users] Create User';
  constructor(public user: UserFormModel) { }
}

export class EditUser {
  static readonly type = '[Users] Edit User';
  constructor(public user: UserFormModel, public id: string) {}
}

export class UpdateUserPassword {
  static readonly type = '[Users] Change User Password';
  constructor(public currentPassword: string, public newPassword: string, public newPasswordConfirmation: string) {}
}

export class ResetUserPassword {
  static readonly type = '[Users] Reset User Password';
  constructor(public login: string) {}
}

export class ClearResetKey {
  static readonly  type = '[Users] Clear Reset Key State';
}

export class ClearUserDetails {
  static readonly  type = '[Users] Clear User Details';
}

export class ResetSavingUsersState {
  static readonly  type = '[Users] Reset Saving State';
}
