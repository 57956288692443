import { Pipe, PipeTransform } from '@angular/core';
import {AccountModel} from "../../core/models/account.model";

@Pipe({
  name: 'getAccountId'
})
export class GetAccountIdPipe implements PipeTransform {

  transform(account: AccountModel): string {
    return (account?.id) ? account.id.toString() : account?.uid;
  }

}
