import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {routes} from "../../../../environments/vendor/routes/routes";
import {environment} from "../../../../environments/environment";
import {Credentials} from "../../../core/models/credentials.model";

const API = environment.apiUrl;

@Injectable({
  providedIn: 'root',
})
export class LoginService {

  constructor(
    private http: HttpClient
  ) { }

  login(user: Credentials) {
    return this.http.post(`${API}/${routes.login}`, user, {
      responseType: 'text'
    });
  }

}
