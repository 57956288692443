export class AccountSecurityStateModel {
  isLoading: boolean;
  isLoadingStep: boolean;
  isEmpty: boolean;
  hasError: boolean;

  appSetupKey: string;
  appSetupQrCode: string;
}

export const defaultAccountSecurityState: AccountSecurityStateModel = {
  isLoading: false,
  isLoadingStep: false,
  isEmpty: false,
  hasError: false,

  appSetupKey: null,
  appSetupQrCode: null
}
