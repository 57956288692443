import { Pipe, PipeTransform } from '@angular/core';
import {PixEnum} from "../enums/pix.enum";

@Pipe({
  name: 'pixKeyTranslateKey'
})
export class TranslatePixKeyPipe implements PipeTransform {
  transform(status: string): string {
    let translateKey = status ?? '';

    switch (status) {
      case PixEnum.CPF:
        translateKey = 'SHARED.PIX.CPF'
        break;
      case PixEnum.CNPJ:
        translateKey = 'SHARED.PIX.CNPJ'
        break;
      case PixEnum.EMAIL:
        translateKey = 'SHARED.PIX.EMAIL'
        break;
      case PixEnum.PHONE:
        translateKey = 'SHARED.PIX.PHONE'
        break;
      case PixEnum.EVP:
        translateKey = 'SHARED.PIX.RANDOM'
        break;
    }
    return translateKey;
  }
}
