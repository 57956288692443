import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MjxInputComponent } from './mjx-input.component';
import {MaterialElementsModule} from "../material-elements/material-elements.module";



@NgModule({
  declarations: [
    MjxInputComponent
  ],
  exports: [
    MjxInputComponent
  ],
  imports: [
    CommonModule,
    MaterialElementsModule
  ]
})
export class MjxInputModule { }
