import {Component, EventEmitter, Input, Output, TemplateRef, ViewChild, ViewEncapsulation} from '@angular/core';
import {MjxTabOption} from "./models/mjx-table-option.model";
import {MatTabChangeEvent, MatTabGroup} from "@angular/material/tabs";
import {MatTabHeaderPaginationModel} from "../../models/mat-tab-header-pagination.model";
import {MatHeaderPaginationEventsEnum} from "../../enums/mat-header-pagination-events.enum";

@Component({
  selector: 'mjx-navigation-tabs',
  templateUrl: './mjx-navigation-tabs.component.html',
  styleUrls: ['./mjx-navigation-tabs.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class MjxNavigationTabsComponent {
  @Input() tabs: MjxTabOption[] = [];
  @Input() set currentIndex(index: number) {
    if (this.matTabGroup) {
      this.matTabGroup.selectedIndex = index;
      this.idx = index;
    }
  }
  @Input() customOption: TemplateRef<any>;
  @Output() selectedTabChange: EventEmitter<MatTabChangeEvent>;
  @ViewChild(MatTabGroup) matTabGroup: MatTabGroup;
  @ViewChild('lineContainer') lineContainer: any;
  idx: any = 0;

  constructor() {
    this.selectedTabChange = new EventEmitter<MatTabChangeEvent>();
  }

  get lineWidth(): string {
    const delta = 230;
    const width = (this.tabs?.length * delta) + this.checkOptions;

    return `calc(100% - ${width}px)`
  }

  get linePosition(): any {
    const position = (!!this.customOption) ? 'relative' : 'unset'
    return { position };
  }

  changeTab(event: MatTabChangeEvent) {
    this.selectedTabChange.emit(event);
    this.idx = event.index;
  }

  threatPagination(event: MatTabHeaderPaginationModel) {
    switch (event.event) {
      case MatHeaderPaginationEventsEnum.Before:
        this.currentIndex = (this.matTabGroup.selectedIndex - 1);
        break;
      case MatHeaderPaginationEventsEnum.After:
        this.currentIndex = (this.matTabGroup.selectedIndex + 1);
        break;
    }
  }

  private get checkOptions(): number {
    let optionWidth = 0;

    if (this.lineContainer && !!this.customOption) {
      const hasOptionChildren = (this.lineContainer?.nativeElement?.children?.length > 1);
      optionWidth =
        (hasOptionChildren)
          ? (this.lineContainer?.nativeElement?.lastElementChild?.clientWidth ?? 0)
          : 0

      return optionWidth;
    }

    return optionWidth;
  }
}
