import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Store} from '@ngxs/store';
import {
  AccountModel,
  AccountOperationalSummary,
  AccountTransactionsSummary,
  FilterAccountOperationalSummary,
  FilterAccountSummaryModel,
  FilterAccountTransactionsSummary,
  FilterAnalyticsHeatmap
} from 'src/app/core/models/account.model';
import {AccountsState} from 'src/app/core/state/accounts/accounts.state';
import {AccountsStateModel} from 'src/app/core/state/accounts/models/accounts-state.model';
import {PaginationModel} from 'src/app/shared/models/pagination.model';
import {RequestDataFilterModel} from 'src/app/shared/models/request-filter.model';
import {CommonResponse} from 'src/app/shared/models/response.model';
import {HttpUtilsService} from 'src/app/shared/services/http-utils.service';
import {environment} from 'src/environments/environment';
import {routes} from 'src/environments/vendor/routes/partner-routes';
import {AccountsFilterModel} from "../../../core/state/accounts/models/accounts-filter.model";
import {TransactionsTypesEnum} from "../../enums/transactions-types.enum";
import {TransactionResponseModel} from "../../../modules/transactions/models/transaction-response.model";
import {RecentTransactionsModel} from "../../../modules/transactions/models/recent-transactions.model";
import {TransactionRequestFilterModel} from 'src/app/modules/transactions/models/transaction-request-filter.model';
import {TransactionRequestModel} from 'src/app/modules/transactions/models/transaction-request.model';
import {Observable} from "rxjs";
import {PolicyFilterModel} from "../../../modules/configuration/models/policy/policy-filter.model";
import {PolicyModel} from "../../../modules/configuration/models/policy/policy.model";
import {PolicyRulesModel} from "../../../modules/configuration/models/policy/policy-rules.model";
import {AccountUserPreferencesObject} from "../../../modules/partners/models/account-user-preferences-object.model";
import {ParticipantsCheckResponse} from "../../../modules/queries/models/participants-check-response.model";
import { HeatmapModel } from '../../models/heatmap.model';

const API = environment.apiUrl;

@Injectable({
  providedIn: 'root',
})
export class PartnersApiService {

  constructor(
    private http: HttpClient,
    private httpUtils: HttpUtilsService,
    private store: Store
  ) {
  }

  getAccounts(filter: Partial<AccountsFilterModel>) {
    let url = `${API}/${routes?.accounts}`;
    return this.http.get<CommonResponse<AccountModel[]>>(url, {
      params: filter
    });
  }

  unlinkAccount(accountId: string, usersIds: string[]) {
    const url = `${API}/${routes?.accounts}/${accountId}/memberships`;
    return this.http.request<CommonResponse<AccountModel>>('delete', url, {body: {users: usersIds}});
  }

  linkAccounts(accountId: string, usersIds: string[]) {
    const url = `${API}/${routes?.accounts}/${accountId}/memberships`;
    return this.http.post<CommonResponse<AccountModel>>(url, {users: usersIds});
  }

  getLinkedAccounts(refUser: string) {
    let url = `${API}/${routes?.users}/${refUser}/accounts`;

    return this.http.get<CommonResponse<AccountModel[]>>(url);
  }

  getAccountSummary(accountId: string, filter?: FilterAccountSummaryModel) {
    const url = `${API}/${routes?.accounts}/${accountId}/summary`;
    return this.http.get<CommonResponse<number>>(url, {
      params: {...filter}
    });
  }

  getOperationalSummary(filter?: FilterAccountOperationalSummary) {
    const url = `${API}/${routes?.accounts}/${filter.accountId}/reports/operational-summary`;
    delete filter.accountId;
    return this.http.get<CommonResponse<AccountOperationalSummary>>(url, {
      params: {...filter}
    });
  }

  getTransactionsSummary(filter?: FilterAccountTransactionsSummary) {
    let url = `${API}/${routes?.accounts}/${filter.accountId}/reports/transactions-summary`;
    delete filter.accountId;

    return this.http.get<CommonResponse<AccountTransactionsSummary>>(url, {
      params: filter as any
    });
  }

  getTransactionsBalance(accountId) {
    let url = `${API}/${routes?.accounts}/${accountId}/balance`;

    return this.http.get<CommonResponse<PaginationModel<any>>>(url);
  }

  get currentAccount(): AccountModel {
    const state = this.store.selectSnapshot<AccountsStateModel>(AccountsState)
    return state.currentAccount
  }

  getKeys<T>(filter: RequestDataFilterModel<any>) {
    let url = `${API}/${routes.accounts}${this.currentAccount}/${routes?.apiKeys}`;

    const filterParams = this.httpUtils.parseFilterParams(filter);

    return this.http.get<CommonResponse<PaginationModel<T>>>(url, {
      params: filterParams,
    });
  }

  newKey<T>(newApiKey: T) {
    let url = `${API}/${routes.accounts}${this.currentAccount}/${routes?.apiKeys}`;

    return this.http.post<CommonResponse<T>>(url, newApiKey);
  }

  revokeKey<T>(accessKey: string) {
    let url = `${API}/${routes.accounts}${this.currentAccount}/${routes?.apiKeys}/revoke/${accessKey}`;

    return this.http.put<CommonResponse<PaginationModel<T>>>(url, {});
  }

  deleteKey<T>(accessKey: string) {
    let url = `${API}/${routes.accounts}${this.currentAccount}/${routes?.apiKeys}/remove/${accessKey}`;

    return this.http.delete<CommonResponse<PaginationModel<T>>>(url, {});
  }

  // TRANSACTIONS

  getTransactions(type: TransactionsTypesEnum, accountId: string, filter?: RequestDataFilterModel<any>) {
    let url = `${API}/${routes?.accounts}/${accountId}/transactions/${type.toLowerCase()}`;

    return this.http
      .get<CommonResponse<PaginationModel<TransactionResponseModel>>>(url, {
        params: this.httpUtils.parseFilterParams(filter)
      });
  }

  getTransactionsResume(accountId: string, filter?: RequestDataFilterModel<any>) {
    let url = `${API}/${routes?.accounts}/${accountId}/summary`;

    const filterParams = this.httpUtils.parseFilterParams(filter);
    return this.http
      .get<RecentTransactionsModel>(url, {
        params: filterParams
      });
  }

  getTransactionsById(transactionId: string, type?: TransactionsTypesEnum, accountId?: string) {
    let url = `${API}/${routes?.accounts}/${accountId}/transactions/${type?.toLowerCase()}/${transactionId}`;

    return this.http
      .get<CommonResponse<TransactionResponseModel>>(url);
  }

  invokeWebhook(accountId: string, transactionsId) {
    let url = `${API}/${routes?.accounts}/${accountId}/transactions/${transactionsId}/invoke`;

    return this.http
      .put<CommonResponse<string>>(url, null);
  }

  // get currentAccount(): AccountModel {
  //   const state = this.store.selectSnapshot<AccountsStateModel>(AccountsState);
  //   return state.currentAccount;
  // }

  // Transaction Requests
  getTransactionRequests(accountId: string, filter?: TransactionRequestFilterModel) {
    const url = `${API}/${routes?.accounts}/${accountId}/transaction-requests?${this.httpUtils.createQueryParamByObject(filter)}`;

    return this.http.get<CommonResponse<PaginationModel<TransactionRequestModel>>>(url);
  }

  getTransactionRequestDetails(accountId: string, transactionId) {
    const url = `${API}/${routes?.accounts}/${accountId}/transaction-requests/${transactionId}`;

    return this.http.get<CommonResponse<TransactionRequestModel>>(url);
  }

  createTransactionRequest(accountId: string, transactionRequest: TransactionRequestModel) {
    const url = `${API}/${routes?.accounts}/${accountId}/transaction-requests`;

    return this.http
      .post<CommonResponse<TransactionRequestModel>>(url, transactionRequest);
  }

  deleteTransactionRequest(accountId: string, transactionRequestId: string) {
    const url = `${API}/${routes?.accounts}/${accountId}/transaction-requests/${transactionRequestId}`;

    return this.http.delete<CommonResponse<any>>(url);
  }

  cancelPayment(accountId: string, transactionId: string) {
    const url = `${API}/${routes?.accounts}/${accountId}/transaction-requests/cancel/${transactionId}`;
    return this.http.put<CommonResponse<any>>(url, null);
  }

  // Entry
  getEntries(obj: RequestDataFilterModel<any>) {
    let url = `${API}/${routes?.accounts}/${obj.where?.accountId}/entries`;
    const params = this.httpUtils.parseFilterParams(obj);

    return this.http.get(`${url}`, {
      params
    });
  }

  getPartnerEntries(obj: RequestDataFilterModel<any>, accountId: string) {
    let url = `${API}/${routes?.accounts}/${accountId}/entries`;
    const params = this.httpUtils.parseFilterParams(obj);

    return this.http.get(`${url}`, {
      params
    });
  }

  // Security
  mfaValidate({ app }) {
    let url = `${API}/${routes?.mfa}/validate`;
    return this.http.post(`${url}`, { app }, {
      responseType: 'text'
    });
  }

  mfaAppSetupKey(): Observable<CommonResponse<any>> {
    let url = `${API}/${routes?.mfa}/app/setup`;
    return this.http.get<CommonResponse<any>>(`${url}`);
  }

  mfaAppConfirm(code: string): Observable<CommonResponse<any>> {
    let url = `${API}/${routes?.mfa}/app/setup/confirm`;
    return this.http.post<CommonResponse<any>>(`${url}`, { code });
  }

  mfaAppRevoke(code: string): Observable<CommonResponse<any>> {
    let url = `${API}/${routes?.mfa}/app/revoke`;
    return this.http.put<CommonResponse<any>>(`${url}`, { code });
  }

  //policy
  getPolicyRules(filter: RequestDataFilterModel<Partial<PolicyFilterModel>>, accountId: string) {
    let url = `${API}/${routes.accounts}/${accountId}/policy-rules`;
    return this.http.get<CommonResponse<PaginationModel<PolicyModel>>>(`${url}`, {
      params: this.httpUtils.parseFilterParams(filter)
    });
  }

  addPolicyRules(rules: PolicyRulesModel, accountId: string) {
    let url = `${API}/${routes.accounts}/${accountId}/policy-rules`;
    return this.http.post<CommonResponse<any>>(`${url}`, rules);
  }

  editPolicy(policy: PolicyModel, policyId: string, accountId: string) {
    let url = `${API}/${routes.accounts}/${accountId}/policy-rules/${policyId}`;
    return this.http.put<CommonResponse<any>>(`${url}`, { ...policy });
  }

  removePolicyRules(policyId: string, accountId: string) {
    let url = `${API}/${routes.accounts}/${accountId}/policy-rules`;
    return this.http.delete<CommonResponse<any>>(`${url}/${policyId}`);
  }

  getAccountSecurityKey(accountId: string) {
    let url = `${API}/${routes?.accounts}/${accountId}/preferences`;
    return this.http.get<CommonResponse<any>>(`${url}` );
  }

  setAccountSecurityKey(accountId: string, accountUserPreferencesObject: AccountUserPreferencesObject) {
    let url = `${API}/${routes?.accounts}/${accountId}/preferences`;
    return this.http.put<CommonResponse<any>>(`${url}`,
      { accountUserPreferencesObject }
    );
  }

  getHeatmapData(filter: FilterAnalyticsHeatmap) {
    let url = `${API}/partners/analytics/heatmap`;

    const params = this.httpUtils.parseFilterParams(filter);
    return this.http.get<CommonResponse<PaginationModel<HeatmapModel>>> (url, {
      params
    });
  }

  // API Queries
  queryByCpf(cpf: string) {
    const url = `${API}/${routes?.participants}/check/${cpf}`;

    return this.http.get<CommonResponse<ParticipantsCheckResponse>>(url);
  }
}
