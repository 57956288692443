import {ErrorsReasonEnum} from "../../enums/errors-reason.enum";
import {ApiErrorDetailedItem} from "../../../core/models/api-error.model";

export class ThreatError {
  static readonly type = '[Errors] Threat Error';
  constructor(public reason?: ErrorsReasonEnum) { }
}

export class ThreatMultipleErrors {
  static readonly type = '[Errors] Threat Multiples Error';
  constructor(public items?: ApiErrorDetailedItem[]) { }
}
