import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {MjxTableComponent} from "./mjx-table.component";
import {NgxMaskModule} from "ngx-mask";
import {MaterialElementsModule} from "../material-elements/material-elements.module";
import {TranslateModule} from "@ngx-translate/core";
import { MjxMatColumnDirective } from './directives/mjx-mat-column.directive';
import {MatPaginatorIntl} from "@angular/material/paginator";
import {MjxMatPaginatorIntl} from "./services/mjx-mat-paginator-intl.service";
import { SharedDirectivesModule } from '../../directives/shared-directives.module';
import { TableActionButtonComponent } from './components/table-action-button/table-action-button.component';
import {FormsModule} from "@angular/forms";
import {maskConfigFactory} from "../../utils/maskConfigFactory";
import {SharedPipesModule} from "../../pipes/shared-pipes.module";

@NgModule({
  declarations: [MjxTableComponent, MjxMatColumnDirective, TableActionButtonComponent],
  exports: [MjxTableComponent, MjxMatColumnDirective],
  imports: [
    CommonModule,
    MaterialElementsModule,
    NgxMaskModule.forRoot(maskConfigFactory),
    TranslateModule.forChild({ extend: true }),
    SharedDirectivesModule,
    FormsModule,
    SharedPipesModule
  ],
  providers: [
    {
      provide: MatPaginatorIntl,
      useClass: MjxMatPaginatorIntl
    }
  ]
})
export class MjxTableModule { }
