import {NgModule} from '@angular/core';
import {CommonModule, CurrencyPipe} from '@angular/common';
import {DatasourcePipe} from "./datasource.pipe";
import {TranslateModule} from "@ngx-translate/core";
import {TranslateCountryPipe} from './translate-country.pipe';
import {TranslateTransactionStatusPipe} from './translate-transaction-status.pipe';
import {TranslateAccountStatusPipe} from './translate-account-status.pipe';
import {GetAccountIdPipe} from './get-account-id.pipe';
import {TranslateApiKeysStatePipe} from './translate-api-keys-state.pipe';
import {ReplacePipe} from './replace.pipe';
import {TranslateTransactionTypePipe} from './translate-transaction-type.pipe';
import {HiddenCpfPipe} from './hidden-cpf.pipe';
import {TranslatePolicyTypePipe} from './translate-policy-type.pipe';
import {AccountNameByIdPipe} from './account-name-by-id.pipe';
import {PartnerNameByIdPipe} from './partner-name-by-id.pipe';
import { TranslateUserRolePipe } from './translate-user-role.pipe';
import { TranslatePixKeyPipe } from './translate-pix-key.pipe';
import { MjxCurrencyPipe } from './mjx-currency.pipe';
import {TranslatePolicyResourceTypePipe} from "./translate-policy-resource-type.pipe";
import {TranslateDocumentTypePipe} from "./translate-document-type.pipe";
import { StartsWithPipe } from './starts-with.pipe';
import { InferPixKeyPipe } from './infer-pix-key.pipe';
import {AccountCompleteNamePipe} from "./account-complete-name.pipe";
import { SafePipe } from './safe.pipe';

@NgModule({
  declarations: [
    DatasourcePipe,
    TranslateCountryPipe,
    TranslateTransactionStatusPipe,
    TranslateAccountStatusPipe,
    GetAccountIdPipe,
    TranslateApiKeysStatePipe,
    ReplacePipe,
    TranslateTransactionTypePipe,
    HiddenCpfPipe,
    TranslatePolicyTypePipe,
    AccountNameByIdPipe,
    PartnerNameByIdPipe,
    TranslateUserRolePipe,
    TranslatePixKeyPipe,
    MjxCurrencyPipe,
    TranslatePolicyResourceTypePipe,
    TranslateDocumentTypePipe,
    TranslatePolicyResourceTypePipe,
    StartsWithPipe,
    InferPixKeyPipe,
    AccountCompleteNamePipe,
    SafePipe
  ],
  exports: [
    DatasourcePipe,
    TranslateCountryPipe,
    TranslateTransactionStatusPipe,
    TranslateAccountStatusPipe,
    GetAccountIdPipe,
    TranslateApiKeysStatePipe,
    ReplacePipe,
    TranslateTransactionTypePipe,
    HiddenCpfPipe,
    TranslatePolicyTypePipe,
    AccountNameByIdPipe,
    PartnerNameByIdPipe,
    TranslateUserRolePipe,
    TranslatePixKeyPipe,
    MjxCurrencyPipe,
    TranslatePolicyResourceTypePipe,
    TranslateDocumentTypePipe,
    TranslatePolicyResourceTypePipe,
    StartsWithPipe,
    InferPixKeyPipe,
    AccountCompleteNamePipe,
    SafePipe
  ],
  imports: [
    CommonModule,
    TranslateModule
  ],
  providers: [
    CurrencyPipe,
    MjxCurrencyPipe
  ]
})
export class SharedPipesModule {
}
