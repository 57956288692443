import { Injectable } from '@angular/core';
import {CustomMatColumn} from "../mjx-table.component";
import {VersionedStorage} from "../models/versioned-storage";

@Injectable({
  providedIn: 'root'
})
export class TableStorageService extends VersionedStorage{
  // Update filter storage if current version is different from previous version
  override readonly version = '#1';
  override readonly storageKey = 'mjx-tables-column';

  constructor() {
    super();
    this.verifyStorageVersion();
  }

  saveTableColumns(tableName: string, tableData: CustomMatColumn[]) {
    const tableConfigs = JSON.parse(localStorage.getItem(this.storageKey)) || {};
    if (tableConfigs) {
      tableConfigs['version'] = this.version;
      tableConfigs[tableName.toLowerCase()] = tableData;
      localStorage.setItem(this.storageKey, JSON.stringify(tableConfigs));
    }
  }

  deleteTableColumn(tableName: string) {
    const tableConfigs = JSON.parse(localStorage.getItem(this.storageKey)) || {};
    if (tableConfigs && tableConfigs[tableName.toLowerCase()]) {
      delete tableConfigs[tableName.toLowerCase()];
      localStorage.setItem(this.storageKey, JSON.stringify(tableConfigs));
    }
  }

  loadTableColumns(tableName: string,) {
    const data = localStorage.getItem(this.storageKey);
    if (data) {
      const tableData = JSON.parse(data);
      if (tableData[tableName.toLowerCase()]) {
        return tableData[tableName.toLowerCase()];
      }
    }
    return null;
  }
}
