import {MjxTableColumnDefinition} from "../../../shared/modules/mjx-table/models/mjx-table.model";
import {MjxTableColumnTypesEnum} from "../../../shared/modules/mjx-table/enums/mjx-table-column-types.enum";
import {Injectable} from "@angular/core";

@Injectable({
  providedIn: 'root'
})
export class ProfilesPermissionsTableUtilsService {
  getColumns(): string[] {
    return [
      'profile',
      'permissions',
      'actions'
    ];
  }

  getColumnsDef(): MjxTableColumnDefinition[] {
    return [
      {
        column: 'profile',
        property: 'profile',
        name: 'INTEGRATION.TABLE.PROFILE',
        type: MjxTableColumnTypesEnum.Text,
        sortable: true
      },
      {
        column: 'permissions',
        property: 'permissions',
        name: 'INTEGRATION.TABLE.PERMISSIONS',
        type: MjxTableColumnTypesEnum.Text,
        sortable: true
      },
      {
        column: 'actions',
        property: '',
        name: 'INTEGRATION.TABLE.ACTIONS',
        type: MjxTableColumnTypesEnum.Actions,
        sortable: false,
        actions: [
          {
            action: 'profiles-permissions-edit', icon: 'edit',
            tooltip: 'INTEGRATION.TOOLTIP.EDIT'
          },
          {
            action: 'profiles-permissions-delete', icon: 'delete', color: 'var(--main-red)',
            tooltip: 'INTEGRATION.TOOLTIP.DELETE'
          }
        ]
      }
    ]
  }
}
