import {Component, Input, OnDestroy} from '@angular/core';
import {ActivatedRoute, NavigationEnd, Router, RouterEvent} from "@angular/router";
import {filter} from "rxjs/operators";
import {Subscription} from "rxjs";
import {ListenBreadcrumbService} from "./services/listen-breadcrumb.service";

@Component({
  selector: 'mjx-breadcrumb',
  templateUrl: './mjx-breadcrumb.component.html',
  styleUrls: ['./mjx-breadcrumb.component.scss']
})
export class MjxBreadcrumbComponent implements OnDestroy{
  @Input() breadcrumbs: string[];
  routedBreadcrumbs: string[] = [];
  currentRoute: string;
  subs: Subscription;
  disabledRoutes = ['/transactions', '/integration', '/configuration'];

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private listenBreadcrumbs: ListenBreadcrumbService

  ) {
    this.listenRouterChanges();
    this.listenBreadcrumbs
      .listen(router, activatedRoute, this.breadcrumbs)
      .subscribe(res => {
        this.breadcrumbs = res;
      });
  }

  ngOnDestroy() {
    this.routedBreadcrumbs = [];
    this.subs.unsubscribe();
  }

  private listenRouterChanges() {
    this.subs = this.router
      .events
      .pipe(
        filter(event => event instanceof NavigationEnd)
      )
      .subscribe((res: RouterEvent) => {
        if (res) {
          this.generateBreadRoutes(res);
        }
      });
  }

  private generateBreadRoutes(res: RouterEvent) {
    this.currentRoute = this.router.url;
    const routes = res.url.split('/');
    const tempRoutes = [];
    let lastRoute: string;

    routes.forEach((path, index) => {
      const route: string = routes.slice(0, index + 1).join('/');

      if (route !== '') {
        lastRoute = route;

        if (this.disabledRoutes.includes(route)) {
          tempRoutes.push(null)
        } else {
          tempRoutes.push(route);
        }
      } else {
        tempRoutes.push(null);
      }
    });

    this.routedBreadcrumbs = tempRoutes;
  }
}
